import { observable, action, makeObservable } from 'mobx';
import { CreditCardState } from '../../../molecules/payment/states/CreditCardFormState';
import { SubmitState } from '../../../../../commonv3/states/SubmitState';
import { GlobalStatesV3 } from '../../../../../commonv3/states/GlobalStatesV3';
import { translateErrorMessage } from '../../../../../commonv3/api/protocol';
import { V3Routes } from '../../../../routes';

export class PaymentInfoPaneState {
  initialized = false;
  formState: CreditCardState = new CreditCardState(GlobalStatesV3.currentUser);
  submitState = new SubmitState();
  success = false;

  constructor() {
    makeObservable(this, {
      initialized: observable,
      formState: observable,
      submitState: observable,
      success: observable,
      init: action.bound,
      validateCardInfo: action.bound,
      submit: action.bound,
    });
  }

  async init() {
    await this.formState.init();
    this.initialized = true;
  }

  async validateCardInfo() {
    await this.formState.validateNewCard();
  }

  async submit() {
    await this.submitState.operate(async () => {
      try {
        await this.validateCardInfo();
      } catch (e) {
        return;
      }
      try {
        await this.formState.update();
        this.success = true;
        setTimeout(() => {
          GlobalStatesV3.routing.push(V3Routes.payments());
        }, 1000);
      } catch (e) {
        alert('お支払い情報の更新に失敗しました：' + translateErrorMessage(e));
      }
    });
  }
}
