import { observable, action, makeObservable } from 'mobx';
import { TrialLessonConfigApis } from '../api/trialLesson';
import { TrialLessonConfig } from '../../admin/types/trial';

export class TrialLessonConfigState {
  lessonConfig: TrialLessonConfig = null;

  constructor() {
    makeObservable(this, {
      lessonConfig: observable,
      loadConfig: action,
    });
  }

  async loadConfig() {
    if (!this.lessonConfig) {
      this.lessonConfig = await TrialLessonConfigApis.findGlobal();
    }
  }

  isTrialFree(): boolean {
    return this.lessonConfig ? this.lessonConfig.price === 0 : false;
  }
}
