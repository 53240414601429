import { ApiV3 } from './ApiV3';
import { MainV3ApiBase } from '../../mainv3/apis/base';

export const JwtApis = {
  base(): ApiV3 {
    return MainV3ApiBase.appendPath('/users/current/jwt');
  },
  find(): Promise<string> {
    return this.base().get();
  },
};
