import React, { useEffect } from 'react';
import { ISODate } from '../../../../types/dates';
import moment, { Moment } from 'moment-timezone';
import './dateInput.module.scss';
import { range } from 'lodash';
import { SelectInput } from './SelectInput';
import { action, computed, observable, makeObservable } from 'mobx';
import { observer, useLocalStore } from 'mobx-react';
import classNames from 'classnames';
import { useViewportState } from '../../../../hooks/useViewportState';
import { allPresent, isPresent } from '../../../../utils/presence';

class NonSpDateState {
  min = null;
  max = null;
  date = null;
  month = null;
  year = null;
  internalValue = null;
  updateHandler = null;

  constructor() {
    makeObservable(this, {
      min: observable,
      max: observable,
      date: observable,
      month: observable,
      year: observable,
      internalValue: observable,
      minMoment: computed,
      maxMoment: computed,
      yearOptions: computed,
      monthOptions: computed,
      maxDate: computed,
      dateOptions: computed,
      setValue: action.bound,
      clearValue: action.bound,
      setDate: action.bound,
      setMonth: action.bound,
      setYear: action.bound,
      syncUpdate: action.bound,
      handleUpdate: action.bound,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  init(min, max, updateHandler: (value: ISODate | Moment) => any) {
    this.min = min || moment().add(-70, 'year');
    this.max = max || moment().add(20, 'year');
    this.updateHandler = updateHandler;
  }

  get minMoment() {
    return moment(this.min);
  }

  get maxMoment() {
    return moment(this.max);
  }

  get yearOptions() {
    return range(this.minMoment.year(), this.maxMoment.year() + 1).map((y) => ({
      label: `${y}年`,
      value: y,
    }));
  }

  get monthOptions() {
    // Moment month starts from zero
    return range(0, 12).map((m) => ({
      label: `${m + 1}月`,
      value: m,
    }));
  }

  /**
   * Returns the maximum date that the user can set
   * If the month (and year) is set, we return the maximum date for the month,
   * otherwise, we fallback to 31st
   */
  get maxDate() {
    const m = moment();
    if (isPresent(this.year)) {
      m.year(this.year);
    }
    if (isPresent(this.month)) {
      // Calculate the maximum date as last day of the next month
      m.month(this.month + 1);
      m.date(0);
      return m.date();
    }
    return 31;
  }

  get dateOptions() {
    const dayRange = range(1, this.maxDate + 1);
    return dayRange.map((d) => ({
      label: `${d}日`,
      value: d,
    }));
  }

  setValue(value: ISODate | Moment) {
    if (value != this.internalValue) {
      this.internalValue = value;
      if (value == null) {
        this.clearValue();
        return;
      }
      const parsed = moment(value);
      if (!parsed.isValid()) {
        this.clearValue();
        return;
      }
      this.date = parsed.date();
      this.month = parsed.month();
      this.year = parsed.year();
    }
  }

  clearValue() {
    this.date = null;
    this.month = null;
    this.year = null;
  }

  setDate(date: number) {
    this.date = date;
    this.syncUpdate();
  }
  setMonth(month: number) {
    this.month = month;
    this.syncUpdate();
  }
  setYear(year: number) {
    this.year = year;
    this.syncUpdate();
  }
  syncUpdate() {
    if (isPresent(this.date) && this.date > this.maxDate) {
      this.date = this.maxDate;
    }
    if (!allPresent(this.date, this.month, this.year)) {
      this.handleUpdate(null);
      return;
    }
    const currentDate = moment().year(this.year).month(this.month).date(this.date);
    if (!currentDate.isValid()) {
      this.handleUpdate(null);
      return;
    }
    this.handleUpdate(currentDate.format('YYYY-MM-DD'));
  }

  handleUpdate(value) {
    if (!this.updateHandler) {
      return;
    }
    if (value != this.internalValue) {
      this.internalValue = value;
      this.updateHandler(this.internalValue);
    }
  }
}

type Props = {
  id?: string;
  name?: string;
  error?: boolean;
  value?: ISODate;
  size?: 'm' | 'l';
  min?: ISODate;
  max?: ISODate;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (date) => any;
  styleName?: string;
  className?: string;
};

export const SelectDateInput = observer(
  ({ id, name, error, size = 'm', value, min, max, onChange, className }: Props) => {
    const { componentState } = useLocalStore(() => ({ componentState: new NonSpDateState() }));
    const { year, month, date, setYear, setMonth, setDate, yearOptions, monthOptions, dateOptions } = componentState;
    useEffect(() => {
      componentState.init(min, max, onChange);
      componentState.setValue(value);
    }, [min, max, onChange]);
    useEffect(() => {
      componentState.setValue(value);
    }, [value]);
    return (
      <div styleName="date-input-non-sp" id={id} className={className}>
        <SelectInput
          id={`${id || 'date-selector'}-year`}
          name={`${name || 'date-selector'}-year`}
          placeholder="年"
          value={year}
          onChange={setYear}
          styleName="selector wide"
          size={size}
          error={error}
          serializer={(v) => String(v)}
          deserializer={(v) => (v === '' ? null : Number(v))}
          options={yearOptions}
        />
        <SelectInput
          id={`${id || 'date-selector'}-month`}
          name={`${name || 'date-selector'}-month`}
          placeholder="月"
          value={month}
          onChange={setMonth}
          size={size}
          error={error}
          styleName="selector"
          serializer={(v) => String(v)}
          deserializer={(v) => (v === '' ? null : Number(v))}
          options={monthOptions}
        />
        <SelectInput
          id={`${id || 'date-selector'}-date`}
          name={`${name || 'date-selector'}-date`}
          placeholder="日"
          value={date}
          size={size}
          onChange={setDate}
          error={error}
          styleName="selector"
          serializer={(v) => String(v)}
          deserializer={(v) => (v === '' ? null : Number(v))}
          options={dateOptions}
        />
      </div>
    );
  }
);

export const NativeDateInput = ({ id, name, error, size = 'm', value, min, max, onChange, className }: Props) => {
  const selected = !!value;
  return (
    <div styleName={classNames('date-input-sp', { error: error })} className={className}>
      <input
        type="date"
        value={value === null ? '' : value}
        onChange={(e) => onChange(e.target.value)}
        id={id}
        name={name}
        min={min}
        max={max}
        styleName={classNames('select', size, { selected: selected, error: error })}
      />
    </div>
  );
};

export const DateInput = observer((props) => {
  const { isSp } = useViewportState();
  return isSp ? <NativeDateInput {...props} /> : <SelectDateInput {...props} />;
});
