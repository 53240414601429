import { action, computed, observable, makeObservable } from 'mobx';
import { RemoteResourceV3, updateRemoteResourceV3 } from '../../../../commonv3/api/RemoteResourceV3';
import { SurveyIndexView, SurveyQuestionLinkIndexView } from '../../../types/surveys';
import { SurveyApis } from '../../../apis/surveys';
import { SurveyQuestionType } from '../../../../commonv3/types/survey_questions';
import {
  DateQuestionState,
  DegreeQuestionState,
  MultiLineFreeAnswerQuestionState,
  MultiSelectQuestionState,
  SatisfactionQuestionState,
  SingleLineFreeAnswerQuestionState,
  SingleSelectPullDownQuestionState,
  SingleSelectQuestionState,
  TopKSelectQuestionState,
} from '../../../components/organisms/surveys/states/QuestionState';
import { SubmitState } from '../../../../commonv3/states/SubmitState';
import { SurveySubmissionApis } from '../../../apis/surveySubmissions';
import autobind from 'autobind-decorator';
import { GlobalStatesV3 } from '../../../../commonv3/states/GlobalStatesV3';
import { AbsoluteAccountRoutes, V3Routes, BeautyRoutes } from '../../../routes';
import { SurveyParameterType } from '../../../../commonv3/types/surveyParameterDefinitions';
import { parseQueryParams } from '../../../types/location';
import { SurveySubmissionParameterApis } from '../../../../adminv3/api/survey_submission_parameters';
import { Service } from '../../../../commonv3/types/service';
import { EventApis } from '../../../../mainv2/api/event';
import { EventDetails } from '../../../../mainv2/types/event';
import { MembershipPlanGroupKey } from '../../../types/membershipPlans';

export class SurveyState {
  surveyKey = null;
  membershipPlanGroupKey: MembershipPlanGroupKey | null = null;
  survey: RemoteResourceV3<SurveyIndexView> = RemoteResourceV3.loading();
  parameters = null;
  parameterParseError = null;
  questionIdToState = observable.map();
  submitState = new SubmitState();
  initialized = false;
  success = false;
  currentQuestionNumber = 1;
  event: RemoteResourceV3<EventDetails> = RemoteResourceV3.loading();
  eventService: Service = null;

  constructor() {
    makeObservable(this, {
      surveyKey: observable,
      survey: observable,
      parameters: observable,
      parameterParseError: observable,
      questionIdToState: observable,
      submitState: observable,
      initialized: observable,
      success: observable,
      currentQuestionNumber: observable,
      event: observable,
      eventService: observable,
      init: action.bound,
      questions: computed,
      validate: action.bound,
      errorQuestions: computed,
      hasErrors: computed,
      hasSurvey: computed,
      questionAnswers: computed,
      submit: action.bound,
      nextQuestion: action.bound,
      prevQuestion: action.bound,
    });
  }

  async init(surveyKey: string) {
    this.initialized = false;
    this.surveyKey = surveyKey;
    const survey = await updateRemoteResourceV3(
      this.survey,
      (v) => (this.survey = v),
      () => SurveyApis.show(this.surveyKey)
    );
    if (survey) {
      this.parameters = await this.parseAndValidateParameters(survey);
      survey.survey_question_links.forEach((link) => {
        this.questionIdToState.set(link.survey_question.id, this.createQustionStateFor(link));
      });

      this.membershipPlanGroupKey = survey.membership_plan_group_key;
    }
    this.initialized = true;
    if (this.parameters) {
      const event = await updateRemoteResourceV3(
        this.event,
        (v) => (this.event = v),
        () => EventApis.find(this.parameters.event_id)
      );
      if (event) {
        this.eventService = event.service;
      }
    }
  }

  private async parseAndValidateParameters(survey: SurveyIndexView) {
    try {
      const parameters = {};
      const urlParameters = parseQueryParams(location);
      survey.survey_parameter_definitions.forEach((definition) => {
        const rawValue = urlParameters[definition.key];
        let parsedValue = null;
        if (!rawValue) {
          throw new Error(`${definition.key}を指定してください`);
        }
        switch (definition.parameter_type) {
          case SurveyParameterType.REFERENCE:
          case SurveyParameterType.INTEGER:
            parsedValue = Number(rawValue);
            if (!Number.isFinite(parsedValue)) {
              throw new Error(`${definition.key}の値が無効です`);
            }
            parameters[definition.key] = parsedValue;
            break;
          case SurveyParameterType.STRING:
            parameters[definition.key] = rawValue;
            break;
        }
      });
      await SurveySubmissionParameterApis.validate(survey.key, parameters);
      return parameters;
    } catch (e) {
      this.parameterParseError = true;
    }
  }

  @autobind
  getQuestionStateFor(questionId) {
    return this.questionIdToState.get(questionId);
  }

  get questions(): SurveyQuestionLinkIndexView[] {
    if (this.survey.isEmpty()) {
      return [];
    }
    return this.survey.get().survey_question_links;
  }

  validate() {
    this.questions.forEach((q) => {
      const questionState = this.getQuestionStateFor(q.survey_question_id);
      if (questionState) {
        questionState.validate();
      }
    });
  }

  get errorQuestions(): SurveyQuestionLinkIndexView[] {
    return this.questions.filter((question) => this.getQuestionStateFor(question.survey_question_id).hasErrors);
  }

  get hasErrors(): boolean {
    return this.questions.some((q) => {
      const questionState = this.getQuestionStateFor(q.survey_question_id);
      return questionState.hasErrors;
    });
  }

  get hasSurvey(): boolean {
    return !this.survey.isEmpty() && !this.parameterParseError;
  }

  get questionAnswers() {
    return this.questions.reduce(
      (result, q) => ({
        ...result,
        [q.survey_question.key]: this.getQuestionStateFor(q.survey_question_id).value,
      }),
      {}
    );
  }

  hundleRoutingPush(service): void {
    switch (service) {
      case Service.BEAUTY:
        window.location.replace(BeautyRoutes.memberHome().pathname);
        break;
      case Service.LIKES:
        GlobalStatesV3.routing.push(V3Routes.home());
        break;
      default:
        GlobalStatesV3.routing.push(V3Routes.home());
        break;
    }
  }

  async submit() {
    this.validate();
    if (!this.hasErrors) {
      this.submitState.operate(async () => {
        await SurveySubmissionApis.create(this.surveyKey, this.parameters, this.questionAnswers);
        this.success = true;
        setTimeout(() => {
          switch (this.membershipPlanGroupKey) {
            case MembershipPlanGroupKey.LIKES:
            case MembershipPlanGroupKey.MULTICREATOR:
              GlobalStatesV3.routing.push(V3Routes.home());
              break;
            default:
              location.assign(AbsoluteAccountRoutes.home().stringify());
              break;
          }
        }, 1000);
      });
    }
  }

  private createQustionStateFor(link: SurveyQuestionLinkIndexView) {
    const question = link.survey_question;
    const required = link.required;
    switch (question.question_type) {
      case SurveyQuestionType.MULTI_SELECT:
        return new MultiSelectQuestionState(question, required);
      case SurveyQuestionType.SINGLE_SELECT:
        return new SingleSelectQuestionState(question, required);
      case SurveyQuestionType.TOP_K_SELECT:
        return new TopKSelectQuestionState(question, required);
      case SurveyQuestionType.DEGREE:
        return new DegreeQuestionState(question, required);
      case SurveyQuestionType.SATISFACTION:
        return new SatisfactionQuestionState(question, required);
      case SurveyQuestionType.MULTI_LINE_FA:
        return new MultiLineFreeAnswerQuestionState(question, required);
      case SurveyQuestionType.SINGLE_LINE_FA:
        return new SingleLineFreeAnswerQuestionState(question, required);
      case SurveyQuestionType.DATE:
        return new DateQuestionState(question, required);
      case SurveyQuestionType.SINGLE_SELECT_PULL_DOWN:
        return new SingleSelectPullDownQuestionState(question, required);
      default:
        return null;
    }
  }

  nextQuestion() {
    this.currentQuestionNumber++;
  }

  prevQuestion() {
    this.currentQuestionNumber--;
  }
}
