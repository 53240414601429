import React from 'react';
import './serviceApplicationCards.module.scss';
import { Button } from '../../../../commonv3/components/atoms/buttons/Button';
import { V3Routes } from '../../../../mainv3/routes';
import { LikesLogo } from '../../../../commonv2/components/navigation/LikesLogo';
import { MoneyLogoSvg } from '../../../containers/navigation/MoneyLogoSvg';
import { Service } from '../../../../commonv3/types/service';

const Contents = {
  likes: {
    text: (
      <>
        私らしい働き方を実現するキャリア
        <br />
        ＆ライフコーチングスクール
      </>
    ),
    logo: <LikesLogo color="white" styleName="likes-logo" />,
    bg: 'likes-bg',
    url: V3Routes.likesUrl(V3Routes.applicationUserInfo().stringify()),
  },
  money: {
    text: (
      <>
        私らしい生き方を叶える
        <br />
        マネースクール
      </>
    ),
    logo: <MoneyLogoSvg styleName="money-logo" />,
    bg: 'money-bg',
    url: V3Routes.likesUrl(V3Routes.moneyApplication().stringify()),
  },
};

type CardProps = {
  service: Service;
  submitting: boolean;
};

const ApplicationCard = ({ service, submitting }: CardProps): JSX.Element => {
  const setContent = (service: Service) => {
    switch (service) {
      case Service.LIKES:
        return Contents.likes;
      case Service.MONEY:
        return Contents.money;
    }
  };
  const content = setContent(service);

  return (
    <div styleName="application-card" id={`application-card-${service}`}>
      <div styleName={`card-header ${content.bg}`}>{content.logo}</div>
      <div styleName="card-body">
        <span styleName="info-text">{content.text}</span>
        <Button
          id={`apply-button-${service}`}
          styleName="application-cta"
          type="normal"
          size="m"
          disabled={submitting}
          href={content.url}
        >
          申し込む
        </Button>
      </div>
    </div>
  );
};

type Props = {
  services?: Service[];
  submitting: boolean;
};

export const ServiceApplicationCards = ({ services = [Service.LIKES, Service.MONEY], submitting }: Props) => {
  return (
    <>
      <div styleName="header">新規申し込み</div>
      <div styleName="body">
        {services.map((s, i) => {
          return <ApplicationCard service={s} submitting={submitting} key={i} />;
        })}
      </div>
    </>
  );
};
