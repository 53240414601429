import * as React from 'react';
import './questionNumberIndicator.module.scss';

type Props = {
  questionNumber: number;
  totalQuestions: number;
  styleName?: string;
  className?: string;
};

export const QuestionNumberIndicator = ({ questionNumber, totalQuestions, className }: Props) => (
  <div styleName="indicator" className={className}>
    <span styleName="question-number">{questionNumber}</span>
    <span styleName="separator">/</span>
    <span styleName="total-questions">{totalQuestions}</span>
  </div>
);
