import React, { useEffect } from 'react';
import './resetPassword.module.scss';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { Page, Pane } from '../../../../mainv3/components/atoms/applications/page';
import { Button } from '../../../../commonv3/components/atoms/buttons/Button';
import { StringFieldInput } from '../../../../mainv3/components/atoms/forms/vertical/StringField';
import { ResetPasswordPageState } from '../../../containers/passwords/states/ResetPasswordPageState';
import { SignInNavigation } from '../navigation/SignInNavigation';
import { GlobalStatesV3 } from '../../../../commonv3/states/GlobalStatesV3';

type Props = {
  pageState: ResetPasswordPageState;
};

export const ResetPasswordPane = inject('pageState')(
  observer(({ pageState }: Props) => {
    useEffect(() => {
      pageState.init();
    }, []);

    const { resetPassword, submitting, passwordField, passwordConfirmationField, resetSuccess } = pageState;

    return (
      <>
        {GlobalStatesV3.viewport.isNonSp && <SignInNavigation />}
        <Page>
          <Pane>
            <div styleName="header">パスワードのリセット</div>
            <div styleName="subheader">新しいパスワードを設定してください。</div>
            <div styleName="body">
              <div styleName="form">
                <StringFieldInput
                  label="新しいパスワード"
                  placeholder="6文字以上"
                  type="password"
                  state={passwordField}
                />
                <StringFieldInput
                  label="新しいパスワードをもう一度入力"
                  placeholder="6文字以上"
                  type="password"
                  state={passwordConfirmationField}
                />
                <div id="reset-password-button-wrapper" styleName="button-wrapper">
                  <Button
                    id="reset-password-button"
                    styleName="button"
                    type={resetSuccess ? 'success' : 'primary'}
                    size="l"
                    onClick={resetPassword}
                    loading={submitting}
                  >
                    {resetSuccess ? '新しいパスワードの設定が完了しました' : '新しいパスワードを設定してログインする'}
                  </Button>
                </div>
              </div>
            </div>
          </Pane>
        </Page>
      </>
    );
  })
);
