import * as React from 'react';
import Icon from '@material-ui/core/Icon';

export type MaterialIconName =
  | 'arrow_drop_down'
  | 'calendar_today'
  | 'room'
  | 'attach_money'
  | 'checkbox'
  | 'payment'
  | 'error_outline'
  | 'done'
  | 'local_offer'
  | 'trending_up'
  | 'local_play'
  | 'add'
  | 'timer'
  | 'launch';

function createIcon(name: MaterialIconName) {
  return ({ className = undefined }) => <Icon className={className}>{name}</Icon>;
}

export const MaterialFontIcons = {
  ArrowDropDown: createIcon('arrow_drop_down'),
  CalendarToday: createIcon('calendar_today'),
  Room: createIcon('room'),
  AttachMoney: createIcon('attach_money'),
  CheckBox: createIcon('checkbox'),
  Payment: createIcon('payment'),
  Warning: createIcon('error_outline'),
  Success: createIcon('done'),
};
