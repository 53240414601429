import * as React from 'react';
import './youtubeIframe.module.scss';

type Props = {
  youtubeKey: string;
};

export const YoutubeIframe = ({ youtubeKey }: Props) => {
  return (
    <div styleName="youtube">
      <iframe
        width="560"
        height="315"
        src={`https://www.youtube-nocookie.com/embed/${youtubeKey}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};
