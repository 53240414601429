import React, { useEffect } from 'react';
import './resetPassword.module.scss';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { Page, Pane } from '../../../../mainv3/components/atoms/applications/page';
import { Button } from '../../../../commonv3/components/atoms/buttons/Button';
import { StringFieldInput } from '../../../../mainv3/components/atoms/forms/vertical/StringField';
import { ResetPasswordPageState } from '../../../containers/passwords/states/ResetPasswordPageState';
import { Loader } from '../../../../mainv3/components/atoms/forms/loader';
import { FormError, FormErrors } from '../../../../mainv3/components/atoms/forms/vertical/base';
import { SignInNavigation } from '../navigation/SignInNavigation';
import { GlobalStatesV3 } from '../../../../commonv3/states/GlobalStatesV3';

type Props = {
  pageState: ResetPasswordPageState;
};

export const ResetCodePane = inject('pageState')(
  observer(({ pageState }: Props) => {
    useEffect(() => {
      pageState.init();
    }, []);

    const { validateCode, submitting, resetCodeField, resetCodeResource, emailField, resendCode, resent, formError } =
      pageState;
    if (resetCodeResource.loading) {
      return <Loader />;
    }
    return (
      <>
        {GlobalStatesV3.viewport.isNonSp && <SignInNavigation />}
        <Page>
          <Pane>
            <div styleName="header">パスワードのリセット</div>
            <div styleName="subheader">
              {emailField.getDirtyValue()}へコードを送信しました。以下にコードを入力してください。
              <br />
              コード入力画面を閉じずにメールをご確認ください。
            </div>
            <div styleName="body">
              <div styleName="form">
                <StringFieldInput label="コード" placeholder="●●●●●●" type="text" state={resetCodeField} />
                <div id="validate-code-button-wrapper" styleName="button-wrapper">
                  <Button
                    id="validate-code-button"
                    styleName="button"
                    type="primary"
                    size="l"
                    onClick={validateCode}
                    disabled={submitting}
                  >
                    次へ進む
                  </Button>
                </div>
                {resent ? (
                  <div styleName="notification-wrapper">
                    <div id="success-notification" styleName="notification">
                      コードを再送しました。
                    </div>
                  </div>
                ) : (
                  <Button
                    type="link"
                    size="l"
                    styleName="link"
                    id="resend-code-button"
                    disabled={submitting}
                    onClick={resendCode}
                  >
                    コードを再送する
                  </Button>
                )}
                {formError && (
                  <div styleName="form-error">
                    <FormErrors>
                      <FormError>{formError}</FormError>
                    </FormErrors>
                  </div>
                )}
              </div>
            </div>
          </Pane>
        </Page>
      </>
    );
  })
);
