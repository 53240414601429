import { observable, action, makeObservable } from 'mobx';
import { PaymentApis } from '../../../mainv3/apis/payments';
import { RemoteResourceV3, updateRemoteResourceV3 } from '../../../commonv3/api/RemoteResourceV3';
import { PaymentShowView } from '../../../mainv3/types/payments';
import { CardSummary } from '../../../mainv2/types/card';
import { UserApis } from '../../../mainv2/api';
import { PaymentInfoPaneState } from '../../../mainv3/components/organisms/mypage/states/PaymentInfoPaneState';
import { GlobalStatesV3 } from '../../../commonv3/states/GlobalStatesV3';
import { CreditCardState } from '../../../mainv3/components/molecules/payment/states/CreditCardFormState';
import { SubmitState } from '../../../commonv3/states/SubmitState';
import { UserCardApis } from '../../../mainv3/apis/users';
import { routingStore } from '../../../common/stores/routing';
import { AccountRoutes } from '../../routes/AccountRoutes';

export class PaymentRetryPaneState {
  code: string | null = null;
  payment: RemoteResourceV3<PaymentShowView> = RemoteResourceV3.loading();
  cardSummary: RemoteResourceV3<CardSummary> = RemoteResourceV3.loading();
  cardState: CreditCardState = new CreditCardState(GlobalStatesV3.currentUser);
  paymentInfoPaneState: PaymentInfoPaneState = new PaymentInfoPaneState();
  submitState = new SubmitState();
  response: PaymentShowView | null = null;
  responseError: Record<string, string> | null = null;

  constructor() {
    makeObservable(this, {
      code: observable,
      payment: observable,
      cardSummary: observable,
      cardState: observable,
      paymentInfoPaneState: observable,
      submitState: observable,
      response: observable,
      responseError: observable,
      init: action.bound,
      reloadPayment: action.bound,
      reloadCardSummary: action.bound,
      submit: action.bound,
    });
  }

  async init(code: string) {
    this.code = code;
    await this.reloadPayment();
    this.reloadCardSummary();
    this.cardState.init();
  }

  async reloadPayment() {
    await updateRemoteResourceV3(
      this.payment,
      (v) => (this.payment = v),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      () => PaymentApis.show(this.code!)
    );
  }

  async reloadCardSummary() {
    await updateRemoteResourceV3(
      this.cardSummary,
      (v) => (this.cardSummary = v),
      () => UserApis.getCurrentCard()
    );
  }

  async submit(code: string) {
    await this.submitState.operate(async () => {
      if (this.cardState.editMode) {
        if (!(await this.cardState.validateNewCard())) {
          return;
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await UserCardApis.updateCard(this.cardState.newToken!);
      }
      try {
        const payment = await PaymentApis.create(code);
        GlobalStatesV3.currentUser.reloadUser();

        // Redirect after 3s
        if (payment.status === 'paid') {
          this.response = payment;
          setTimeout(() => {
            routingStore.push(AccountRoutes.payments());
            window.location.reload();
          }, 3000);
        } else {
          this.responseError = { body: '支払いに失敗しました' };
        }
      } catch (error) {
        this.responseError = error;
      }
    });
  }
}
