import { ISODate, ISODateTime } from '../../commonv3/types/dates';
import { MembershipItemIndexView, MembershipPlanGroupKey } from '../../mainv3/types/membershipPlans';

export type UserId = number;

export type UserPreference = {
  introduction_completed_at?: ISODateTime;
  coaching_tooltip_closed?: boolean;
  confirmed_home_location_alert?: boolean;
  receive_promotion_emails?: boolean;
  receive_notification_emails?: boolean;
  coaching_v2_introduction_completed?: boolean;
};

export enum V3MembershipType {
  STANDARD = 'standard',
  TRIAL = 'trial',
}

export enum V3MembershipPlanKey {
  LIKES_STANDARD = 'regular',
  CASUAL = 'casual',
  MAKUAKE_TRIAL = 'makuake_trial',
  MAKUAKE_STANDARD = 'makuake_standard',
  SPRING_2020 = 'spring_2020',
  GOTO_2020 = 'goto_2020',
  PLANNER = 'planner',
}

export type ImpersonateUser = {
  id: UserId;
  name: string;
  email: string;
};

export type SecureUser = {
  id: UserId;
  name: string;
  age: number;
  furigana: string;
  email: string;
  phone_number: string;
  job_title: string;
  job_department: string;
  job_company: string;
  facebook_url: string;
  profile_url: string;
  payment_info?: PaymentInfo;
  user_preference: UserPreference;
  has_password: boolean;
  v2_member: boolean;
  v3_member: boolean;
  v3_membership_type: V3MembershipType;
  has_effective_membership_token: boolean;
  has_effective_multicretor_membership_token: boolean;
  membership_plan_keys: V3MembershipPlanKey[];
  membership_plan_group_keys: MembershipPlanGroupKey[];
  membership_items: MembershipItemIndexView[];
  first_payment_date_after_migration: ISODateTime | null;
  withdrawal_applicable_dates: ISODate[] | null;
  activated_course_count: number;
  // 退会前の期間を含む、ユーザの最初の加入日。非会員の場合はnull
  joined_date: ISODate | null;
  // 退会前の期間を含む、ユーザの最初の加入月（ "Y-m" ） 。非会員の場合はnull
  joined_month: string | null;
  // 退会前の期間を無視した、ユーザの最初の加入日。非会員・退会者の場合はnull
  strict_joined_date: ISODate | null;
  days_since_join: number;
  has_likes_active_subscription: boolean;
  impersonating_user: ImpersonateUser;
};

export type User = {
  id: UserId;
  name: string;
  furigana: string;
  age: number;
  email: string;
  job_title: string;
  job_company: string;
  facebook_url: string;
  profile_url: string;
  phone_number: string;
  via: string;
  new_tickets_count: number;
};

export type PaymentInfo = {
  has_card: boolean;
};
