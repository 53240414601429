import { useForm } from 'react-hook-form';

type UserPersonalFormValues = {
  firstName: string;
  firstNameKana: string;
  lastName: string;
  lastNameKana: string;
  phoneNumber: string;
  userAddress: {
    zipcode: string;
    prefecture: string;
    city: string;
    address: string;
    building: string;
  };
};

export const useUserPersonalForm = () => {
  return useForm<UserPersonalFormValues>({
    criteriaMode: 'all',
    defaultValues: {
      firstName: '',
      firstNameKana: '',
      lastName: '',
      lastNameKana: '',
      phoneNumber: '',
      userAddress: {
        zipcode: '',
        prefecture: '',
        city: '',
        address: '',
        building: '',
      },
    },
  });
};
