import * as React from 'react';
import './warning.module.scss';

export const Warning = ({ id = undefined, children }: { id?: string; children: React.ReactNode }) => {
  return (
    <div id={id} styleName="warning-body">
      {children}
    </div>
  );
};
