/**
 * ISO date time format: e.g. 2019-06-01T10:00:00
 */
export type ISODateTime = string;
/**
 * ISO date format: e.g. 2019-06-01
 */
export type ISODate = string;

export const DAY_OF_WEEKS = ['日', '月', '火', '水', '木', '金', '土'];
