import { action, computed, observable, makeObservable } from 'mobx';
import { SubmitState } from '../../commonv3/states/SubmitState';
import { createContext, useContext, useEffect } from 'react';
import { RemoteResourceV3, updateRemoteResourceV3 } from '../../commonv3/api/RemoteResourceV3';
import { SubscriptionSummariesApis } from '../../mainv3/apis/subscriptionSummaries';
import { Service } from '../../commonv3/types/service';
import { SubscriptionSummary, StatusType } from '../../mainv3/types/subscriptionSummary';
import dayjs from 'dayjs';

class PlanListState {
  initialized = false;
  initState = new SubmitState();
  summaryListResource: RemoteResourceV3<SubscriptionSummary[]> = RemoteResourceV3.loading();

  constructor() {
    makeObservable(this, {
      initialized: observable,
      initState: observable,
      summaryListResource: observable,
      init: action.bound,
      loadSummaries: action.bound,
      loading: computed,
      summaries: computed,
      hasLikesPlans: computed,
      likesSummaries: computed,
      isActiveLikesMember: computed,
      hasActiveBeautyPlans: computed,
      hasFeatureBeautyPlans: computed,
      beautySummaries: computed,
      isActiveBeautyMember: computed,
      hasActiveMoneyPlans: computed,
      hasFeatureMoneyPlans: computed,
      moneySummaries: computed,
      isActiveMoneyMember: computed,
      initializing: computed,
    });
  }

  async init() {
    await this.initState.operate(async () => {
      await this.loadSummaries();
    });
    this.initialized = true;
  }

  async loadSummaries() {
    await updateRemoteResourceV3(
      this.summaryListResource,
      (v) => (this.summaryListResource = v),
      () => SubscriptionSummariesApis.findAll([Service.BEAUTY, Service.MONEY, Service.LIKES])
    );
  }

  get loading() {
    return this.summaryListResource.loading;
  }

  get summaries(): SubscriptionSummary[] {
    return this.summaryListResource.getOrElse([]);
  }

  get hasLikesPlans(): boolean {
    return this.likesSummaries.length > 0;
  }

  get likesSummaries(): SubscriptionSummary[] {
    return this.summaries.filter((s) => s.service === Service.LIKES);
  }

  get isActiveLikesMember(): boolean {
    return (
      this.likesSummaries.find((b) => {
        return b.active;
      }) != null
    );
  }

  get hasActiveBeautyPlans(): boolean {
    return this.beautySummaries.some((s) => s.active === true);
  }

  get hasFeatureBeautyPlans(): boolean {
    const today = dayjs().format('YYYY-MM-DD');
    return this.beautySummaries.some((s) => s.status === StatusType.ACTIVE && s.current_period_starts_at > today);
  }

  get beautySummaries(): SubscriptionSummary[] {
    return this.summaries.filter((s) => s.service === Service.BEAUTY);
  }

  get isActiveBeautyMember(): boolean {
    return (
      this.beautySummaries.find((b) => {
        return b.active;
      }) != null
    );
  }

  get hasActiveMoneyPlans(): boolean {
    return this.moneySummaries.some((s) => s.active === true);
  }

  get hasFeatureMoneyPlans(): boolean {
    const today = dayjs().format('YYYY-MM-DD');
    return this.moneySummaries.some((s) => s.status === StatusType.ACTIVE && s.current_period_starts_at > today);
  }

  get moneySummaries(): SubscriptionSummary[] {
    return this.summaries.filter((s) => s.service === Service.MONEY);
  }

  get isActiveMoneyMember(): boolean {
    return (
      this.moneySummaries.find((b) => {
        return b.active;
      }) != null
    );
  }

  get initializing() {
    return this.initState.submitting;
  }
}

const PlanListPageContext = createContext(new PlanListState());

export const usePlanListState = () => {
  const state = useContext(PlanListPageContext);
  useEffect(() => {
    if (!state.initialized && !state.initializing) {
      state.init();
    }
  }, []);
  return state;
};
