import React, { ReactNode } from 'react';
import './Page.module.scss';

type Props = {
  id?: string;
  testId?: string;
  children: ReactNode;
};

/**
 * 基本のページコンポーネント
 */
export const Page = ({ id, testId, children }: Props) => {
  return (
    <div styleName="page" id={id} data-testid={testId}>
      <div styleName="page-inner">{children}</div>
    </div>
  );
};
