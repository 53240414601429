import * as React from 'react';
import classNames from 'classnames';
import './selectInput.module.scss';
import { MaterialFontIcons } from '../../icons/icons';

export type SelectOptionSpec<T> = {
  label: string;
  value: T;
  metaAttributes?: object;
};

type Props<T> = {
  id?: string;
  name?: string;
  error?: boolean;
  value?: T;
  size?: 'm' | 'l';
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (nextValue: T) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur?: (e: React.FormEvent) => any;
  serializer: (v: T) => string;
  deserializer: (v: string) => T;
  className?: string;
  styleName?: string;
  options: SelectOptionSpec<T>[];
  metaAttributes?: object;
};

export function SelectInput<T>({
  id,
  name,
  error,
  size = 'm',
  value,
  onChange,
  onBlur,
  options,
  className,
  placeholder,
  serializer,
  deserializer,
  metaAttributes,
}: Props<T>) {
  const selected = value !== null && value !== undefined;
  return (
    <div styleName={classNames('select-wrap', { error: error })} className={className}>
      <select
        id={id}
        name={name}
        value={serializer(value)}
        onChange={(e) => onChange && onChange(deserializer(e.target.value))}
        onBlur={onBlur}
        styleName={classNames('select', size, { selected: selected, error: error })}
        {...(metaAttributes || {})}
      >
        {placeholder && (
          <option value="" styleName="placeholder">
            {placeholder}
          </option>
        )}
        {options.map((o) => {
          const serializedValue = serializer(o.value);
          return (
            <option key={serializedValue} value={serializedValue} {...(o.metaAttributes || {})}>
              {o.label}
            </option>
          );
        })}
      </select>
      <MaterialFontIcons.ArrowDropDown styleName={classNames('icon', size)} />
    </div>
  );
}
