import React from 'react';
import { SingleLineFreeAnswerQuestionState } from './states/QuestionState';
import { QuestionCard } from '../../molecules/surveys/QuestionCard';
import { observer } from 'mobx-react-lite';
import { StringInput } from '../../../../commonv3/components/atoms/forms/primitive/StringInput';
import './singleLineFreeAnswerCard.module.scss';
import { SurveyQuestionFormat } from '../../../../commonv3/types/surveys';

type Props = {
  questionNumber: number;
  totalQuestions: number;
  questionState: SingleLineFreeAnswerQuestionState;
  format?: SurveyQuestionFormat;
};

export const SingleLineFreeAnswerCard = observer(({ questionNumber, totalQuestions, questionState, format }: Props) => {
  const { question, value, setValue, required, validate, errorMessage } = questionState;

  return (
    <QuestionCard
      questionNumber={questionNumber}
      totalQuestions={totalQuestions}
      sentence={question.sentence}
      subSentence={question.sub_sentence}
      youtubeKey={question.youtube_key}
      required={required}
      errorMessage={errorMessage}
      onBlur={validate}
      format={format}
    >
      <div styleName="content">
        <StringInput styleName="string-input" value={value} onChange={setValue} />
      </div>
    </QuestionCard>
  );
});
