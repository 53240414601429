import { FormLike, Validator } from '../FormStateV3';
import { UserApis } from '../../../../mainv2/api';
import { FieldSetType } from '../../../../mainv2/containers/common/userInfo/states/UserFormState';
import { ApiError } from '../../../../mainv2/api/error';

export class BooleanValidator<V> implements Validator<V, string> {
  constructor(private presenceChecker: (value: V) => boolean | Promise<boolean>, private message: string) {}
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  async validate(_id: string, currentValue: any): Promise<string | null> {
    const result = await this.presenceChecker(currentValue);
    if (!result) {
      return this.message;
    }
    return null;
  }
}

export const StringValidators = {
  required(message = '入力してください') {
    return new BooleanValidator<string>((value) => !!value, message);
  },

  googleUrl(message = 'ファイルの共有権限を確認してください') {
    return new BooleanValidator<string>((value) => {
      return !value || value.includes('sharing');
    }, message);
  },
  maxLength(maxLength: number, message = `${maxLength}文字以内で入力してください`) {
    return new BooleanValidator<string>((value) => {
      if (!value) {
        return true;
      } else {
        return value.length <= maxLength;
      }
    }, message);
  },
};

export const PasswordValidators = {
  minLength(threshold: number) {
    return new BooleanValidator<string>((value) => value.length >= threshold, `${threshold}文字以上を入力してください`);
  },
};

export const DependencyValidators = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  equalWith(theOtherForm: FormLike<any, any>, message: string) {
    return {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      validate(id: string, currentValue: any): any | undefined {
        if (currentValue && currentValue !== theOtherForm.getDirtyValue()) {
          return message;
        }
        return null;
      },
    };
  },
};

export const EmailValidators = {
  simple(message = '正しい値を入力してください') {
    const regex = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return new BooleanValidator<string>((value) => regex.test(value), message);
  },
  combined() {
    return {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      async validate(id: string, currentValue: any): Promise<string | undefined> {
        try {
          await UserApis.validateForm(
            {
              email: currentValue,
            },
            FieldSetType.TRIAL
          );
          return undefined;
        } catch (e) {
          if (e instanceof ApiError && e.isValidationError()) {
            return e.body['email'] && e.body['email'][0];
          }
        }
      },
    };
  },
};

export const NumberValidators = {
  required(message = '入力してください') {
    return new BooleanValidator<number>((value) => value !== null && value !== undefined, message);
  },
  valid(message = '正しい値を入力してください') {
    return new BooleanValidator<number>((value) => Number.isFinite(Number(value)), message);
  },
  greaterThanOrEqual(threshold: number) {
    return new BooleanValidator<number>((value) => value >= threshold, `${threshold}以上で入力してください`);
  },
  lessThanOrEqual(threshold: number) {
    return new BooleanValidator<number>((value) => value <= threshold, `${threshold}以下で入力してください`);
  },
  equalLength(threshold: number) {
    return new BooleanValidator<number>(
      (value) => value.toString().length == threshold,
      `${threshold}桁を入力してください`
    );
  },
};
