import React, { useEffect } from 'react';
import './emailChangePage.module.scss';
import { Page, PageBody, PageHeader } from '../../components/layout/page';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { EmailChangePageState } from './states/EmailChangePageState';
import { FormInput } from '../../../mainv3/components/atoms/forms/FormInput';
import { CheckBoxInput } from '../../../mainv3/components/atoms/forms/CheckBoxInput';
import { Button } from '../../../commonv3/components/atoms/buttons/Button';

export const EmailChangePage = observer(() => {
  const { pageState } = useLocalObservable(() => ({ pageState: new EmailChangePageState() }));

  useEffect(() => {
    pageState.init();
  }, []);

  const { submitting, formState, submit } = pageState;
  return (
    <Page>
      <PageHeader>メールの設定</PageHeader>
      <PageBody>
        <div styleName="form-label">メールアドレス</div>
        <FormInput
          styleName="email"
          name="email"
          value={formState.getValue('email')}
          placeholder=""
          onChange={(e) => formState.setValue('email', e.target.value)}
          error={formState.getError('email')}
          className="account-page-form-input"
        />
        <div styleName="form-label">配信設定</div>
        <CheckBoxInput
          label={'メールマガジンを許可する'}
          checked={formState.getValue('receive_promotion_emails')}
          onCheck={(checked) => formState.setValue('receive_promotion_emails', checked)}
          error={formState.getError('receive_promotion_emails')}
          className={'receive-promotion-emails-check-box'}
        />
        <div styleName="note">※ キャンペーンやSHEについてのインタビューなどお得な情報をお送りします</div>
        <div styleName="receive-notification-emails">
          <CheckBoxInput
            label={'会員限定のお知らせを許可する'}
            checked={formState.getValue('receive_notification_emails')}
            onCheck={(checked) => formState.setValue('receive_notification_emails', checked)}
            error={formState.getError('receive_notification_emails')}
            className={'receive-notification-emails-check-box'}
          />
        </div>
        <div styleName="note">※ 最新の特別イベント・教室利用についての重要なお知らせをお送りします</div>
        <div styleName="form-submit-button">
          <Button
            id="email-change-submit"
            type="primary"
            size="m"
            styleName="button"
            loading={submitting}
            onClick={() => submit()}
            className="form-submit-button"
          >
            保存する
          </Button>
        </div>
      </PageBody>
    </Page>
  );
});
