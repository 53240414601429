/* eslint-disable */

import 'whatwg-fetch';
import QueryString from 'query-string';
import { locatePath } from '../RelativeUrl';
import { ApiCallable } from './ApiCallable';
import { GlobalStatesV3 } from '../../commonv3/states/GlobalStatesV3';
const csrfConfig = {
  param: '',
  token: '',
};

// Resides here to simulate api call latency
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export class Api {
  basepath = locatePath('/api/v1');
  path = '';
  pathVariables = {};
  params = {};

  constructor(
    basepath: string,
    path: string = '',
    pathVariables: Record<string, string> = {},
    params: Record<string, string> = {}
  ) {
    this.basepath = basepath;
    this.path = path;
    this.pathVariables = pathVariables;
    this.params = params;
  }

  appendPath(path: string): Api {
    const paths = [this.path, path].map((v) => v.replace(/^\//, '')).filter((v) => v);
    return new Api(this.basepath, paths.join('/'), this.pathVariables, this.params);
  }

  withId(id: number | string): Api {
    return this.appendPath(id.toString());
  }

  bind(pathVariables: Record<string, string>): Api {
    return new Api(this.basepath, this.path, { ...this.pathVariables, ...pathVariables }, this.params);
  }

  addParams(params: Record<string, any>) {
    return new Api(this.basepath, this.path, this.pathVariables, { ...this.params, ...params });
  }

  _renderUrl() {
    let path = this.path;
    Object.entries(this.pathVariables).forEach((entry) => {
      path = path.replace(`:${entry[0]}`, String(entry[1]));
    });
    const authParams = {
      [csrfConfig.param]: csrfConfig.token,
    };
    return [this.basepath, path].join('/') + '?' + QueryString.stringify({ ...this.params, ...authParams });
  }

  async fetchAsRaw(config: any): Promise<Response> {
    const defaultOptions = {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await GlobalStatesV3.jwt.jwt.getOrElse('')}`,
      },
    };
    return sleep(300).then(() => fetch(this._renderUrl(), { ...defaultOptions, ...config }));
  }

  fetch(config: any) {
    return this.fetchAsRaw(config).then((response) => {
      if (response.status >= 400 && response.status < 500) {
        return null;
      } else {
        return response.json();
      }
    });
  }

  get(params: Record<string, any> = {}): any {
    return this.addParams(params).fetch({
      method: 'GET',
    });
  }

  post(payload: Record<string, any> | null | undefined = null): any {
    return this.fetch({
      method: 'POST',
      body: payload ? JSON.stringify(payload) : null,
    });
  }

  postAsRaw(payload: Record<string, any> | null | undefined = null): Promise<Response> {
    return this.fetchAsRaw({
      method: 'POST',
      body: payload ? JSON.stringify(payload) : null,
    });
  }

  put(payload: Record<string, any> | null | undefined = null): any {
    return this.fetch({
      method: 'PUT',
      body: payload ? JSON.stringify(payload) : null,
    });
  }

  delete(payload: Record<string, any> | null | undefined = null): any {
    return this.fetch({
      method: 'DELETE',
      body: payload ? JSON.stringify(payload) : null,
    });
  }

  putForm(data: FormData): any {
    return this.fetch({
      method: 'PUT',
      body: data,
      headers: {
        Authorization: `Bearer ${GlobalStatesV3.jwt.jwt.getOrElse('')}`,
      },
    });
  }

  postForm(data: FormData): any {
    return this.fetch({
      method: 'POST',
      body: data,
      headers: {
        Authorization: `Bearer ${GlobalStatesV3.jwt.jwt.getOrElse('')}`,
      },
    });
  }

  toGetCallable<T>(params: Record<string, any> = {}) {
    return new ApiCallable(this._renderUrl(), () => this.get(params));
  }
}
export const initCsrfConfig = () => {
  const param = (document.querySelector('meta[name=csrf-param]') as any).content;
  const token = (document.querySelector('meta[name=csrf-token]') as any).content;
  csrfConfig.param = param;
  csrfConfig.token = token;
};
