import { action, computed, observable, makeObservable } from 'mobx';
import { RemoteResourceV3, updateRemoteResourceV3 } from '../../../../commonv3/api/RemoteResourceV3';
import { ReservationIndexView } from '../../../types/reservations';
import { EventReservationIndexView } from '../../../types/eventReservations';
import { EventReservationApis } from '../../../apis/eventReservations';
import { ReservationApis } from '../../../apis/reservations';
import { translateErrorMessage } from '../../../../mainv2/api/error';
import { GlobalStatesV3 } from '../../../../commonv3/states/GlobalStatesV3';
import { V3Routes } from '../../../routes';
import { AllReservationIndexView } from '../../../components/molecules/reservations/ReservationCardList';
import { AccountRoutes } from '../../../../account/routes/AccountRoutes';

export class AttendancePageState {
  eventReservations: RemoteResourceV3<EventReservationIndexView[]> = RemoteResourceV3.loading();
  reservations: RemoteResourceV3<ReservationIndexView[]> = RemoteResourceV3.loading();

  constructor() {
    makeObservable(this, {
      eventReservations: observable,
      reservations: observable,
      init: action.bound,
      allReservationList: computed,
      loading: computed,
      attend: action.bound,
    });
  }

  async init() {
    const today = new Date();
    await updateRemoteResourceV3(
      this.eventReservations,
      (v) => (this.eventReservations = v),
      () => EventReservationApis.findByDate(today)
    );
    await updateRemoteResourceV3(
      this.reservations,
      (v) => (this.reservations = v),
      () => ReservationApis.findByDate(today)
    );
  }

  get allReservationList(): AllReservationIndexView[] {
    return [...this.eventReservations.getOrElse([]), ...this.reservations.getOrElse([])];
  }

  get loading(): boolean {
    return this.reservations.loading || this.eventReservations.loading;
  }

  async attend(reservation: AllReservationIndexView, isAccountPage: boolean) {
    try {
      switch (reservation.reservable_type) {
        case 'TrialLesson':
          await EventReservationApis.attend(reservation.id);
          GlobalStatesV3.routing.push(
            isAccountPage
              ? AccountRoutes.eventReservationAttendanceComplete(reservation.id)
              : V3Routes.eventReservationAttendanceComplete(reservation.id)
          );
          break;
        case 'Event':
          await EventReservationApis.attend(reservation.id);
          GlobalStatesV3.routing.push(
            isAccountPage
              ? AccountRoutes.eventReservationAttendanceComplete(reservation.id)
              : V3Routes.eventReservationAttendanceComplete(reservation.id)
          );
          break;
        case 'Session':
          await ReservationApis.attend(reservation.id);
          GlobalStatesV3.routing.push(
            isAccountPage
              ? AccountRoutes.reservationAttendanceComplete(reservation.id)
              : V3Routes.reservationAttendanceComplete(reservation.id)
          );
          break;
      }
    } catch (e) {
      alert('更新に失敗しました: ' + translateErrorMessage(e));
    }
  }
}
