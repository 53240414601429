import React from 'react';
import './questionCard.module.scss';
import { SurveyQuestionType, SurveyQuestion } from '../../../commonv3/types/survey_questions';
import { SingleLineFreeAnswerCard } from '../../components/organisms/surveys/SingleLineFreeAnswerCard';
import { MultiLineFreeAnswerCard } from '../../components/organisms/surveys/MultiLineFreeAnswerCard';
import { SingleSelectQuestionCard } from '../../components/organisms/surveys/SingleSelectQuestionCard';
import { MultiSelectQuestionCard } from '../../components/organisms/surveys/MultiSelectQuestionCard';
import { SatisfactionQuestionCard } from '../../components/organisms/surveys/SatisfactionQuestionCard';
import { DegreeQuestionCard } from '../../components/organisms/surveys/DegreeQuestionCard';
import { DateQuestionCard } from '../../components/organisms/surveys/DateQuestionCard';
import { TopKSelectQuestionCard } from '../../components/organisms/surveys/TopKSelectQuestionCard';
import { SurveyQuestionFormat } from '../../../commonv3/types/surveys';
import { SingleSelectPullDownQuestionCard } from '../../components/organisms/surveys/SingleSelectPullDownQuestionCard';

type Props = {
  questionNumber: number;
  totalQuestions: number;
  question: SurveyQuestion;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  questionState: any;
  format?: SurveyQuestionFormat;
};

const QuestionCardInner = ({ questionNumber, totalQuestions, question, questionState, format }: Props) => {
  switch (question.question_type) {
    case SurveyQuestionType.SINGLE_LINE_FA:
      return (
        <SingleLineFreeAnswerCard
          questionNumber={questionNumber}
          totalQuestions={totalQuestions}
          questionState={questionState}
          format={format}
        />
      );
    case SurveyQuestionType.MULTI_LINE_FA:
      return (
        <MultiLineFreeAnswerCard
          questionNumber={questionNumber}
          totalQuestions={totalQuestions}
          questionState={questionState}
          format={format}
        />
      );
    case SurveyQuestionType.SINGLE_SELECT:
      return (
        <SingleSelectQuestionCard
          questionNumber={questionNumber}
          totalQuestions={totalQuestions}
          questionState={questionState}
          format={format}
        />
      );
    case SurveyQuestionType.MULTI_SELECT:
      return (
        <MultiSelectQuestionCard
          questionNumber={questionNumber}
          totalQuestions={totalQuestions}
          questionState={questionState}
          format={format}
        />
      );
    case SurveyQuestionType.TOP_K_SELECT:
      return (
        <TopKSelectQuestionCard
          questionNumber={questionNumber}
          totalQuestions={totalQuestions}
          questionState={questionState}
          format={format}
        />
      );
    case SurveyQuestionType.SATISFACTION:
      return (
        <SatisfactionQuestionCard
          questionNumber={questionNumber}
          totalQuestions={totalQuestions}
          questionState={questionState}
          format={format}
        />
      );
    case SurveyQuestionType.DEGREE:
      return (
        <DegreeQuestionCard
          questionNumber={questionNumber}
          totalQuestions={totalQuestions}
          questionState={questionState}
          format={format}
        />
      );
    case SurveyQuestionType.DATE:
      return (
        <DateQuestionCard
          questionNumber={questionNumber}
          totalQuestions={totalQuestions}
          questionState={questionState}
          format={format}
        />
      );
    case SurveyQuestionType.SINGLE_SELECT_PULL_DOWN:
      return (
        <SingleSelectPullDownQuestionCard
          questionNumber={questionNumber}
          totalQuestions={totalQuestions}
          questionState={questionState}
          format={format}
        />
      );
    default:
      return null;
  }
};

export const QuestionCardOneQuestion = (props) => <QuestionCardInner {...props} />;
