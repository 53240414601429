import { makeObservable, observable } from 'mobx';
import { RemoteResourceV3, updateRemoteResourceV3 } from '../api/RemoteResourceV3';
import { JwtApis } from '../api/jwts';

export class JwtState {
  jwt: RemoteResourceV3<string> = RemoteResourceV3.loading();

  constructor() {
    makeObservable(this, {
      jwt: observable,
    });
  }

  async reloadJwt() {
    await updateRemoteResourceV3(
      this.jwt,
      (v) => (this.jwt = v),
      () => JwtApis.find()
    );
  }
}
