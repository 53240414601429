import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';
import Textarea from 'react-textarea-autosize';
import './stringInput.module.scss';

type StringInputProps = {
  id?: string;
  name?: string;
  type?: 'text' | 'password';
  size?: 'm' | 'l';
  value?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (nextValue: string) => any;
  placeholder?: string;
  error?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFocus?: (e: SyntheticEvent) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e: SyntheticEvent) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur?: (e) => any;
  className?: string;
  styleName?: string;
  metaAttributes?: object;
};
export const StringInput = ({
  id,
  name,
  type,
  size = 'm',
  error,
  value = '',
  onChange,
  placeholder,
  onFocus,
  onBlur,
  onClick,
  className,
  metaAttributes,
}: StringInputProps) => (
  <input
    id={id}
    name={name}
    styleName={classNames('string-input', size, { error: error })}
    className={className}
    value={value}
    onChange={(e) => onChange(e.target.value)}
    type={type}
    placeholder={placeholder}
    onFocus={onFocus}
    onClick={onClick}
    onBlur={onBlur}
    {...(metaAttributes || {})}
  />
);

type MultiLineInputProps = {
  id?: string;
  name?: string;
  error?: boolean;
  placeholder?: string;
  size?: 'm' | 'l';
  value?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (nextValue: string) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e: SyntheticEvent) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur?: () => any;
  className?: string;
  styleName?: string;
  metaAttributes?: object;
};
export const MultiLineStringInput = ({
  id,
  name,
  error,
  size = 'm',
  value,
  onChange,
  onClick,
  placeholder,
  onBlur,
  className,
  metaAttributes,
}: MultiLineInputProps) => (
  <Textarea
    id={id}
    name={name}
    styleName={classNames('string-input multiline', size, { error: error })}
    className={className}
    onChange={(e) => onChange(e.target.value)}
    onClick={onClick}
    value={value}
    placeholder={placeholder}
    onBlur={onBlur}
    {...(metaAttributes || {})}
  />
);
