import { BrandIcon } from '../../atoms/cards/BrandIcon';
import * as React from 'react';
import { CardSummary } from '../../../../mainv2/types/card';
import './currentCardSummary.module.scss';
import { Button, LabelText } from '@sheinc/shelikes-design-system';

type ContainerProps = {
  className?: string;
};

export const CardSummaryContainer: React.FC<ContainerProps> = ({ children, className }) => {
  return (
    <div styleName="payment-info" className={className}>
      <div styleName="heading">
        <LabelText size="L" weight="bold">
          現在のお支払い情報
        </LabelText>
      </div>
      {children}
    </div>
  );
};

type Props = {
  cardSummary: CardSummary;
  onRequestUpdate: () => void;
};

export const CurrentCardSummary: React.FC<Props> = ({ cardSummary, onRequestUpdate }) => {
  return (
    <CardSummaryContainer className="current-card-summary">
      <div styleName="card-number">
        <BrandIcon styleName="brand-icon" brand={cardSummary.brand} />
        <span styleName="card-number-text">
          <LabelText size="M">●●●● ●●●● {cardSummary.last4}</LabelText>
        </span>
      </div>
      <Button variant="secondary" size="small" onClick={onRequestUpdate} className="update-button">
        変更する
      </Button>
    </CardSummaryContainer>
  );
};
