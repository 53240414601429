import React, { useEffect, useState } from 'react';
import { SignInPane } from '../../components/organisms/sign_in/SignInPane';
import { GlobalStatesV3 } from '../../../commonv3/states/GlobalStatesV3';
import { RouteComponentProps } from 'react-router';
import { parseQueryParams } from '../../../mainv3/types/location';
import { observer } from 'mobx-react-lite';
import { AccountRoutes } from '../../routes/AccountRoutes';

export const SignInPage = observer((props: RouteComponentProps) => {
  const [redirectUrl, setRedirectUrl] = useState<string | string[] | undefined>(undefined);

  useEffect(() => {
    const { redirect_url } = parseQueryParams(props.location);
    setRedirectUrl(redirect_url ? redirect_url : AccountRoutes.profile().stringify());
    GlobalStatesV3.currentUser.reloadUser();
  }, []);

  return <SignInPane redirectUrl={redirectUrl} supportNewUser={true} />;
});
