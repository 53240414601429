import React, { useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { Page } from '../../../mainv3/components/atoms/applications/page';
import '../../../mainv3/containers/attendances/reservationAttendancePage.module.scss';
import { LoaderRow } from '../../../mainv3/components/atoms/layout/LoaderRow';
import { authenticated } from '../../../mainv3/components/hocs/authenticated';
import { ReservationAttendanceCompletePageState } from '../../../mainv3/containers/attendances/states/ReservationAttendanceCompletePageState';
import { ReservationId } from '../../../commonv3/types/reservations';
import { ReservationCard } from '../../../mainv3/components/molecules/reservations/ReservationCard';

export type Props = {
  match: {
    params: {
      reservationId: ReservationId;
    };
  };
};

export const ReservationAttendanceCompletePage = authenticated(
  observer((props: Props) => {
    const { pageState } = useLocalObservable(() => ({ pageState: new ReservationAttendanceCompletePageState() }));

    useEffect(() => {
      const { reservationId } = props.match.params;
      pageState.init(reservationId);
    }, []);

    const { loading, reservation } = pageState;
    const index = 1;
    return (
      <Page>
        {loading ? (
          <LoaderRow />
        ) : (
          <div>
            <div styleName="body">
              <div styleName="header">
                <div styleName="title">出席完了</div>
                <div styleName="text">スタッフにこちらの画面をみせてください</div>∂
              </div>
              <ReservationCard
                reservation={reservation.get()}
                index={index}
                showButton={false}
                onRequestUpdate={() => undefined}
              />
            </div>
          </div>
        )}
      </Page>
    );
  })
);
