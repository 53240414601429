import * as React from 'react';
import './page.module.scss';

type Props = {
  id?: string;
};

export const Page: React.FC<Props> = ({ id, children }) => {
  return (
    <div styleName="page" id={id}>
      {children}
    </div>
  );
};

export const PageHeader: React.FC = ({ children }) => (
  <div id="page-header" styleName="page-header">
    {children}
  </div>
);
export const PageBody: React.FC = ({ children }) => (
  <div id="page-body" styleName="page-body">
    {children}
  </div>
);
