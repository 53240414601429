import * as React from 'react';
import { observer } from 'mobx-react';
import { PaymentMethod } from '../../../../commonv3/types/payments';

type PaymentMethodViewProps = {
  paymentMethod: PaymentMethod;
};

/**
 * @deprecated REST API に依存したコンポーネントです。GraphQL APIと使用する場合は PaymentMethodView を使用してください。
 */
export const LegacyPaymentMethodView = observer(({ paymentMethod }: PaymentMethodViewProps) => {
  switch (paymentMethod) {
    case 'credit_card':
      return <>クレジットカードでの支払い</>;
    case 'transfer':
      return <>銀行口座での振込み</>;
    default:
      return <>不明なお支払方法</>;
  }
});
