import * as React from 'react';
import './base.module.scss';
import { Button } from '../../../../../commonv3/components/atoms/buttons/Button';

export const FormSection = ({ children }: { children: React.ReactNode }) => <div styleName="section">{children}</div>;

export const SectionLabel = ({ children }: { children: React.ReactNode }) => <div styleName="label">{children}</div>;
export const SectionBody = ({ children }: { children: React.ReactNode }) => <div styleName="body">{children}</div>;
export const FormErrors = ({ children }: { children: React.ReactNode }) => (
  <div className="errors" styleName="errors">
    {children}
  </div>
);
export const FormError = ({ children }: { children: React.ReactNode }) => (
  <div className="error" styleName="error">
    {children}
  </div>
);
export const ButtonWrapper = ({ children }: { children: React.ReactNode }) => (
  <div styleName="button-wrapper">{children}</div>
);

export const SubmitButton = ({
  id,
  children,
  loading = false,
  disabled = false,
  onClick = undefined,
}: {
  id?: string;
  children: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}) => (
  <Button
    id={id}
    styleName="submit-button"
    type="primary"
    size="l"
    loading={loading}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </Button>
);
