import { UserPreference } from '../types/user';
import { action, computed, observable, makeObservable } from 'mobx';
import { UserPreferenceApis } from '../../mainv3/apis/userPreferences';
import { SubmitState } from '../../commonv3/states/SubmitState';
import { checkPresent } from '../../commonv2/utils/preconditions';

export class UserPreferenceState {
  userPreference: UserPreference;
  submitState: SubmitState = new SubmitState();

  constructor(userPreference: UserPreference = {}) {
    makeObservable(this, {
      userPreference: observable,
      submitState: observable,
      update: action.bound,
      loading: computed,
      coachingTooltipClosed: computed,
      confirmedHomeLocationAlert: computed,
      coachingV2IntroductionCompleted: computed,
    });

    checkPresent(userPreference);
    this.userPreference = userPreference;
  }

  async update(diff: Partial<UserPreference>) {
    await this.submitState.operate(async () => {
      this.userPreference = await UserPreferenceApis.update(diff);
    });
  }

  get loading() {
    return this.submitState.submitting;
  }

  get coachingTooltipClosed(): boolean {
    return this.userPreference.coaching_tooltip_closed;
  }

  get confirmedHomeLocationAlert(): boolean {
    return this.userPreference.confirmed_home_location_alert;
  }

  get coachingV2IntroductionCompleted(): boolean {
    return this.userPreference.coaching_v2_introduction_completed;
  }
}
