import { MainV3ApiBase } from './base';
import type { CardSummary, PayjpTokenResponse } from '../../mainv2/types/card';

export const UserCardApis = {
  base() {
    return MainV3ApiBase.appendPath('users/current/card');
  },
  /**
   * Returns the {@link CardSummary} that the current user uses, or null if she doesn't have any cards.
   */
  getCurrentCard(): Promise<CardSummary> {
    return this.base().get();
  },
  /**
   * Updates the current card of the user
   * @param {string} token : the token provided from Payjp. Use {@link #createToken} for details
   * @returns {Promise<void>}
   */
  updateCard(token: string): Promise<void> {
    return this.base().put({ card_token: token });
  },
  /**
   * Creates the secure card token from the given cardElement
   * Or throws {@link PayjpError} if the request is invalid.
   * @param {cardElement}
   * @returns {Promise<PayjpTokenResponse>}
   */
  // TODO: ステートから外に出して usePaymentProvider 経由で利用できるようにする
  async createToken(cardElement: unknown): Promise<PayjpTokenResponse> {
    const res = await payjp.createToken(cardElement);
    if ('error' in res) {
      return Promise.reject(res.error);
    }
    return res;
  },
};
