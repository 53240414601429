import React from 'react';
import './reservationCardList.module.scss';
import { EventReservationIndexView } from '../../../types/eventReservations';
import { ReservationCard } from './ReservationCard';
import { ReservationIndexView } from '../../../types/reservations';

export type AllReservationIndexView = ReservationIndexView | EventReservationIndexView;
type Props = {
  reservations: AllReservationIndexView[];
  onRequestUpdate: (reservation: AllReservationIndexView) => void;
};

export const ReservationCardList = ({ reservations, onRequestUpdate }: Props) => (
  <div styleName="card_wrapper">
    <div styleName="reservations">
      {reservations.map((reservation, index) => {
        return (
          <ReservationCard
            key={reservation.id}
            reservation={reservation}
            index={index + 1}
            onRequestUpdate={onRequestUpdate}
          />
        );
      })}
    </div>
  </div>
);
