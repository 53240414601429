import { action, computed, observable, makeObservable } from 'mobx';
import { RemoteResourceV3, updateRemoteResourceV3 } from '../../../../commonv3/api/RemoteResourceV3';
import { PaymentIndexView } from '../../../../mainv3/types/payments';
import { PaymentApis } from '../../../../mainv3/apis/payments';
import { Service } from '../../../../commonv3/types/service';
import { SubmitState } from '../../../../commonv3/states/SubmitState';
import { createContext, useContext } from 'react';

export class PaymentListState {
  initState = new SubmitState();
  initialized = false;
  paymentsResource: RemoteResourceV3<PaymentIndexView[]> = RemoteResourceV3.loading();

  constructor() {
    makeObservable(this, {
      initState: observable,
      initialized: observable,
      paymentsResource: observable,
      init: action.bound,
      initializing: computed,
      loading: computed,
      reloadPayments: action.bound,
    });
  }

  async init() {
    try {
      await this.initState.operate(async () => {
        await this.reloadPayments();
      });
    } finally {
      this.initialized = true;
    }
  }
  get initializing() {
    return this.initState.submitting;
  }

  get loading() {
    return this.paymentsResource.loading;
  }

  async reloadPayments() {
    await updateRemoteResourceV3(
      this.paymentsResource,
      (v) => (this.paymentsResource = v),
      () => PaymentApis.findAll([Service.BEAUTY, Service.MONEY])
    );
  }
}

const PaymentListContext = createContext<{ value: PaymentListState | null }>({ value: null });

export const usePaymentList = (): PaymentListState => {
  const state = useContext(PaymentListContext);
  if (!state.value) {
    state.value = new PaymentListState();
    state.value.init();
  }
  return state.value;
};
