export type UserInfo = {
  name: string;
  furigana: string;
  email: string;
  age: number | string | null;
  phone_number: string;
  password: string;
  password_confirmation: string;
  job_company: string;
  job_department: string;
  job_title: string;
  facebook_url: string;
  via: string;
};

export enum FieldSetType {
  APPLICATION = 'application',
  EVENT = 'event',
  TRIAL = 'trial',
  SUCCINCT_TRIAL = 'succinct_trial',
}
