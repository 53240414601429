import { createClient, Client, cacheExchange, fetchExchange } from 'urql';
import { devtoolsExchange } from '@urql/devtools';
import { GlobalStatesV3 } from '../states/GlobalStatesV3';
import { authExchange as _authExchange } from '@urql/exchange-auth';
import { SheRavenClient } from '../../commonv2/modules/ravenClient';
// import { SignInPath } from '../../mainv3/routes/AuthPath';

const authExchange = _authExchange(async (utils) => {
  return {
    addAuthToOperation: (operation) => {
      const jwt = GlobalStatesV3.jwt.jwt.get();
      if (!jwt) return operation;
      return utils.appendHeaders(operation, {
        authorization: `Bearer ${jwt}`,
      });
    },
    async refreshAuth() {
      try {
        await GlobalStatesV3.jwt.reloadJwt();
        if (GlobalStatesV3.jwt.jwt.isError()) {
          throw new Error("can't get jwt");
        }
      } catch (_) {
        // FIXME: 現状、ログインが不要なページ（サインインや申込画面など）で認証が必要な graphql のエンドポイントが呼ばれているので把握するために通知する
        SheRavenClient.captureException(new Error('Invalid graphql request'), {
          extra: { cause: 'call authorized query in unauthorized page', page: window.location.href },
        });
        // FIXME: 不正なリクエストがなくなったら以下を有効にする
        // window.location.href = SignInPath(window.location.href).toString();
      }
    },
    didAuthError(error, _operation) {
      return error.graphQLErrors.some((e) => e.extensions?.code === 'LOGIN_ERROR');
    },
  };
});

const createGraphqlClient = (): Client => {
  return createClient({
    url: process.env.GRAPHQL_URL || '/graphql',
    exchanges: [devtoolsExchange, cacheExchange, authExchange, fetchExchange],
    requestPolicy: 'network-only',
    fetchOptions: () => {
      return { credentials: 'include' };
    },
  });
};

export const adminGraphQLClient = createGraphqlClient();
