// FIXME: この関数が呼び出されている箇所を確認し、実装をよりシンプルにしたほうが良さそう
export function isPresent<T>(value: T): value is NonNullable<T> {
  if (value === null || value === undefined) {
    return false;
  }
  if (typeof value === 'number') {
    return true;
  }
  if (typeof value === 'string') {
    return !!value;
  }
  return true;
}

export function allPresent(...values: unknown[]) {
  return values.every(isPresent);
}
