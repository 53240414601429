import { action, computed, observable, makeObservable } from 'mobx';
import { RemoteResourceV3, updateRemoteResourceV3 } from '../../../../commonv3/api/RemoteResourceV3';
import { ReservationIndexView } from '../../../types/reservations';
import { ReservationApis } from '../../../apis/reservations';
import { ReservationId } from '../../../../commonv3/types/reservations';

export class ReservationAttendanceCompletePageState {
  reservation: RemoteResourceV3<ReservationIndexView> = RemoteResourceV3.loading();

  constructor() {
    makeObservable(this, {
      reservation: observable,
      init: action.bound,
      eventReservation: computed,
      loading: computed,
    });
  }

  async init(reservationId: ReservationId) {
    await updateRemoteResourceV3(
      this.reservation,
      (v) => (this.reservation = v),
      () => ReservationApis.find(reservationId)
    );
  }

  get eventReservation(): ReservationIndexView {
    return this.reservation.get();
  }

  get loading(): boolean {
    return this.reservation.loading;
  }
}
