/* eslint-disable */

import 'whatwg-fetch';
import axios from 'axios';
import QueryString from 'query-string';
import { parseResponse } from './protocol';
import { GlobalStatesV3 } from '../states/GlobalStatesV3';

const csrfConfig = {
  param: '',
  token: '',
};

type Payload = { [key: string]: any };

export class ApiV3 {
  basepath = '';
  path = '';
  pathVariables: Payload = {};
  params: Payload = {};

  constructor(basepath: string, path = '', pathVariables: Payload = {}, params: Payload = {}) {
    this.basepath = basepath;
    this.path = path;
    this.pathVariables = pathVariables;
    this.params = params;
  }

  appendPath(path: string): ApiV3 {
    return new ApiV3(this.basepath, this.removeHeadSlash([this.path, path].join('/')), this.pathVariables, this.params);
  }

  withId(id: number | string): ApiV3 {
    return this.appendPath(id.toString());
  }

  bind(pathVariables: Payload): ApiV3 {
    return new ApiV3(this.basepath, this.path, { ...this.pathVariables, ...pathVariables }, this.params);
  }

  addParams(params: Payload) {
    return new ApiV3(this.basepath, this.path, this.pathVariables, { ...this.params, ...params });
  }

  _renderUrl() {
    let path = this.path;
    Object.keys(this.pathVariables).forEach((key) => {
      path = path.replace(`:${key}`, String(this.pathVariables[key]));
    });
    const authParams = {
      [csrfConfig.param]: csrfConfig.token,
    };
    return [this.basepath, path].join('/') + '?' + QueryString.stringify({ ...this.params, ...authParams });
  }

  async fetch(config: any): Promise<any> {
    const defaultOptions = {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await GlobalStatesV3.jwt.jwt.getOrElse('')}`,
      },
    };
    const response = await fetch(this._renderUrl(), { ...defaultOptions, ...config });
    return parseResponse(await response.json());
  }

  get<T>(params = {}): Promise<T> {
    return this.addParams(params).fetch({ method: 'GET' });
  }

  post(payload?: Payload): Promise<any> {
    return this.fetch({ method: 'POST', body: payload ? JSON.stringify(payload) : null });
  }

  put(payload?: Payload): Promise<any> {
    return this.fetch({ method: 'PUT', body: payload ? JSON.stringify(payload) : null });
  }

  delete(payload?: Payload): Promise<any> {
    return this.fetch({ method: 'DELETE', body: payload ? JSON.stringify(payload) : null });
  }

  deleteForm(data: FormData): Promise<any> {
    return this.fetch({
      method: 'DELETE',
      body: data,
      headers: {
        Authorization: `Bearer ${GlobalStatesV3.jwt.jwt.getOrElse('')}`,
      },
    });
  }

  putForm(data: FormData): Promise<any> {
    return this.fetch({
      method: 'PUT',
      body: data,
      headers: {
        Authorization: `Bearer ${GlobalStatesV3.jwt.jwt.getOrElse('')}`,
      },
    });
  }

  postForm(data: FormData): Promise<any> {
    return this.fetch({
      method: 'POST',
      body: data,
      headers: {
        Authorization: `Bearer ${GlobalStatesV3.jwt.jwt.getOrElse('')}`,
      },
    });
  }

  async postWithAxios(data: FormData, onUploadProgress: (event: ProgressEvent) => any): Promise<any> {
    const response = await axios.post(this._renderUrl(), data, {
      onUploadProgress,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await GlobalStatesV3.jwt.jwt.getOrElse('')}`,
      },
    });
    return parseResponse(response.data as any);
  }

  private removeHeadSlash(path: string) {
    return path.replace(/^\/*/, '');
  }
}

export const initCsrfConfigV3 = () => {
  const param = (document.querySelector('meta[name=csrf-param]') as any).content;
  const token = (document.querySelector('meta[name=csrf-token]') as any).content;
  csrfConfig.param = param;
  csrfConfig.token = token;
};
