import * as React from 'react';
import Diners from './images/dinners-club.png';
import JCB from './images/jcb.png';
import Visa from './images/visa.png';
import AmericanExpress from './images/american-express.png';
import MasterCard from './images/mastercard.png';

type BrandProps = {
  brand: string;
  styleName?: string;
  className?: string;
};

export const BrandIcon = ({ brand, className }: BrandProps) => {
  if (brand.toLowerCase() == 'visa') {
    return <img className={className} src={Visa} />;
  }
  if (brand.toLowerCase().startsWith('american')) {
    return <img className={className} src={AmericanExpress} />;
  }
  if (brand.toLowerCase().startsWith('diners')) {
    return <img className={className} src={Diners} />;
  }
  if (brand.toLowerCase().startsWith('jcb')) {
    return <img className={className} src={JCB} />;
  }
  if (brand.toLowerCase().startsWith('master')) {
    return <img className={className} src={MasterCard} />;
  }
  return <span>{brand}</span>;
};
