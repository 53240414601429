import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

/**
 * 基本のページを構成するコンポーネント。Pageと組み合わせて使う。
 */
export const PageBody = ({ children }: Props) => {
  return <div>{children}</div>;
};
