import { action, computed, observable, makeObservable } from 'mobx';
import { RemoteResourceV3, updateRemoteResourceV3 } from '../api/RemoteResourceV3';
import { WithdrawalApis } from '../../mainv3/apis/withdrawals';
import { WithdrawalId } from '../types/withdrawals';
import { Withdrawal } from '../../mainv3/types/withdrawal';
import { MembershipPlanGroupKey } from '../../mainv3/types/membershipPlans';

export class WithdrawalState {
  withdrawalBase = RemoteResourceV3.loading();

  constructor() {
    makeObservable(this, {
      withdrawalBase: observable,
      init: action.bound,
      reload: action.bound,
      cancel: action.bound,
      withdrawals: computed,
      isWithdrawalSet: computed,
      withdrawal: computed,
      withdrawalId: computed,
      withdrawalEffectiveAt: computed,
      loading: computed,
    });
  }

  async init() {
    await this.reload();
  }

  async reload() {
    await updateRemoteResourceV3(
      this.withdrawalBase,
      (v) => (this.withdrawalBase = v),
      () => WithdrawalApis.findAll()
    );
  }

  async cancel(withdrawalId: WithdrawalId) {
    await WithdrawalApis.cancel(withdrawalId);
  }

  get withdrawals(): Withdrawal[] {
    return this.withdrawalBase.getOrElse([]);
  }

  get isWithdrawalSet() {
    return this.withdrawals.some((s) => s.membership_plan_group_key == MembershipPlanGroupKey.LIKES);
  }

  get withdrawal() {
    return this.withdrawals.find((w) => w.membership_plan_group_key == MembershipPlanGroupKey.LIKES);
  }

  get withdrawalId() {
    if (!this.isWithdrawalSet) {
      return null;
    }
    const withdrawal = this.withdrawal;
    if (!withdrawal) {
      return null;
    }
    return withdrawal.id;
  }

  get withdrawalEffectiveAt() {
    if (!this.isWithdrawalSet) {
      return null;
    }
    const withdrawal = this.withdrawal;
    if (!withdrawal) {
      return null;
    }
    return withdrawal.effective_at;
  }

  get loading() {
    return this.withdrawalBase.loading;
  }
}
