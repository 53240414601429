import tokens from '@sheinc/design-tokens';
import { observable, action, computed, makeObservable } from 'mobx';

/* 
  ブレイクポイント図

      544px <- |    768px <- |      1012px <- |      1280px <- | 
      @small   |     @medium |         @large |        @xlarge |

 */

const BreakPoint = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
  XLARGE: 'XLARGE',
} as const;

export type BreakPoint = typeof BreakPoint[keyof typeof BreakPoint];

const MatchWith = (breakpoint: string) => {
  return window.matchMedia(`(max-width: ${breakpoint})`).matches;
};

const getCurrentViewSize = () => {
  if (MatchWith(tokens.breakpoint.small.value)) {
    return BreakPoint.SMALL;
  } else if (MatchWith(tokens.breakpoint.medium.value)) {
    return BreakPoint.MEDIUM;
  } else if (MatchWith(tokens.breakpoint.large.value)) {
    return BreakPoint.LARGE;
  } else if (MatchWith(tokens.breakpoint.xlarge.value)) {
    return BreakPoint.XLARGE;
  }
};

/**
 * @deprecated 削除予定なので利用は非推奨
 */
export type ViewDeviceV3 = 'SP' | 'TABLET' | 'DESKTOP';

/**
 * @deprecated 削除予定なので利用は非推奨
 */
const calculateViewDevice = (): ViewDeviceV3 => {
  if (window.matchMedia('(min-width: 1200px)').matches) {
    return 'DESKTOP';
  } else if (window.matchMedia('(min-width: 465px)').matches) {
    return 'TABLET';
  } else {
    return 'SP';
  }
};

export class ViewportStateV3 {
  viewDevice: ViewDeviceV3 = calculateViewDevice();
  viewSize = getCurrentViewSize();

  constructor() {
    makeObservable(this, {
      viewDevice: observable,
      viewSize: observable,
      isMobile: computed,
      isDesktop: computed,
      isNonSp: computed,
      isSp: computed,
      isSmall: computed,
      isMedium: computed,
      isLarge: computed,
      isXLarge: computed,
      isUnderLarge: computed,
      isUnderMedium: computed,
      update: action.bound,
    });
  }

  /**
   * @description 544px以下の時に true を返す
   */
  get isSmall() {
    return this.viewSize === BreakPoint.SMALL;
  }

  /**
   * @description 545px以上、768px以下の時に true を返す
   */
  get isMedium() {
    return this.viewSize === BreakPoint.MEDIUM;
  }

  /**
   * @description 769px以上、1012px以下の時に true を返す
   */
  get isLarge() {
    return this.viewSize === BreakPoint.LARGE;
  }

  /**
   * @description 1013px以上、1280px以下の時に true を返す
   */
  get isXLarge() {
    return this.viewSize === BreakPoint.XLARGE;
  }

  /**
   * @description 1280px以下の時に true を返す
   */
  get isUnderXLarge() {
    return (
      this.viewSize === BreakPoint.SMALL ||
      this.viewSize === BreakPoint.MEDIUM ||
      this.viewSize === BreakPoint.LARGE ||
      this.viewSize === BreakPoint.XLARGE
    );
  }

  /**
   * @description 1012px以下の時に true を返す
   */
  get isUnderLarge() {
    return (
      this.viewSize === BreakPoint.SMALL || this.viewSize === BreakPoint.MEDIUM || this.viewSize === BreakPoint.LARGE
    );
  }

  /**
   * @description 768px以下の時に true を返す
   */
  get isUnderMedium() {
    return this.viewSize === BreakPoint.SMALL || this.viewSize === BreakPoint.MEDIUM;
  }

  /**
   * @description 544px以下の時に true を返す
   */
  get isUnderSmall() {
    return this.viewSize === BreakPoint.SMALL;
  }

  update() {
    this.viewDevice = calculateViewDevice();
    this.viewSize = getCurrentViewSize();
  }

  /**
   * @deprecated 削除予定なので利用は非推奨
   */
  get isMobile() {
    return this.viewDevice === 'SP' || this.viewDevice === 'TABLET';
  }

  /**
   * @deprecated 削除予定なので利用は非推奨
   */
  get isDesktop() {
    return this.viewDevice === 'DESKTOP';
  }

  /**
   * @deprecated 削除予定なので利用は非推奨
   */
  get isNonSp() {
    return this.viewDevice !== 'SP';
  }

  /**
   * @deprecated 削除予定なので利用は非推奨
   */
  get isSp() {
    return this.viewDevice === 'SP';
  }
}
