import { CurrentUserState } from '../../../../../mainv2/states/CurrentUserState';
import { action, observable, makeObservable, computed } from 'mobx';
import { CardSummary } from '../../../../../mainv2/types/card';
import { UserCardApis } from '../../../../apis/users';

export class CreditCardState {
  userState: CurrentUserState;
  editMode: boolean;
  newToken?: string | null;
  card?: CardSummary;
  cardElement: unknown;
  cardInputErrorMessage = {
    number: '',
    expiry: '',
  };
  cardInputComplete = {
    number: false,
    expiry: false,
    cvc: false,
  };

  constructor(userState: CurrentUserState) {
    makeObservable(this, {
      editMode: observable,
      newToken: observable,
      card: observable,
      setEditMode: action.bound,
      cardElement: observable,
      setCardElement: action.bound,
      cardInputErrorMessage: observable,
      setCardInputErrorMessage: action.bound,
      cardInputComplete: observable,
      setCardInputComplete: action.bound,
      cardInputCompleteAll: computed,
      validateNewCard: action.bound,
    });

    this.userState = userState;
    this.editMode = false;
  }

  setCardElement(cardElement: unknown) {
    this.cardElement = cardElement;
  }

  setCardInputErrorMessage(cardInputErrorMessage: { number?: string; expiry?: string }) {
    this.cardInputErrorMessage = { ...this.cardInputErrorMessage, ...cardInputErrorMessage };
  }

  setCardInputComplete(complete: { number?: boolean; expiry?: boolean; cvc?: boolean }) {
    this.cardInputComplete = { ...this.cardInputComplete, ...complete };
  }

  get cardInputCompleteAll() {
    return Object.values(this.cardInputComplete).every((v) => v);
  }

  async init() {
    if (this.userState.hasCard()) {
      this.editMode = false;
      this.card = await UserCardApis.getCurrentCard();
      if (!this.card) {
        this.editMode = true;
      }
    } else {
      this.editMode = true;
    }
  }

  async validateNewCard() {
    try {
      const response = await UserCardApis.createToken(this.cardElement);
      this.newToken = response.id;
      this.card = response.card;
      return true;
    } catch (error) {
      // 決済ができないというお問合せ調査のためログを仕込む
      // eslint-disable-next-line no-console
      console.error(error);
      switch (error.code) {
        case 'invalid_expiry_year':
        case 'invalid_expiry_month':
          this.cardInputErrorMessage = { ...this.cardInputErrorMessage, number: '正しく入力してください' };
          break;
        case 'card_declined':
          this.cardInputErrorMessage = {
            ...this.cardInputErrorMessage,
            number: 'カード会社に支払いを拒否されました。限度額をご確認の上、カード会社にお問い合わせください。',
          };
          break;
        case 'incorrect_card_data':
        default:
          this.cardInputErrorMessage = { ...this.cardInputErrorMessage, number: 'カード情報が正しくありません' };
      }
      this.newToken = null;
      return false;
    }
  }

  async update() {
    if (!this.newToken) {
      throw new Error('New token must be set before updating card');
    }
    await UserCardApis.updateCard(this.newToken);
  }

  setEditMode(editMode: boolean) {
    this.editMode = editMode;
  }
}
