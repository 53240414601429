import { SurveyKey } from '../../commonv3/types/surveys';
import { MainV3ApiBase } from './base';
import { SurveySubmissionIndexView } from '../types/survey_submissions';
import moment from 'moment-timezone';

export const SurveySubmissionApis = {
  create(
    surveyKey: SurveyKey,
    parameters: Record<string, unknown>,
    answers: Record<string, unknown>
  ): Promise<unknown> {
    return MainV3ApiBase.appendPath('surveys').withId(surveyKey).appendPath('survey_submissions').post({
      answers,
      parameters,
    });
  },
};

export const UserSurveySubmissionApis = {
  index(surveyKey: SurveyKey, parameters: object): Promise<SurveySubmissionIndexView[]> {
    return MainV3ApiBase.appendPath('users/current/user_survey_submissions/search').post({
      survey_id: surveyKey,
      parameters,
    });
  },
  /**
   * Returns true if there's a user's submission for the given user with the specified parameters
   * @param surveyKey the key of survey
   * @param parameters [optional] the parameters
   * @param durationMonth [optional] if specified only searches submissions within the last specified month
   * @throws NotFoundError when the specified survey key is not found
   */
  async hasSurveySubmissionWithin(
    surveyKey: string,
    parameters: object = {},
    durationMonth: number | undefined = undefined
  ): Promise<boolean> {
    const submissions = await UserSurveySubmissionApis.index(surveyKey, parameters);
    return submissions.some((submission) => {
      if (durationMonth) {
        return moment(submission.created_at).add(durationMonth, 'month').isAfter(moment());
      }
      return true;
    });
  },
};

export const exposeSurveyFunctions = () => {
  window['hasSurveySubmissionWithin'] = UserSurveySubmissionApis.hasSurveySubmissionWithin;
};
