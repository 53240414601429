import * as React from 'react';
import classNames from 'classnames';
import './likesLogo.module.scss';
import { LikesLogoSvg } from './LikesLogoSvg';

type LogoProps = {
  id?: string;
  className?: string;
  onClick?: () => void;
};

export const Logo: React.FC<LogoProps> = ({ id, className, onClick }) => (
  <LikesLogoSvg id={id} className={className} onClick={onClick} />
);

type HeaderLogoProps = {
  color?: string;
};

export const HeaderLogo: React.FC<HeaderLogoProps> = ({ color }) => (
  <Logo styleName={classNames('header-logo', color)} />
);

type Props = {
  className?: string;
  color?: 'white' | 'dark';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: () => any;
};

export const LikesLogo = ({ className, color = 'dark', onClick }: Props) => {
  return (
    <div styleName={classNames('logo', color)} className={className} onClick={onClick}>
      <HeaderLogo color={color} />
    </div>
  );
};
