import React from 'react';
import moment from 'moment-timezone';
import './subscriptionSummaryListView.module.scss';
import { LoaderRow } from '../../../atoms/layout/LoaderRow';
import { MembershipItemId } from '../../../../../commonv3/types/membershipPlans';
import { SubscriptionSummary } from '../../../../types/subscriptionSummary';
import { SubscriptionSummaryView } from './SubscriptionSummaryView';

function findNextSummary(summary: SubscriptionSummary, summaries: SubscriptionSummary[]): SubscriptionSummary {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return summaries.find((s) => s.parent_chain_id == summary.chain_id)!;
}

function isSuspendedOrNotExpired(summary: SubscriptionSummary) {
  return summary.suspended || !moment(summary.expires_at).isSameOrBefore(moment().startOf('day'));
}

function getKey(summary: SubscriptionSummary) {
  return `${summary.plan_name}-${summary.starts_at}`;
}

// 引数で与えられた SubscriptionSummary の配列から、休止中もしくは有効であり、かつ、
// ルートではないSubscriptionSummaryを維持し、そのキーを応答する
const getChangingPlanSubscriptionSummaries = (summaries: SubscriptionSummary[]) =>
  summaries
    .filter((s) => isSuspendedOrNotExpired(s)) // 休止中もしくは有効であるSubscriptionSumaryのみを維持する
    .map((s) => findNextSummary(s, summaries)) // 子供のSubscriptionSummaryを取得する
    .filter((s) => !!s) // 直前の処理で取得できなかった場合は除外する
    .map(getKey); // キー名に変換する

// 引数で与えられた SubscriptionSummary の配列から、休止中もしくは有効であり、かつ、
// ルートであるSubscriptionSummaryを維持し応答する
const filterVisibleSubscriptionSummaries = (summaries: SubscriptionSummary[]) => {
  const changingPlanSummaries = getChangingPlanSubscriptionSummaries(summaries);
  return summaries.filter((s) => {
    const key = getKey(s);

    if (changingPlanSummaries.indexOf(key) >= 0) {
      return false; // 休止中もしくは有効であるが、ルートではないSubscriptionSummaryは除外する
    }

    // 休止中もしくは有効であるSubscriptionSummaryのみを維持する
    return isSuspendedOrNotExpired(s);
  });
};

type Props = {
  summaries: SubscriptionSummary[];
  loading?: boolean;
  isWithdrawalSet: boolean;
  onUpdateRequest?: (itemId: MembershipItemId) => void;
  onUpdateCancelRequest?: (originalItemId: MembershipItemId, nextItemId: MembershipItemId) => void;
  onCancelSuspensionRequest?: (subscriptionCode: string, subscriptionSuspensionCode: string) => Promise<void>;
  onResumeSubscriptionRequest?: (subscriptionCode: string) => void;
};

export const SubscriptionSummaryListView = ({ summaries, loading = false, isWithdrawalSet }: Props) => {
  if (loading) {
    return <LoaderRow />;
  }

  const isSuspendedOrNotExpiredSubscriptionSummaries = filterVisibleSubscriptionSummaries(summaries);

  return (
    <div id="subscription-summary-list" styleName="subscription-summary-list">
      {isSuspendedOrNotExpiredSubscriptionSummaries.map((summary) => {
        return (
          <SubscriptionSummaryView
            key={`${summary.plan_name}${summary.starts_at}`}
            summary={summary}
            isWithdrawalSet={isWithdrawalSet}
          />
        );
      })}
    </div>
  );
};
