import { FieldError } from 'react-hook-form';

/**
 * shelikes-design-system のフォームコンポーネントの error フィールドに渡す用にエラー内容を1つの文字列に変換する関数
 * （error フィールドは現状 string しか受け付けないため）
 */
export const joinErrorMessages = (error: FieldError | undefined): string | undefined => {
  const message = error?.types && Object.values(error?.types).join('。');
  return message;
};
