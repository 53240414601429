import React from 'react';
import './reservationCard.module.scss';
import { Button } from '../../../../commonv3/components/atoms/buttons/Button';
import moment from 'moment-timezone';
import { EventReservationIndexView } from '../../../types/eventReservations';
import { SessionIndexView, SessionType } from '../../../types/sessions';
import { expandImageParams } from '../../../../commonv2/utils/image';
import { DetailedDateTime } from '../../../../mainv3/components/atoms/dates/DetailedDateTime';
import mokumokuThumbnailImg from './images/thumbnail_mokumoku.png';
import coachingThumbnailImg from './images/thumbnail_coaching.png';
import trialLessonThumbnailImg from './images/thumbnail_trial_lesson.jpg';
import thankyouImg from './images/thankyou.png';
import { getLocationName } from '../../../types/location';
import { ReservationIndexView } from '../../../types/reservations';
import classNames from 'classnames';

type Props = {
  reservation: ReservationIndexView | EventReservationIndexView;
  index: number;
  showButton?: boolean;
  onRequestUpdate: (reservation: ReservationIndexView | EventReservationIndexView) => void;
};

function getThumbnailSrcSessionType(reservable: SessionIndexView) {
  switch (reservable.session_type) {
    case SessionType.GROUP_COACHING: {
      return <img styleName="thumbnail" src={coachingThumbnailImg} />;
    }
    case SessionType.MOKUMOKU: {
      return <img styleName="thumbnail" src={mokumokuThumbnailImg} />;
    }
  }
}

function getThumbnailSrc(reservation: ReservationIndexView | EventReservationIndexView) {
  const reservable = reservation.reservable;
  switch (reservation.reservable_type) {
    case 'TrialLesson':
      return <img styleName="thumbnail" src={trialLessonThumbnailImg} />;
    case 'Event':
      // @ts-expect-error ts2339
      return <img styleName="thumbnail" {...expandImageParams(reservable.thumbnail_photo_url)} />;
    case 'Session':
      // @ts-expect-error ts2345
      return getThumbnailSrcSessionType(reservable);
  }
}

function isAttended(reservation: ReservationIndexView | EventReservationIndexView) {
  switch (reservation.reservable_type) {
    case 'TrialLesson':
    case 'Event':
      return reservation.status == 'attended';
    case 'Session':
      return reservation.attendance == 'attended';
  }
}

export const ReservationCard = ({ reservation, showButton = true, onRequestUpdate }: Props) => {
  const reservable = reservation.reservable;
  const price = reservation.price && reservation.price > 0 ? `${reservation.price.toLocaleString()}円` : '無料';
  // @ts-expect-error ts2339
  const coaches = reservable.coaches;
  return (
    <div styleName="card_wrapper" key={reservable.title}>
      <div styleName="card">
        <div styleName="thumbnail-wrapper">{getThumbnailSrc(reservation)}</div>
        <div styleName="main">
          <div styleName="title">{reservable.title}</div>
          <div styleName="contents">
            {coaches && coaches.length !== 0 && (
              <div styleName="content-box">
                <div styleName="label">講師</div>
                <div styleName="body">
                  {coaches[0].name} {coaches.length > 1 && <span>他{coaches.length - 1}名</span>}
                </div>
              </div>
            )}
            <div styleName="content-box">
              <div styleName="label">日時</div>
              <div styleName="body">
                <DetailedDateTime date={reservable.starts_at} />
                &nbsp;-&nbsp; {moment(reservable.ends_at).format('HH:mm')}
              </div>
            </div>
            <div styleName="content-box">
              <div styleName="label">場所</div>
              <div styleName="body">{getLocationName(reservable.location)}</div>
            </div>
            <div styleName="content-box">
              <div styleName="label">料金</div>
              <div styleName="body">{price}</div>
            </div>
            {isAttended(reservation) && (
              <img styleName={classNames('thankyou', { show_button: showButton })} src={thankyouImg} />
            )}
          </div>
          {showButton && (
            <Button
              type="primary"
              size="l"
              disabled={isAttended(reservation)}
              onClick={() => onRequestUpdate(reservation)}
            >
              {isAttended(reservation) ? '出席済み' : '出席済みにする'}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
