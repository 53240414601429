import {
  DateQuestion,
  DegreeAnswer,
  DegreeQuestion,
  MultiLineFaQuestion,
  MultiSelectQuestion,
  SatisfactionQuestion,
  SingleLineFaQuestion,
  SingleSelectQuestion,
  SurveyQuestion,
  TopKSelectQuestion,
  SingleSelectPullDownQuestion,
} from '../../../../../commonv3/types/survey_questions';
import { action, computed, observable, makeObservable, override } from 'mobx';
import { ISODate } from '../../../../../commonv3/types/dates';
import { checkState } from '../../../../../mainv2/utils/preconditions';
import { isPresent } from '../../../../../commonv3/utils/presence';
import autobind from 'autobind-decorator';
import { range } from 'lodash';

class BaseQuestionState<Q extends SurveyQuestion, V> {
  question: Q;
  required: boolean;
  value: V | null;
  errorMessage!: string | null;

  constructor(question: Q, required: boolean, defaultValue: V | null = null) {
    makeObservable(this, {
      question: observable,
      required: observable,
      value: observable,
      errorMessage: observable,
      setValue: action.bound,
      validate: action.bound,
      doValidate: action.bound,
      hasErrors: computed,
    });

    this.question = question;
    this.required = required;
    this.value = defaultValue;
  }

  setValue(value: V | null) {
    this.value = value;
    this.validate();
  }

  validate() {
    this.errorMessage = null;
    this.errorMessage = this.doValidate();
  }

  doValidate(): string | null {
    return null;
  }

  get hasErrors() {
    return this.errorMessage != null;
  }
}

export class SatisfactionQuestionState extends BaseQuestionState<SatisfactionQuestion, string> {
  constructor(question: SatisfactionQuestion, required: boolean) {
    super(question, required, null);

    makeObservable(this, {
      doValidate: override,
    });
  }

  doValidate(): string | null {
    if (!this.required) {
      return null;
    }
    return this.value ? null : '選択してください';
  }
}

export class DegreeQuestionState extends BaseQuestionState<DegreeQuestion, DegreeAnswer> {
  constructor(question: DegreeQuestion, required: boolean) {
    super(question, required, null);

    makeObservable(this, {
      doValidate: override,
    });
  }
  doValidate(): string | null {
    if (!this.required) {
      return null;
    }
    return this.value ? null : '選択してください';
  }
}

export class DateQuestionState extends BaseQuestionState<DateQuestion, ISODate> {
  constructor(question: DateQuestion, required: boolean) {
    super(question, required, null);

    makeObservable(this, {
      doValidate: override,
    });
  }
  doValidate(): string | null {
    if (!this.required) {
      return null;
    }
    return this.value ? null : '選択してください';
  }
}

export class SingleLineFreeAnswerQuestionState extends BaseQuestionState<SingleLineFaQuestion, string> {
  constructor(question: SingleLineFaQuestion, required: boolean) {
    super(question, required, '');

    makeObservable(this, {
      doValidate: override,
    });
  }
  doValidate(): string | null {
    if (!this.required) {
      return null;
    }
    return this.value ? null : '入力してください';
  }
}

export class MultiLineFreeAnswerQuestionState extends BaseQuestionState<MultiLineFaQuestion, string> {
  constructor(question: MultiLineFaQuestion, required: boolean) {
    super(question, required, '');

    makeObservable(this, {
      doValidate: override,
    });
  }
  doValidate(): string | null {
    if (!this.required) {
      return null;
    }
    return this.value ? null : '入力してください';
  }
}

export class SingleSelectQuestionState extends BaseQuestionState<SingleSelectQuestion, string> {
  normalValue = '';
  otherValue = '';
  otherValueChecked = false;
  constructor(question: SingleSelectQuestion, required: boolean) {
    super(question, required, null);

    makeObservable(this, {
      normalValue: observable,
      otherValue: observable,
      otherValueChecked: observable,
      setNormalValue: action.bound,
      setOtherValue: action.bound,
      setOtherValueChecked: action.bound,
      syncValue: action.bound,
      doValidate: override,
    });
  }
  setNormalValue(nextValue: string) {
    this.normalValue = nextValue;
    this.otherValue = '';
    this.otherValueChecked = false;
    this.syncValue();
  }
  setOtherValue(nextValue: string) {
    this.normalValue = '';
    this.otherValue = nextValue;
    this.otherValueChecked = true;
    this.syncValue();
  }
  setOtherValueChecked(nextValue: boolean) {
    this.otherValueChecked = nextValue;
    if (nextValue) {
      this.normalValue = '';
    }
    this.syncValue();
  }
  syncValue() {
    this.setValue(this.otherValue || this.normalValue);
  }

  doValidate(): string | null {
    if (!this.required) {
      return null;
    }
    return this.value ? null : '選択してください';
  }
}

export class MultiSelectQuestionState extends BaseQuestionState<MultiSelectQuestion, string[]> {
  normalValues: string[] = [];
  otherValueChecked = false;
  otherValue = '';
  constructor(question: MultiSelectQuestion, required: boolean) {
    super(question, required, []);

    makeObservable(this, {
      normalValues: observable,
      otherValueChecked: observable,
      otherValue: observable,
      handleNormalOptionCheck: action.bound,
      setOtherValue: action.bound,
      setOtherValueChecked: action.bound,
      syncValues: action.bound,
      doValidate: override,
    });
  }

  handleNormalOptionCheck(option: string, checked: boolean) {
    this.normalValues = checked
      ? [...new Set(this.normalValues.concat([option]))]
      : [...new Set(this.normalValues.filter((v) => v !== option))];
    this.syncValues();
  }

  setOtherValue(v: string) {
    this.otherValue = v;
    if (!this.otherValue || this.otherValue === '') {
      this.otherValueChecked = false;
    }
    this.syncValues();
  }

  setOtherValueChecked(checked: boolean) {
    this.otherValueChecked = checked;
    this.syncValues();
  }

  syncValues() {
    this.setValue([
      ...new Set(this.normalValues.concat(this.otherValueChecked ? [this.otherValue] : []).filter((v) => !!v)),
    ]);
  }
  doValidate(): string | null {
    if (!this.required) {
      return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.value!.length > 0 ? null : '少なくとも1つ選択してください';
  }
}

export class TopKSelectQuestionState extends BaseQuestionState<TopKSelectQuestion, (string | null)[]> {
  selection = observable.array<string | null>([]);
  constructor(question: TopKSelectQuestion, required: boolean, defaultValue = null) {
    super(question, required, defaultValue);

    makeObservable(this, {
      selection: observable,
      setSelection: action.bound,
      setValue: override,
      isAllSet: computed,
      doValidate: override,
    });

    const selectionCount = question.content.selection_count || 0;
    this.selection.replace(new Array(selectionCount).map(() => null));
  }

  setSelection(index: number, value: string | null) {
    checkState(index >= 0 && index < this.selection.length);
    this.selection[index] = value;
    range(index + 1, this.selection.length).forEach((i) => {
      if (this.selection[i] && this.getOptions(i).every((opt) => opt.value !== this.selection[i])) {
        this.selection[i] = null;
      }
    });
    let nextValue;
    if (this.isAllSet) {
      this.setValue(this.selection);
      nextValue = this.selection;
    } else {
      nextValue = null;
    }
    if (this.value != nextValue) {
      this.setValue(nextValue);
    }
  }

  setValue(value: (string | null)[] | null) {
    super.setValue(value);
    if (isPresent(value)) {
      value.forEach((v, index) => {
        if (index < this.selection.length) {
          this.selection[index] = v;
        }
      });
    }
  }

  get isAllSet() {
    return this.selection.every(isPresent);
  }

  @autobind
  getOptions(index: number) {
    return this.question.content.options
      .filter((option) => {
        return !this.selection.slice(0, index).some((s) => s == option);
      })
      .map((option) => {
        return { value: option, label: option };
      });
  }

  doValidate(): string | null {
    if (!this.required) {
      return null;
    }
    return this.isAllSet ? null : '入力してください';
  }
}

export class SingleSelectPullDownQuestionState extends BaseQuestionState<SingleSelectPullDownQuestion, string> {
  normalValue = '';
  constructor(question: SingleSelectPullDownQuestion, required: boolean) {
    super(question, required, null);

    makeObservable(this, {
      normalValue: observable,
      setNormalValue: action.bound,
      syncValue: action.bound,
      doValidate: override,
    });
  }
  setNormalValue(nextValue: string) {
    this.normalValue = nextValue;
    this.syncValue();
  }
  syncValue() {
    this.setValue(this.normalValue);
  }
  doValidate(): string | null {
    if (!this.required) {
      return null;
    }
    return this.value ? null : '選択してください';
  }
}
