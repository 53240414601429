import { ISODateTime } from '../../admin/types/date';
import moment from 'moment-timezone';
import { OnlineLocation, SheLocation } from '../../mainv3/types/location';
import { SessionParameter, SessionType } from '../../mainv3/types/sessions';
import { TokenType } from './tokens';
import { MembershipPlanGroupKey } from '../../mainv3/types/membershipPlans';
export type ReservableId = number;

export enum ReservableType {
  EVENT = 'Event',
  TRIAL_LESSON = 'TrialLesson',
  SESSION = 'Session',
}

export type CoachPosition = {
  coach_position_spec_id: number;
  created_at: string;
  id: number;
  session_id: number;
  updated_at: string;
  user_id: number;
  user_name: string;
};

export interface Reservable {
  id: ReservableId;
  title: string;
  starts_at: ISODateTime;
  ends_at: ISODateTime;
  capacity: number;
  active_reservation_count: number;
  location?: SheLocation | OnlineLocation;
  params?: SessionParameter[];
  session_type?: SessionType;
  required_token_type?: TokenType;
  membership_plan_group_keys: MembershipPlanGroupKey | MembershipPlanGroupKey[];
  zoom_url?: string | null;
  video_provider: string;
  lesson1?: string;
  lesson2?: string;
  coach_positions?: CoachPosition[];
}

export type Group<T> = {
  date: ISODateTime;
  entries: T[];
};

export function groupByDate<T>(entries: T[], func: (v: T) => ISODateTime): Group<T>[] {
  const sortedEntries = entries.sort((x, y) => func(x).localeCompare(func(y)));
  return sortedEntries.reduce<Group<T>[]>((result, entry) => {
    const entryDate = moment(func(entry)).format('YYYY-MM-DD');
    if (result.length === 0) {
      return [
        {
          date: entryDate,
          entries: [entry],
        },
      ];
    }
    const lastGroup = result[result.length - 1];
    if (lastGroup.date === entryDate) {
      lastGroup.entries = lastGroup.entries.concat([entry]);
      return result;
    } else {
      return result.concat([
        {
          date: entryDate,
          entries: [entry],
        },
      ]);
    }
  }, []);
}
