import { BodyText } from '@sheinc/shelikes-design-system';
import * as React from 'react';
import './currentCardPlaceholder.module.scss';
import { CardSummaryContainer } from './CurrentCardSummary';

type Props = {
  onRequestCreate: () => void;
};

export const CurrentCardPlaceholder: React.FC<Props> = ({ onRequestCreate }) => {
  return (
    <CardSummaryContainer className="current-card-placeholder">
      <div styleName="placeholder">
        <BodyText>まだお支払い情報が登録されていません。</BodyText>
      </div>
      <div styleName="button" onClick={onRequestCreate} className="create-button">
        登録する
      </div>
    </CardSummaryContainer>
  );
};
