import * as React from 'react';
import { OverlayContent } from './OverlayPage';
import { Loader } from '../../../../mainv3/components/atoms/forms/loader';
import './overlaySpinner.module.scss';

type Props = {
  open: boolean;
  onRequestClose?: () => void;
  id?: string;
};

export const OverlaySpinner: React.FC<Props> = ({ open, onRequestClose = () => null, id }) => (
  <OverlayContent
    open={open}
    onRequestClose={onRequestClose}
    childElem={(st, setRef) => (
      <div id={id} styleName="loader-wrapper" ref={setRef}>
        <Loader styleName="loader" />
      </div>
    )}
  />
);
