import { ISODate } from './dates';

export type SurveyQuestionId = number;

export enum SurveyQuestionType {
  SATISFACTION = 'satisfaction',
  DEGREE = 'degree',
  SINGLE_SELECT = 'single_select',
  MULTI_SELECT = 'multi_select',
  SINGLE_LINE_FA = 'single_line_fa',
  MULTI_LINE_FA = 'multi_line_fa',
  DATE = 'date',
  TOP_K_SELECT = 'top_k_select',
  SINGLE_SELECT_PULL_DOWN = 'single_select_pull_down',
}

export type BaseSurveyQuestion<T, K extends SurveyQuestionType> = {
  id: SurveyQuestionId;
  key: string;
  sentence: string;
  sub_sentence?: string;
  youtube_key?: string;
  question_type: K;
  content: T;
  reusable: boolean;
  personal_information: boolean;
};

export type SatisfactionContent = {
  extra_options: string[];
};

export type SingleSelectContent = {
  size: 'm' | 's';
  options: string[];
  allow_other?: boolean;
};

export type MultiSelectContent = {
  size: 'm' | 's';
  options: string[];
  allow_other: boolean;
};

export type TopKSelectContent = {
  options: string[];
  selection_count: number;
  selection_text: string;
};

export type DateQuestionContent = {
  max: ISODate;
  min: ISODate;
};

export type SingleSelectPullDownContent = {
  options: string[];
  allow_other?: boolean;
};

export type SatisfactionQuestion = BaseSurveyQuestion<SatisfactionContent, SurveyQuestionType.SATISFACTION>;
export type DegreeQuestion = BaseSurveyQuestion<Record<string, unknown>, SurveyQuestionType.DEGREE>;
export type SingleSelectQuestion = BaseSurveyQuestion<SingleSelectContent, SurveyQuestionType.SINGLE_SELECT>;
export type MultiSelectQuestion = BaseSurveyQuestion<MultiSelectContent, SurveyQuestionType.MULTI_SELECT>;
export type TopKSelectQuestion = BaseSurveyQuestion<TopKSelectContent, SurveyQuestionType.TOP_K_SELECT>;
export type SingleLineFaQuestion = BaseSurveyQuestion<Record<string, unknown>, SurveyQuestionType.SINGLE_LINE_FA>;
export type MultiLineFaQuestion = BaseSurveyQuestion<Record<string, unknown>, SurveyQuestionType.MULTI_LINE_FA>;
export type DateQuestion = BaseSurveyQuestion<DateQuestionContent, SurveyQuestionType.DATE>;
export type SingleSelectPullDownQuestion = BaseSurveyQuestion<
  SingleSelectPullDownContent,
  SurveyQuestionType.SINGLE_SELECT_PULL_DOWN
>;

export type SurveyQuestion =
  | SatisfactionQuestion
  | DegreeQuestion
  | SingleSelectQuestion
  | MultiSelectQuestion
  | TopKSelectQuestion
  | SingleLineFaQuestion
  | MultiLineFaQuestion
  | DateQuestion
  | SingleSelectPullDownQuestion;

export type DegreeAnswer = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
