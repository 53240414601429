import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
export class ModalSpec {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onActivate: () => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDeactivate: () => any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(onActivate: () => any, onDeactivate: () => any) {
    this.onActivate = onActivate;
    this.onDeactivate = onDeactivate;
  }
}

export class ModalState {
  stack: ModalSpec[] = [];

  push(spec) {
    if (this.stack.length > 0) {
      this.stack[this.stack.length - 1].onDeactivate();
    } else {
      disableBodyScroll();
    }
    this.stack.push(spec);
    spec.onActivate();
  }

  pop() {
    if (this.stack.length < 1) return;
    const spec = this.stack.pop();
    spec.onDeactivate();
    if (this.stack.length > 0) {
      this.stack[this.stack.length - 1].onActivate();
    } else {
      enableBodyScroll();
    }
  }
}
