import React from 'react';
import { MultiSelectQuestionState } from './states/QuestionState';
import { QuestionCard } from '../../molecules/surveys/QuestionCard';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import './multiSelectQuestionCard.module.scss';
import { CheckBoxCard, FreeAnswerCheckBoxCard } from '../../atoms/select/CheckBoxCard';
import { SurveyQuestionFormat } from '../../../../commonv3/types/surveys';

type Props = {
  questionNumber: number;
  totalQuestions: number;
  questionState: MultiSelectQuestionState;
  format?: SurveyQuestionFormat;
};

export const MultiSelectQuestionCard = observer(({ questionNumber, totalQuestions, questionState, format }: Props) => {
  const {
    question,
    value,
    handleNormalOptionCheck,
    required,
    otherValue,
    otherValueChecked,
    setOtherValue,
    setOtherValueChecked,
    validate,
    errorMessage,
  } = questionState;
  const values = value || [];

  return (
    <QuestionCard
      questionNumber={questionNumber}
      totalQuestions={totalQuestions}
      sentence={question.sentence}
      subSentence={question.sub_sentence}
      youtubeKey={question.youtube_key}
      required={required}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      errorMessage={errorMessage!}
      onBlur={validate}
      format={format}
    >
      <div styleName={classNames('container', question.content.size)}>
        {question.content.options.map((opt, index) => (
          <CheckBoxCard
            id={`option-${question.id}-${index}`}
            key={opt}
            styleName={classNames('option', question.content.size)}
            checked={values.includes(opt)}
            label={opt}
            onCheck={(checked) => handleNormalOptionCheck(opt, checked)}
          />
        ))}
        {question.content.allow_other && (
          <FreeAnswerCheckBoxCard
            styleName={classNames('option', question.content.size)}
            checked={otherValueChecked}
            label="その他"
            onCheck={setOtherValueChecked}
            value={otherValue}
            onChange={setOtherValue}
          />
        )}
      </div>
    </QuestionCard>
  );
});
