import { ApiV2 } from '../../commonv2/api/ApiV2';
import { locatePath } from '../../common/RelativeUrl';
import { EventDetails, EventId } from '../types/event';

export const EventApis = {
  base() {
    const baseHost = process.env.REST_API_BASE_URL || `${location.protocol}//${location.host}`;
    return new ApiV2(baseHost + locatePath('/api/v2/events'));
  },
  /**
   * Returns the specified event
   * @returns {Promise<CourseDetail>}
   */
  async find(id: EventId): Promise<EventDetails> {
    return await this.base().withId(id).get();
  },
  /**
   * Find all events held in future
   */
  async findAll(): Promise<EventDetails[]> {
    return await this.base().get();
  },
};
