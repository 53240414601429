import { action, computed, observable, makeObservable } from 'mobx';
import { RemoteResourceV3, updateRemoteResourceV3 } from '../../../../commonv3/api/RemoteResourceV3';
import { EventReservationIndexView } from '../../../types/eventReservations';
import { EventReservationApis } from '../../../apis/eventReservations';
import { EventReservationId } from '../../../../commonv3/types/eventReservations';

export class EventReservationAttendanceCompletePageState {
  eventReservation: RemoteResourceV3<EventReservationIndexView> = RemoteResourceV3.loading();

  constructor() {
    makeObservable(this, {
      eventReservation: observable,
      init: action.bound,
      loading: computed,
    });
  }

  async init(eventReservationId: EventReservationId) {
    await updateRemoteResourceV3(
      this.eventReservation,
      (v) => (this.eventReservation = v),
      () => EventReservationApis.find(eventReservationId)
    );
  }

  get loading(): boolean {
    return this.eventReservation.loading;
  }
}
