import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './button.module.scss';
import { Loader } from '../../../../mainv3/components/atoms/forms/loader';
import { LocationDescriptorObject } from 'history';
import { Link } from 'react-router-dom';
import { EnhancedLocationDescriptor } from '../../../../mainv3/routes/index';

type Props = {
  id?: string;
  type: ButtonType;
  size?: 'xs' | 's' | 'ms' | 'm' | 'l' | 'custom';
  loading?: boolean;
  disabled?: boolean;
  href?: string | EnhancedLocationDescriptor;
  target?: string;
  to?: LocationDescriptorObject | 'string';
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  styleName?: string;
  className?: string;
  'data-value'?: string;
  fullWidth?: boolean;
  testid?: string;
  children: ReactNode;
};

export type ButtonType =
  | 'primary-inverted'
  | 'primary'
  | 'normal'
  | 'normal-inverted'
  | 'notify'
  | 'success'
  | 'link'
  | 'underline'
  | 'multicreator'
  | 'link-inverted'
  | 'off';

export const Button = ({
  id = undefined,
  type,
  size = 'm',
  to,
  href,
  target,
  loading = false,
  disabled = false,
  onClick,
  onMouseOver,
  onMouseOut,
  children,
  className,
  'data-value': dataAttribute,
  fullWidth = false,
  testid,
}: Props) => {
  const formattedClassName = classNames(className, 'button', { disabled: disabled, loading: loading });
  if (href) {
    return (
      <a
        id={id}
        styleName={classNames('button', type, size, { disabled: disabled, fullWidth: fullWidth })}
        href={href}
        target={target}
        onClick={!loading && !disabled ? onClick : undefined}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        className={formattedClassName}
        data-testid={testid}
      >
        <div styleName="button-inner" data-value={dataAttribute}>
          {loading ? <Loader styleName="loader" className="loading" /> : children}
        </div>
      </a>
    );
  }
  if (to) {
    return (
      <Link
        id={id}
        styleName={classNames('button', type, size, { disabled: disabled, fullWidth: fullWidth })}
        to={to}
        target={target}
        onClick={!loading && !disabled ? onClick : undefined}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        className={formattedClassName}
        data-testid={testid}
      >
        <div styleName="button-inner" data-value={dataAttribute}>
          {loading ? <Loader styleName="loader" className="loading" /> : children}
        </div>
      </Link>
    );
  }
  return (
    <div
      id={id}
      styleName={classNames('button', type, size, { disabled: disabled, fullWidth: fullWidth })}
      onClick={!loading && !disabled ? onClick : undefined}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      className={formattedClassName}
      data-testid={testid}
    >
      <div styleName="button-inner" data-value={dataAttribute}>
        {loading ? <Loader styleName="loader" className="loading" /> : children}
      </div>
    </div>
  );
};
