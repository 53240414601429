import { action, computed, observable, makeObservable } from 'mobx';
import { RemoteResourceV3, updateRemoteResourceV3 } from '../api/RemoteResourceV3';
import { HomeLocationApis } from '../../mainv3/apis/homeLocations';
import { LocationId } from '../types/locations';

export class HomeLocationState {
  homeLocation = RemoteResourceV3.loading();

  constructor() {
    makeObservable(this, {
      homeLocation: observable,
      init: action.bound,
      reload: action.bound,
      update: action.bound,
      isHomeLocationSet: computed,
      isCurrentLocationOnline: computed,
      locationId: computed,
      loading: computed,
    });
  }

  async init() {
    await this.reload();
  }

  async reload() {
    await updateRemoteResourceV3(
      this.homeLocation,
      (v) => (this.homeLocation = v),
      () => HomeLocationApis.show()
    );
  }

  async update(locationId: LocationId) {
    await HomeLocationApis.update(locationId);
  }

  get isHomeLocationSet() {
    return this.homeLocation.getOrElse(null) != null;
  }

  get isCurrentLocationOnline() {
    if (!this.isHomeLocationSet) {
      return false;
    }
    return this.locationId == null;
  }

  get locationId() {
    if (!this.isHomeLocationSet) {
      return null;
    }
    const location = this.homeLocation.get().location;
    if (!location) {
      return null;
    }
    return location.id;
  }

  get loading() {
    return this.homeLocation.loading;
  }
}
