import * as React from 'react';
import classNames from 'classnames';
import type { DegreeAnswer } from '../../../../commonv3/types/survey_questions';
import './degreeSelectInput.module.scss';

const Circle = ({
  value,
  checked,
  onCheck,
}: {
  value: DegreeAnswer;
  checked: boolean;
  onCheck: (checked: boolean) => void;
}) => (
  <div styleName={classNames('circle', { checked: checked })} onClick={() => onCheck(!checked)}>
    {value}
  </div>
);

type Props = {
  value: DegreeAnswer;
  onChange: (value: DegreeAnswer) => void;
};

export const DegreeSelectInput: React.FC<Props> = ({ value, onChange }) => (
  <div styleName="degree-select-input">
    {([1, 2, 3, 4, 5, 6, 7, 8, 9, 10] as const).map((v) => (
      <Circle key={v} value={v} checked={value === v} onCheck={() => onChange(v)} />
    ))}
  </div>
);
