import * as React from 'react';
import './signInNavigation.module.scss';
import { observer } from 'mobx-react-lite';
import SheLogoImg from '../../../containers/navigation/she.png';

export const SignInNavigation = observer(() => (
  <div styleName="nav">
    <div styleName="logo-wrapper">
      <img src={SheLogoImg} styleName="logo" id="she-logo" />
    </div>
  </div>
));
