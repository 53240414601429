import React from 'react';
import { MaterialFontIcons } from '../../../../commonv3/components/atoms/icons/icons';
import './selectBoxCard.module.scss';

export type SelectBoxOptions = {
  label: string;
  value: string;
};

type Props = {
  id?: string;
  placeholder?: string;
  options: SelectBoxOptions[];
  defaultValue?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (nextValue: any) => any;
};

export const SelectBoxCard = ({ id, placeholder, options, defaultValue, onChange }: Props) => {
  return (
    <div id={id} styleName="select-wrap">
      <select
        styleName="select"
        value={defaultValue}
        onChange={(e) => (e.target.value !== placeholder ? onChange(e.target.value) : onChange(''))}
      >
        {/* @ts-expect-error ts2322 */}
        {placeholder && <option value={null}>{placeholder}</option>}
        {options.map((opt) => {
          return (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          );
        })}
      </select>
      <MaterialFontIcons.ArrowDropDown styleName="icon" />
    </div>
  );
};
