import { Api as _Api } from '../../common/api/Api';
import { locatePath } from '../../common/RelativeUrl';
export { initCsrfConfig } from '../../common/api/Api';
const baseHost = process.env.REST_API_BASE_URL || `${location.protocol}//${location.host}`;
const Base = new _Api(baseHost + locatePath('/api/v1'), '');
export const CurrentUser = Base.appendPath('/users/current');
export const Lessons = Base.appendPath('/lessons');
export const Coaches = Base.appendPath('/coaches');
export const Faqs = Base.appendPath('/faqs_with_categories');
export const PastLessons = Lessons.addParams({
  past: true,
});
export const RecommendedLessons = Base.appendPath('/recommended_lessons');
export const LessonReserve = Lessons.appendPath(':lessonId/reserve');
export const Memberships = Base.appendPath('/memberships');
export const MembershipCurrent = Memberships.appendPath('current');
export const MembershipTickets = Memberships.appendPath('current/tickets');
export const MembershipCurrentCard = Memberships.appendPath('current/card');
export const Reservations = Base.appendPath('/reservations');
export const Reservation = Reservations.appendPath(':reservationId');
export const SignIn = Base.appendPath('/users/sign_in');
export const SignUp = Base.appendPath('/users/new');
export const Api = {
  CurrentUser,
  Lessons,
  Coaches,
  Faqs,
  PastLessons,
  RecommendedLessons,
  LessonReserve,
  SignIn,
  SignUp,
  Memberships,
  MembershipCurrent,
  MembershipTickets,
  MembershipCurrentCard,
  Reservations,
  Reservation,
};
