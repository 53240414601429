import React from 'react';
import classNames from 'classnames';
import './menu.module.scss';
import { observer } from 'mobx-react';
import { EnhancedLocationDescriptor, FAQ_URL, MoneyRoutes, V3Routes } from '../../../mainv3/routes';
import { routingStore } from '../../../common/stores/routing';
import { AccountRoutes } from '../../routes/AccountRoutes';
import { GlobalStatesV3 } from '../../../commonv3/states/GlobalStatesV3';
import { Logo } from '../../../commonv2/components/navigation/LikesLogo';
import { MoneyLogoSvg } from './MoneyLogoSvg';
import { Link } from 'react-router-dom';
import { usePlanListState } from '../../states/PlanListState';
import { HiddenLoaderRow } from '../../../mainv3/components/atoms/layout/LoaderRow';

type BaseMenuItemProps = {
  id?: string;
  activePattern: string;
  children: React.ReactNode;
};

type MenuItemProps =
  | (BaseMenuItemProps & {
      to: EnhancedLocationDescriptor;
    })
  | (BaseMenuItemProps & {
      href: string;
      target?: string;
    });

const MenuItem = observer((props: MenuItemProps) => {
  const { activePattern, children, id } = props;
  const active = routingStore.location.pathname.includes(activePattern);
  if ('href' in props) {
    const { href, target } = props;
    return (
      <a styleName={classNames('menu-item', { active: active })} href={href} target={target} id={id}>
        <span styleName="menu-item-text">{children}</span>
      </a>
    );
  }
  return (
    <div
      styleName={classNames('menu-item', { active: active })}
      onClick={() => 'to' in props && routingStore.push(props.to)}
      id={id}
    >
      <span styleName="menu-item-text">{children}</span>
    </div>
  );
});

const ServiceItems = observer(() => {
  const planList = usePlanListState();
  return (
    <>
      <div styleName="service-items-header-wrapper">
        <div styleName="service-items-header">
          <div styleName="service-items-title">OUR SERVICE</div>
          <div styleName="service-items-sub-title">サービス</div>
        </div>
      </div>
      <div styleName="likes-logo-wrapper">
        <a
          id="likes-logo"
          href={planList.isActiveLikesMember ? V3Routes.likesHome() : V3Routes.likesUrl('/trial')}
          target="_self"
        >
          <Logo styleName="likes-logo-svg" />
        </a>
      </div>
      <div styleName="money-logo-wrapper">
        <Link
          id="money-logo"
          to={planList.isActiveMoneyMember ? MoneyRoutes.memberHome() : MoneyRoutes.home()}
          target="_self"
        >
          <MoneyLogoSvg styleName="money-logo-svg" />
        </Link>
        <span styleName="label-new">NEW</span>
      </div>
      {planList.loading && <HiddenLoaderRow />}
    </>
  );
});

export const Menu = observer(() => {
  return (
    <div styleName="menu" id="menu">
      {GlobalStatesV3.viewport.isNonSp && (
        <div styleName="header-wrapper">
          <div styleName="header">
            <div styleName="title">MYPAGE</div>
            <div styleName="sub-title">マイページ</div>
          </div>
        </div>
      )}
      <MenuItem id="menu-item-profile" activePattern={'profile'} to={AccountRoutes.profile()}>
        プロフィールを編集
      </MenuItem>
      <MenuItem id="menu-item-reservations" activePattern={'reservations'} to={AccountRoutes.reservations()}>
        予約の確認
      </MenuItem>
      <MenuItem id="menu-item-plans" activePattern={'plans'} to={AccountRoutes.plan()}>
        プランの確認 / 変更
      </MenuItem>
      <MenuItem id="menu-item-plans" activePattern={'address'} to={AccountRoutes.address()}>
        本人情報の確認 / 変更
      </MenuItem>
      <MenuItem id="menu-item-payments" activePattern={'payments'} to={AccountRoutes.payments()}>
        お支払い情報 / 変更
      </MenuItem>
      <MenuItem id="menu-item-email" activePattern={'email'} to={AccountRoutes.email()}>
        メールの設定
      </MenuItem>
      <MenuItem id="menu-item-faq" activePattern={'faq'} href={FAQ_URL} target="_blank">
        よくあるご質問
      </MenuItem>
      <MenuItem id="menu-item-sign-out" activePattern={'sign_out'} to={AccountRoutes.signOut()}>
        ログアウト
      </MenuItem>
      <ServiceItems />
    </div>
  );
});
