import React, { ErrorInfo } from 'react';
import { history, routingStore } from './common/stores/routing';
import { observer, Provider } from 'mobx-react';
import { initTime } from './commonv3/utils/time';
import { SheRavenClient } from './commonv2/modules/ravenClient';
import { exposeSurveyFunctions } from './mainv3/apis/surveySubmissions';
import { exposeFbq } from './adminv3/api/facebook';
import { observable, reaction, makeObservable, configure } from 'mobx';
import { scrollToTop } from './mainv2/utils/scroll';
import { FacebookProvider } from 'react-facebook';
import { GlobalStatesV3 } from './commonv3/states/GlobalStatesV3';
import { Navigation } from './account/containers/navigation/Navigation';
import { Menu } from './account/containers/navigation/Menu';
import { Redirect, Route, Router, Switch } from 'react-router';
import { AccountRoutes } from './account/routes/AccountRoutes';
import { SignOutPage } from './account/containers/sign_out/SignOutPage';
import { ReservationListPage } from './account/containers/reservation/ReservationListPage';
import { ProfileChangePage } from './account/containers/profile/ProfileChangePage';
import { EmailChangePage } from './account/containers/email/EmailChangePage';
import { SignInPage } from './account/containers/sign_in/SignInPage';
import { ForgotPasswordPane } from './account/components/organisms/passwords/ForgotPasswordPane';
import { ResetPasswordPane } from './account/components/organisms/passwords/ResetPasswordPane';
import { ResetCodePane } from './account/components/organisms/passwords/ResetCodePane';
import { ResetPasswordPageState } from './account/containers/passwords/states/ResetPasswordPageState';
import { PlanListPage } from './account/containers/plans/PlanListPage';
import { PaymentListPage } from './account/containers/payments/PaymentListPage';
import { PaymentInfoPage } from './account/containers/payments/PaymentInfoPage';
import { SheEnv } from './commonv3/utils/envs';
import { AddressChangePane } from './account/containers/address/AddressChangePane';
import { DatadogRumClient } from './commonv3/modules/rumClient';
import { AttendancePage } from './account/containers/attendance/AttendancePage';
import { ReservationAttendanceCompletePage } from './account/containers/attendance/ReservationAttendanceCompletePage';
import { EventReservationAttendanceCompletePage } from './account/containers/attendance/EventReservationAttendanceCompletePage';
import { PaymentRetryPane } from './account/components/organisms/payments/PaymentRetryPane';

declare let FACEBOOK_APP_ID: string;

configure({ enforceActions: 'never', useProxies: 'never' });

const AuthorizedRoutes = () => {
  // ログイン会員専用のルーティング
  return (
    <div>
      <Navigation />
      <div className="wrapper">
        {GlobalStatesV3.viewport.isNonSp && <Menu />}
        <div className="main">
          <Switch>
            <Route exact path={AccountRoutes.signOut().pathname} component={SignOutPage} />
            <Route exact path={AccountRoutes.plan().pathname} component={PlanListPage} />
            <Route exact path={AccountRoutes.profile().pathname} component={ProfileChangePage} />
            <Route exact path={AccountRoutes.reservations().pathname} component={ReservationListPage} />
            <Route exact path={AccountRoutes.payments().pathname} component={PaymentListPage} />
            <Route exact path={AccountRoutes.paymentInfo().pathname} component={PaymentInfoPage} />
            <Route exact path={AccountRoutes.email().pathname} component={EmailChangePage} />
            <Route exact path={AccountRoutes.address().pathname} component={AddressChangePane} />
            <Route exact path={AccountRoutes.attendance().pathname} component={AttendancePage} />
            <Route exact path={AccountRoutes.retryPayment(':code').pathname} component={PaymentRetryPane} />
            <Route
              exact
              path={AccountRoutes.reservationAttendanceCompleteRoute().pathname}
              component={ReservationAttendanceCompletePage}
            />
            <Route
              exact
              path={AccountRoutes.eventReservationAttendanceCompleteRoute().pathname}
              component={EventReservationAttendanceCompletePage}
            />
            <Redirect to={AccountRoutes.profile()} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export const ApplicationAccount = observer(
  class // eslint-disable-next-line @typescript-eslint/no-explicit-any
  App extends React.Component<any> {
    pageState = new ResetPasswordPageState(GlobalStatesV3.routing);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private unsubscriber: () => any = null;

    constructor(props: any) {
      super(props);

      makeObservable(this, {
        pageState: observable,
      });
    }

    async componentDidMount() {
      await this.initializeDependencies();
    }

    async initializeDependencies() {
      initTime();
      exposeSurveyFunctions();
      this.unsubscriber = reaction(
        () => routingStore.location.pathname,
        () => {
          scrollToTop();
        }
      );
      if (SheEnv.isStaging() || SheEnv.isProduction()) {
        exposeFbq();
        SheRavenClient.init();
        DatadogRumClient.init();
      }
    }

    componentWillUnmount() {
      this.unsubscriber();
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
      SheRavenClient.captureException(error, { extra: errorInfo });
    }

    render() {
      return (
        <FacebookProvider appId={FACEBOOK_APP_ID} language="ja_JP">
          <Provider pageState={this.pageState}>
            <Router history={history}>
              <div>
                <Switch>
                  <Route exact path={AccountRoutes.signIn().stringify()} component={SignInPage} />
                  <Route exact path={AccountRoutes.resetPassword().stringify()} component={ResetPasswordPane} />
                  <Route exact path={AccountRoutes.forgotPassword().stringify()} component={ForgotPasswordPane} />
                  <Route exact path={AccountRoutes.validateResetCode().stringify()} component={ResetCodePane} />
                  {GlobalStatesV3.currentUser.isSignedIn() && <AuthorizedRoutes />}
                  <Redirect to={AccountRoutes.signIn(location.href).stringify()} />
                </Switch>
              </div>
            </Router>
          </Provider>
        </FacebookProvider>
      );
    }
  }
);
