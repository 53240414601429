import { observable, action, computed, makeObservable } from 'mobx';
import { calculateViewDevice } from '../../main/view/utils';

export type ViewDevice = 'MOBILE' | 'DESKTOP';

export class ViewportState {
  viewDevice: ViewDevice = 'DESKTOP';

  constructor() {
    makeObservable(this, {
      viewDevice: observable,
      isMobile: computed,
      isDesktop: computed,
      update: action.bound,
    });
  }

  get isMobile() {
    return this.viewDevice === 'MOBILE';
  }

  get isDesktop() {
    return this.viewDevice === 'DESKTOP';
  }

  update() {
    this.viewDevice = calculateViewDevice();
  }
}
