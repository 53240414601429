import * as queryString from 'querystring';
import { LocationId } from '../../commonv3/types/locations';
import { LocationKey, LocationType } from '../../adminv3/types/locations';

export type SheLocation = {
  id: LocationId;
  key: LocationKey | string;
  name: string;
  short_name: string;
  location_type: LocationType;
  zipcode: string;
  address: string;
  access_summary?: string;
  short_access_summary?: string;
  access_description?: string;
  google_map_url?: string;
  image?: string;
};

export type OnlineLocation = null;

export function getLocationName(location?: { name: string } | null) {
  if (!location) {
    return 'オンライン';
  } else {
    return location.name;
  }
}

export type RouteLocation = {
  pathname: string;
  search: string;
  hash: string;
};

export function parseQueryParams(location: RouteLocation): queryString.ParsedUrlQuery {
  const { search } = location;
  if (!search) {
    return {};
  }
  // queryStringの前の"?"をsliceで取り除く
  return queryString.parse(search.slice(1));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function serializeQueryParams(params: Record<string, any>): string {
  if (Object.keys(params).length === 0) {
    return '';
  }
  return '?' + queryString.stringify(params);
}
