import React from 'react';
import './primary.module.scss';

export const Primary = ({ children, className = undefined }: { children: React.ReactNode; className?: string }) => {
  return (
    <div styleName="primary" className={className}>
      <div styleName="primary-body">{children}</div>
    </div>
  );
};
