import React, { useEffect, useState } from 'react';
import { FieldStateV3, FormContext, FormStateV3 } from '../../../../commonv3/states/form/FormStateV3';
import { Button } from '../../../../commonv3/components/atoms/buttons/Button';
import { StringFieldInput } from '../../../../mainv3/components/atoms/forms/vertical/StringField';
import './signInPane.module.scss';
import { Api } from '../../../../main/api';
import { FormError, FormErrors } from '../../../../mainv3/components/atoms/forms/vertical/base';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { OverlaySpinner } from '../../../../commonv3/components/molecules/layout/OverlaySpinner';
import { Page, Pane } from '../../../../mainv3/components/atoms/applications/page';
import { GlobalStatesV3 } from '../../../../commonv3/states/GlobalStatesV3';
import { AccountRoutes } from '../../../routes/AccountRoutes';
import { SignInNavigation } from '../navigation/SignInNavigation';
import { ServiceApplicationCards } from './ServiceApplicationCards';

type SignInInput = {
  email: string;
  password: string;
};

type SignInFormSchema = {
  email: FieldStateV3<string>;
  password: FieldStateV3<string>;
};

class FormState extends FormStateV3<SignInInput, SignInFormSchema> {
  constructor() {
    super({
      email: new FieldStateV3<string>(),
      password: new FieldStateV3<string>(),
    });
  }
  init() {
    this._init('signin', new FormContext(), {
      email: '',
      password: '',
    });
  }
}

type Props = {
  /**
   * The url where the user is redirected after successful sign in
   */
  redirectUrl?: string | string[];
  /**
   * True if 'create new user' option is available on this pane
   */
  supportNewUser: boolean;
};

export const SignInPane = observer(({ redirectUrl, supportNewUser }: Props) => {
  const { formState } = useLocalObservable(() => ({ formState: new FormState() }));
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    formState.init();
  }, []);

  const signIn = async () => {
    await formState.submitAsJson(async (params) => {
      setError(null);
      const response = await Api.SignIn.post(params);
      if (!response) {
        setError('ログインに失敗しました');
      } else if (!response.error) {
        if (redirectUrl) {
          location.href = redirectUrl as string;
        } else {
          location.href = '/auth';
        }
      } else {
        setError(response.error);
      }
    });
  };

  const { submitting } = formState;
  return (
    <>
      {GlobalStatesV3.viewport.isNonSp && <SignInNavigation />}
      <Page>
        <Pane>
          <div styleName="header">ログイン</div>
          <div styleName="body">
            <div styleName="form">
              <StringFieldInput
                label="メールアドレス"
                placeholder="she@she-inc.co.jp"
                type="text"
                state={formState.fields.email}
              />
              <StringFieldInput
                label="パスワード"
                type="password"
                placeholder="●●●●●●●●●"
                state={formState.fields.password}
              />
              {error && (
                <FormErrors>
                  <FormError>{error}</FormError>
                </FormErrors>
              )}
              <div id="sign-in-button" styleName="button-wrapper">
                <Button
                  id="signin-button"
                  styleName="button"
                  type="primary"
                  size="l"
                  onClick={signIn}
                  disabled={submitting}
                >
                  ログイン
                </Button>
              </div>
              <Button
                type="link"
                size="l"
                styleName="link"
                id="forgot-password-button"
                to={AccountRoutes.forgotPassword()}
              >
                パスワードを忘れた方はこちら
              </Button>
            </div>
            {supportNewUser && (
              <>
                <hr />
                <ServiceApplicationCards submitting={submitting} />
              </>
            )}
            <OverlaySpinner id="sign-in-loader" open={submitting} />
          </div>
        </Pane>
      </Page>
    </>
  );
});
