import React, { ReactNode } from 'react';
import moment from 'moment-timezone';
import { Reservable, ReservableType } from '../../../../commonv3/types/reservable';
import classNames from 'classnames';
import './scheduleListItem.module.scss';
import { CoachingSessionTypeList, SessionParameter, SessionParameterKey, SessionType } from '../../../types/sessions';
import { serviceName } from '../../../../commonv3/types/service';
import { BodyText } from '@sheinc/shelikes-design-system';
import { GlobalStatesV3 } from '../../../../commonv3/states/GlobalStatesV3';
import { MembershipPlanGroupKey } from '../../../types/membershipPlans';
import { OnlineLocation, SheLocation } from '../../../types/location';

type BadgeProps = {
  color: 'pink';
  children: React.ReactNode;
};
export const ScheduleBadge = ({ color, children }: BadgeProps) => (
  <span styleName={classNames('schedule-badge', color)}>{children}</span>
);

type Props = {
  id?: string;
  title?: string;
  reservable: Reservable;
  actionItem?: ReactNode;
  masked?: boolean;
  maskMessage?: ReactNode;
  titleBadge?: ReactNode;
  showServiceLabel?: boolean;
  showZoomLink?: boolean;
  reservableType?: ReservableType;
};

export function parseSessionLabels(params: SessionParameter[] | null, key_type: SessionParameterKey): string[] {
  if (!params) {
    return [];
  }
  const sessionParam = params.find((p) => p.key === key_type);
  if (sessionParam && sessionParam.value) {
    return sessionParam.value.split(/,\s*/).filter((s) => !!s);
  }
  return [];
}

export function parseServiceName(services: MembershipPlanGroupKey[] | MembershipPlanGroupKey) {
  if (Array.isArray(services)) {
    return services.map((service: MembershipPlanGroupKey) => {
      if (service in serviceName) {
        return (
          <div key={service} styleName={`service-label ${service}-label`}>
            {serviceName[service]}
          </div>
        );
      }
    });
  }
  if (services in serviceName) {
    return <div styleName={`service-label ${services}-label`}>{serviceName[services]}</div>;
  }
}

export const ScheduleListItem = ({
  id,
  title,
  reservable,
  actionItem,
  masked = false,
  maskMessage = '',
  titleBadge = undefined,
  showServiceLabel = false,
  showZoomLink = false,
  reservableType,
}: Props) => {
  const {
    starts_at,
    ends_at,
    capacity,
    active_reservation_count,
    location,
    params,
    coach_positions,
    session_type,
    membership_plan_group_keys,
    zoom_url,
    video_provider,
    lesson1,
    lesson2,
  } = reservable;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const labels = parseSessionLabels(params!, SessionParameterKey.LABELS);
  const now_time = Date.now();
  const isMoneyTrialLesson =
    membership_plan_group_keys?.includes(MembershipPlanGroupKey.MONEY) &&
    reservableType === ReservableType.TRIAL_LESSON;
  const moneyTrialLessonTitle = lesson2 ? `${lesson1} / ${lesson2}` : lesson1;

  const coachPositionsLabel = (sessionType: SessionType | undefined) => {
    if (!sessionType) return 'TA';
    if (CoachingSessionTypeList.includes(sessionType)) {
      return 'コーチ';
    }
    if (sessionType === SessionType.STUDY_GROUP_MEETING) {
      return 'ナビゲーター';
    }
    return 'TA';
  };

  const locationLabel = (location?: SheLocation | OnlineLocation) => {
    if (location) return location.short_name || location.name;
    if (zoom_url) return video_provider;
    return 'オンライン';
  };

  return (
    <div id={id || `schedule-list-item-${reservable.id}`} styleName="schedule-list-item">
      {title && (
        <div styleName="title">
          <BodyText size={GlobalStatesV3.viewport.isMobile ? 'L' : 'M'}>
            {isMoneyTrialLesson ? moneyTrialLessonTitle : title}
          </BodyText>
          {titleBadge && <span styleName="badge-wrapper">{titleBadge}</span>}
        </div>
      )}
      {labels.length !== 0 && (
        <div styleName="label">
          {labels.map((label, index) => (
            <div key={index} styleName="label-item">
              <ScheduleBadge color="pink">{label}</ScheduleBadge>
            </div>
          ))}
        </div>
      )}

      <div styleName="main">
        <div styleName="header">
          {showServiceLabel && membership_plan_group_keys && membership_plan_group_keys.length !== 0 && (
            <BodyText>サービス: {parseServiceName(membership_plan_group_keys)}</BodyText>
          )}
          <BodyText>
            時間: {moment(starts_at).format('HH:mm')}
            &nbsp;-&nbsp;
            {moment(ends_at).format('HH:mm')}
          </BodyText>
          <div styleName="location-capacity">
            <BodyText>場所: {locationLabel(location)}</BodyText>
            <BodyText>
              申込者: {active_reservation_count}/{capacity}人
            </BodyText>
          </div>
          {coach_positions && session_type !== SessionType.COURSE_PLANNING_MEETING && (
            <div data-testid="coach-positions">
              <BodyText>
                {coachPositionsLabel(session_type)}:&nbsp;
                {coach_positions.length > 0 ? coach_positions.map((c) => c.user_name).join('、') : '未定'}
              </BodyText>
            </div>
          )}
          {showZoomLink && zoom_url && (
            <div styleName="zoom-link">
              <BodyText>
                {video_provider}:&nbsp;
                {moment(now_time) > moment(starts_at).add(5, 'm') ? (
                  <p styleName="expired-link">
                    遅れてのご参加は開始時刻から5分までとなっております。
                    <br />
                    恐れ入りますが再予約をお願いします。
                  </p>
                ) : (
                  <a styleName="join-link" href={zoom_url} target="_blank" rel="noopener noreferrer">
                    こちらからご参加ください
                  </a>
                )}
              </BodyText>
            </div>
          )}
        </div>
        <div styleName="action">{actionItem}</div>
      </div>
      {masked && (
        <div id={`mask-${reservable.id}`} styleName="mask">
          {maskMessage}
        </div>
      )}
    </div>
  );
};
