/* eslint-disable */

import queryString from 'query-string';
// RELATIVE_URL_ROOT is injected on application.html.erb
declare var RELATIVE_URL_ROOT;
export const locatePath = (relative: string, queryParams: Record<string, any> | null | undefined = null) => {
  const search = queryParams == null ? '' : '?' + queryString.stringify(queryParams);
  let path = relative.startsWith('/')
    ? `${RELATIVE_URL_ROOT}${relative}${search}`
    : `${RELATIVE_URL_ROOT}/${relative}${search}`;
  return path.replace('//', '/');
};
