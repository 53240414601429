import { UserHomeLocationIndexView } from '../types/home_locations';
import { MainV3ApiBase } from './base';
import { LocationId } from '../../commonv3/types/locations';

export const HomeLocationApis = {
  show(): Promise<UserHomeLocationIndexView | null> {
    return MainV3ApiBase.appendPath('users/current/user_home_location').get();
  },
  update(locationId: LocationId) {
    return MainV3ApiBase.appendPath('users/current/user_home_location').put({ location_id: locationId });
  },
};
