import { ApiError } from '../../../src/commonv3/api/protocol';
import { FormError } from '../../../src/commonv3/states/form/FormStateV3';
import { FieldValues, Path, UseFormReturn } from 'react-hook-form';

export const useFormErrors = <T extends FieldValues>(form: UseFormReturn<T>) => {
  const setFormErrors = (e: unknown) => {
    form.clearErrors();

    if (e instanceof ApiError && e.isValidationError()) {
      const errors: FormError<T> = e.body;
      Object.keys(errors).forEach((key: keyof T) => {
        const fieldErrors = errors[key] as string[];
        const types = fieldErrors.reduce((prev, fieldError, index) => {
          prev[`${index}`] = fieldError;
          return prev;
        }, {} as Record<string, string>);

        form.setError(key as Path<T>, { types });
      });
    }

    throw e;
  };

  const hasErrors = () => {
    return Object.keys(form.formState.errors).length > 0;
  };

  return { setFormErrors, hasErrors };
};
