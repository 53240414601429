import { Service } from './service';

type ObjectValueType<T> = T[keyof T];

export type SurveyId = number;
export type SurveyKey = string;

export const SurveyKeysTrialLesson = {
  [Service.LIKES]: 'trial_lesson',
  [Service.BEAUTY]: 'beauty_trial_lesson',
  [Service.MONEY]: 'money_trial_lesson',
} as const;

export const SurveyKeysSpecialEvent = {
  [Service.LIKES]: 'special_event',
  [Service.BEAUTY]: 'beauty_special_event',
  [Service.MONEY]: 'money_special_event',
} as const;

export const SurveyKeysGroupCoaching = {
  [Service.LIKES]: 'group_coaching_2022',
  [Service.BEAUTY]: 'beauty_group_coaching',
  [Service.MONEY]: 'money_group_coaching',
} as const;

export const SurveyKeysMokumoku = {
  [Service.LIKES]: 'mokumoku',
  [Service.BEAUTY]: 'beauty_mokumoku',
  [Service.MONEY]: 'money_mokumoku',
} as const;

export const SurveyKeysTrialLessonReservationCancel = {
  [Service.LIKES]: 'trial_lesson_reservation_cancel',
  [Service.BEAUTY]: 'beauty_trial_lesson_reservation_cancel',
  [Service.MONEY]: 'TBD',
  [Service.MULTICREATOR]: 'mcctrial_lesson_reservation_cancel',
} as const;

export const SurveyKeysPlane = {
  CAREER_CHANGE: 'career_change',
  CREATORS_SURVEY: 'creators_survey',
  WELCOME_WORKSHOP: 'welcome_workshop',
  GROUP_COACHING_INITIAL: 'group_coaching_initial',
  ADJOURNMENT: 'likes_adjournment',
  WITHDRAWAL: 'likes_withdrawal',
} as const;

type SurveyKeysPlaneValues = ObjectValueType<typeof SurveyKeysPlane>;

export enum ByServiceSurveyKeys {
  TRIAL_LESSON,
  SPECIAL_EVENT,
  GROUP_COACHING,
  MOKUMOKU,
  TRIAL_LESSON_RESERVATION_CANCEL,
}

export type SurveyKeys =
  | ObjectValueType<typeof SurveyKeysTrialLesson>
  | ObjectValueType<typeof SurveyKeysSpecialEvent>
  | ObjectValueType<typeof SurveyKeysGroupCoaching>
  | ObjectValueType<typeof SurveyKeysMokumoku>
  | ObjectValueType<typeof SurveyKeysTrialLessonReservationCancel>
  | SurveyKeysPlaneValues;

function isByServiceSurveyKeys(likesKey: unknown): likesKey is ByServiceSurveyKeys {
  return Object.values(ByServiceSurveyKeys).includes(likesKey as ByServiceSurveyKeys);
}

export function getSurveyKeyForService(likesKey: SurveyKeysPlaneValues): SurveyKeys;
export function getSurveyKeyForService(likesKey: ByServiceSurveyKeys, service: Service): SurveyKeys;
export function getSurveyKeyForService(
  likesKey: SurveyKeysPlaneValues | ByServiceSurveyKeys,
  service?: Service
): SurveyKeys {
  if (isByServiceSurveyKeys(likesKey)) {
    if (!service) throw new Error('should define service to get this survey key');
    switch (likesKey) {
      case ByServiceSurveyKeys.TRIAL_LESSON:
        return SurveyKeysTrialLesson[service];
      case ByServiceSurveyKeys.SPECIAL_EVENT:
        return SurveyKeysSpecialEvent[service];
      case ByServiceSurveyKeys.GROUP_COACHING:
        return SurveyKeysGroupCoaching[service];
      case ByServiceSurveyKeys.MOKUMOKU:
        return SurveyKeysMokumoku[service];
      case ByServiceSurveyKeys.TRIAL_LESSON_RESERVATION_CANCEL:
        return SurveyKeysTrialLessonReservationCancel[service];
    }
  } else {
    return likesKey;
  }
}

export type SurveyQuestionFormat = 'card' | 'one_by_one';
