import React from 'react';
import './questionError.module.scss';

export const QuestionError = ({
  className = undefined,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) => (
  <div styleName="question-error" className={className}>
    {children}
  </div>
);
