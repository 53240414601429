import { Location, LocationDescriptorObject } from 'history';
import { CourseId } from '../types/courses';
import { SectionId } from '../types/sections';
import * as queryString from 'querystring';
import { EventId } from '../../commonv3/types/events';
import { LessonId } from '../types/lessons';
import { SessionId } from '../../commonv3/types/sessions';
import { SessionTemplateKey, SessionType } from '../types/sessions';
import {
  ExamPracticalSectionId,
  ExamPracticalSectionSubmissionToken,
  ExamWritingSectionId,
} from '../../commonv3/types/courseExams';
import { TrialLessonId } from '../../commonv3/types/trialLessons';
import { MembershipItemId } from '../../commonv3/types/membershipPlans';
import { ApplicationPageKey } from '../types/applicationPages';
import { EventReservationId } from '../../commonv3/types/eventReservations';
import { ByServiceSurveyKeys, getSurveyKeyForService, SurveyKey, SurveyKeysPlane } from '../../commonv3/types/surveys';
import { LessonHomeworkId } from '../../commonv3/types/lesson_homeworks';
import { LessonHomeworkSubmissionToken } from '../../commonv3/types/lesson_homework_submissions';
import { SheEnv } from '../../commonv3/utils/envs';
import { NotificationDeliveryToken } from '../types/notification_deliveries';
import { Service } from '../../commonv3/types/service';
import { ReservationId } from '../../adminv3/types/session';
import { MembershipPlanGroupKey } from '../types/membershipPlans';
import { UserId } from '../../mainv2/types/user';

export type EnhancedLocationDescriptor = LocationDescriptorObject & {
  stringify(): string;
  toAbsolute(): string;
};

function v3Path(path: string, params: Partial<LocationDescriptorObject> = {}): EnhancedLocationDescriptor {
  return {
    pathname: `/${path}`,
    stringify() {
      const search = this.search ? `?${this.search}` : '';
      const hash = this.hash ? this.hash : '';
      return `/${path}${hash}${search}`;
    },
    toAbsolute() {
      return `${location.origin}${this.stringify()}`;
    },
    ...params,
  };
}

const DynamicPath = {
  openEventOverlay(current: Location, eventId: number): LocationDescriptorObject {
    const { search } = current;
    const query = search.startsWith('?') ? search.slice(1) : search;
    return {
      ...current,
      search: queryString.stringify({ ...queryString.parse(query), event_id: eventId.toString() }),
    };
  },
  closeEventOverlay(current: Location): LocationDescriptorObject {
    const { search } = current;
    const query = queryString.parse(search.startsWith('?') ? search.slice(1) : search);
    delete query.event_id;
    return {
      ...current,
      search: queryString.stringify(query),
    };
  },
};

export const V3Routes = {
  DynamicPath,
  trial(anchor?: string) {
    return {
      pathname: '/trial',
      hash: anchor ? `#${anchor}` : undefined,
    };
  },
  trialDocument() {
    return {
      pathname: `/trial/document_01`,
    };
  },
  trialLessons() {
    return {
      pathname: `/trial_lessons`,
    };
  },
  trialLessonsMultiCreator() {
    return {
      pathname: `/trial_lessons/multicreator`,
    };
  },
  trialLessonReservationComplete(lessonId: TrialLessonId) {
    return {
      pathname: `/trial_lessons/${lessonId}/reservation_complete`,
    };
  },
  MultiCreatorTrialLessonReservationComplete(lessonId: TrialLessonId) {
    return {
      pathname: `/trial_lessons/multicreator/${lessonId}/reservation_complete`,
    };
  },
  oldTrialLessonReschedule(reservationId: EventReservationId) {
    return {
      pathname: `/trial_reservations/${reservationId}/reschedule`,
    };
  },
  newTrialLessonReschedule(reservationId: EventReservationId) {
    const pathname = `/trial_lessons/${reservationId}/reschedule`;
    if (SheEnv.isProduction()) {
      return `https://trial-lesson.shelikes.jp${pathname}`;
    } else if (SheEnv.isStaging()) {
      return `https://staging.trial-lesson.shelikes.jp${pathname}`;
    } else {
      return `http://localhost:3002${pathname}`;
    }
  },
  trialLessonRescheduleComplete(lessonId: TrialLessonId, user_id: UserId) {
    const pathname = `/trial_lessons/reservation_complete?lesson_id=${lessonId}&u_pattern=${String(user_id).slice(
      -1
    )}&is_rescheduled=true`;
    if (SheEnv.isProduction()) {
      return `https://trial-lesson.shelikes.jp${pathname}`;
    }
    if (SheEnv.isStaging()) {
      return `https://staging.trial-lesson.shelikes.jp${pathname}`;
    }
    return `http://localhost:3002${pathname}`;
  },
  trialFaq() {
    return '/trial/faq';
  },
  pricing() {
    return '/pricing';
  },
  trialReservation(id: TrialLessonId) {
    return {
      pathname: `/trial_lessons/${id}/reservation`,
    };
  },
  application() {
    return v3Path('application');
  },
  applicationPageSummary(page_key: ApplicationPageKey) {
    return v3Path(`application_pages/${page_key}/apply`);
  },
  applicationPageUserInfo(page_key: ApplicationPageKey) {
    return v3Path(`application_pages/${page_key}/user_info`);
  },
  applicationPageSignIn(page_key: ApplicationPageKey) {
    return v3Path(`application_pages/${page_key}/sign_in`);
  },
  applicationPageUserSummary(page_key: ApplicationPageKey) {
    return v3Path(`application_pages/${page_key}/user_summary`);
  },
  applicationPageHomeLocationSelect(page_key: ApplicationPageKey) {
    return v3Path(`application_pages/${page_key}/home_location`);
  },
  applicationPageStartDateSelect(page_key: ApplicationPageKey) {
    return v3Path(`application_pages/${page_key}/start_date`);
  },
  applicationPagePaymentMethod(page_key: ApplicationPageKey) {
    return v3Path(`application_pages/${page_key}/payment_method`);
  },
  applicationPageConfirmation(page_key: ApplicationPageKey) {
    return v3Path(`application_pages/${page_key}/summary`);
  },
  applicationPageComplete(page_key: ApplicationPageKey) {
    return v3Path(`application_pages/${page_key}/complete`);
  },
  multicreatorApplication() {
    return v3Path('apply/multicreator');
  },
  multicreatorApplicationSummary() {
    return v3Path(`apply/multicreator/apply`);
  },
  multicreatorApplicationUserInfo() {
    return v3Path(`apply/multicreator/user_info`);
  },
  multicreatorApplicationSignIn() {
    return v3Path(`apply/multicreator/sign_in`);
  },
  multicreatorApplicationUserSummary() {
    return v3Path(`apply/multicreator/user_summary`);
  },
  multicreatorApplicationHomeLocationSelect() {
    return v3Path(`apply/multicreator/home_location`);
  },
  multicreatorApplicationStartDateSelect() {
    return v3Path(`apply/multicreator/start_date`);
  },
  multicreatorApplicationPackageSelect() {
    return v3Path(`apply/multicreator/package`);
  },
  multicreatorApplicationPaymentMethod() {
    return v3Path(`apply/multicreator/payment_method`);
  },
  multicreatorApplicationConfirmation() {
    return v3Path(`apply/multicreator/summary`);
  },
  multicreatorApplicationComplete() {
    return v3Path(`apply/multicreator/complete`);
  },
  multicreatorFull() {
    return v3Path(`full/multicreators`);
  },
  beautyApplication() {
    return v3Path(`apply/beauty`);
  },
  moneyApplication() {
    return v3Path(`apply/money`);
  },
  applicationSignIn() {
    return v3Path('application/sign_in');
  },
  applicationUserInfo() {
    return v3Path('application/user_info');
  },
  applicationStartDateSelect() {
    return v3Path('application/start_date');
  },
  applicationHomeLocationSelect() {
    return v3Path('application/home_location');
  },
  applicationPlanSelect() {
    return v3Path('application/plans');
  },
  applicationConfirmation() {
    return v3Path('application/summary');
  },
  applicationPaymentMethod() {
    return v3Path('application/payment_method');
  },
  applicationAddressInfo() {
    return v3Path('application/user_address');
  },
  applicationComplete() {
    return v3Path('application/complete');
  },
  applicationError() {
    return v3Path('application/error');
  },
  examWritingSection(sectionId: ExamWritingSectionId) {
    return v3Path(`exam_writing_sections/${sectionId}`);
  },
  examPracticalSection(sectionId: ExamPracticalSectionId) {
    return v3Path(`exam_practical_sections/${sectionId}`);
  },
  examPracticalSectionSubmissionShow(token: LessonHomeworkSubmissionToken) {
    return v3Path(`exam_practical_section_submissions/${token}`);
  },
  examPracticalSectionSubmissionShowForReviewer(token: ExamPracticalSectionSubmissionToken) {
    return v3Path(`reviewer/exam_practical_section_submissions/${token}`);
  },
  examPracticalSectionSubmissionNew(sectionId: ExamPracticalSectionId) {
    return v3Path(`exam_practical_sections/${sectionId}/submissions/new`);
  },
  examPracticalSectionSubmissionComplete(sectionId: ExamPracticalSectionId) {
    return v3Path(`exam_practical_sections/${sectionId}/submissions/complete`);
  },
  examPracticalSectionSubmissionsIndex(sectionId: ExamPracticalSectionId) {
    return v3Path(`exam_practical_sections/${sectionId}/submissions`);
  },
  eventArchives() {
    return v3Path(`event_archives`);
  },
  eventDetailsPage(eventId: EventId) {
    return v3Path(`events/${eventId}`);
  },
  home() {
    return v3Path('home');
  },
  events(ref: string | null = null) {
    return v3Path('events', { search: ref ? queryString.stringify({ ref }) : '' });
  },
  coaching() {
    return v3Path('coaching');
  },
  coachingSessions(sourceType: string | null = null) {
    return v3Path('coaching/sessions', {
      search: sourceType ? queryString.stringify({ sourceType }) : '',
    });
  },
  coachingSessionReservation(sessionId: SessionId) {
    return v3Path(`coaching/sessions/${sessionId}/reservation`);
  },
  coachingSessionReservationComplete(sessionId: SessionId) {
    return v3Path(`coaching/sessions/${sessionId}/reservation_complete`);
  },
  coachingSessionReservationError(sessionId: SessionId, message: string) {
    return v3Path(`coaching/sessions/${sessionId}/reservation_error`, {
      search: message && queryString.stringify({ message }),
    });
  },
  careerCoachingSessions() {
    return v3Path(`coaching/career_coaching_sessions`);
  },
  mokumoku() {
    return v3Path('mokumoku');
  },
  mokumokuSessions(key: MembershipPlanGroupKey = MembershipPlanGroupKey.LIKES, sourceType: string | null = null) {
    const param = sourceType
      ? {
          key: key,
          sourceType: sourceType,
        }
      : {
          key: key,
        };
    return v3Path('mokumoku/sessions', {
      search: queryString.stringify(param),
    });
  },
  mokumokuSessionReservation(sessionId: SessionId) {
    return v3Path(`mokumoku/sessions/${sessionId}/reservation`);
  },
  mokumokuSessionReservationComplete(sessionId: SessionId) {
    return v3Path(`mokumoku/sessions/${sessionId}/reservation_complete`);
  },
  mokumokuSessionReservationError(sessionId: SessionId, message: string) {
    return v3Path(`mokumoku/sessions/${sessionId}/reservation_error`, {
      search: message && queryString.stringify({ message }),
    });
  },
  mokumokuStudyRoom() {
    return v3Path('mokumoku/mokumoku_study_room');
  },
  badges() {
    return v3Path('badges');
  },
  coursePlanningSessions(sourceType: string | null = null) {
    return v3Path('home/course_planning/sessions', {
      search: sourceType ? queryString.stringify({ sourceType }) : '',
    });
  },
  coursePlanningSessionReservation(sessionId: SessionId) {
    return v3Path(`home/course_planning/sessions/${sessionId}/reservation`);
  },
  coursePlanningSessionReservationComplete(sessionId: SessionId) {
    return v3Path(`home/course_planning/sessions/${sessionId}/reservation_complete`);
  },
  coursePlanningSessionReservationError(sessionId: SessionId, message: string) {
    return v3Path(`home/course_planning/sessions/${sessionId}/reservation_error`, {
      search: message && queryString.stringify({ message }),
    });
  },
  creators() {
    return v3Path('she_creators');
  },
  courses() {
    return v3Path('courses');
  },
  course(id: CourseId, preview = false) {
    return v3Path(`courses/${id}`, { search: preview ? queryString.stringify({ preview }) : '' });
  },
  studyRoutesDesign() {
    return v3Path('courses/study_routes/design');
  },
  studyRoutesMarketing() {
    return v3Path('courses/study_routes/marketing');
  },
  studyRoutesWriting() {
    return v3Path('courses/study_routes/writing');
  },
  plusLesson(plusLessonId: number) {
    return v3Path(`plus_lessons/${plusLessonId}`);
  },
  plusLessonView(plusLessonId: number) {
    return v3Path(`plus_lessons/${plusLessonId}/view`);
  },
  courseSet(path: string) {
    return v3Path(`course/set/${path}`);
  },
  lesson(id: CourseId, lessonId: LessonId) {
    return v3Path(`courses/${id}/lessons/${lessonId}`);
  },
  courseSection(id: CourseId, lessonId: LessonId, sectionId: SectionId, preview = false) {
    return v3Path(`courses/${id}/lessons/${lessonId}/sections/${sectionId}`, {
      search: preview ? queryString.stringify({ preview }) : '',
    });
  },
  block(id: CourseId, lessonId: LessonId, sectionId: SectionId, blockId: number) {
    return v3Path(`courses/${id}/lessons/${lessonId}/sections/${sectionId}`, {
      hash: `#${String(blockId)}`,
    });
  },
  section(id: SectionId) {
    return v3Path(`sections/${id}`);
  },
  myPage() {
    return v3Path('home/mypage');
  },
  reward() {
    return v3Path('home/mypage/reward');
  },
  homeLocation() {
    return v3Path('home/mypage/home_location');
  },
  homeLocationUpdate() {
    return v3Path('home_location/update');
  },
  plan() {
    return v3Path('home/mypage/plans');
  },
  multiCreators() {
    return v3Path('home/mypage/multicreators');
  },
  planUpdate(currentItemId: MembershipItemId) {
    return v3Path(`membership_plans/${currentItemId}/update`);
  },
  planUpdatePlanSelect(currentItemId: MembershipItemId) {
    return v3Path(`membership_plans/${currentItemId}/update/plans`);
  },
  planUpdatePaymentMethod(currentItemId: MembershipItemId) {
    return v3Path(`membership_plans/${currentItemId}/update/payment_method`);
  },
  planUpdateConfirm(currentItemId: MembershipItemId) {
    return v3Path(`membership_plans/${currentItemId}/update/confirm`);
  },
  planUpdateComplete(currentItemId: MembershipItemId) {
    return v3Path(`membership_plans/${currentItemId}/update/complete`);
  },
  payments() {
    return v3Path('home/mypage/payments');
  },
  payment_info() {
    return v3Path('home/mypage/payment_info/');
  },
  retry_payment(code: string) {
    return v3Path(`home/mypage/retry_payment/${code}`);
  },
  generate_receipt(code: string) {
    return v3Path(`home/mypage/payments/${code}/receipt`);
  },
  receipt(code: string, name: string, purpose: string, appelation: string) {
    return v3Path(
      `payments/${code}/receipt/?${queryString.stringify({
        name: name,
        purpose: purpose,
        appelation: appelation,
      })}`
    );
  },
  password() {
    return v3Path('home/mypage/password');
  },
  email() {
    return v3Path('home/mypage/email');
  },
  profile() {
    return v3Path('home/mypage/profile');
  },
  address() {
    return v3Path('home/mypage/address');
  },
  reservations() {
    return v3Path('home/mypage/reservations');
  },
  faq() {
    return v3Path('faq');
  },
  courseWorkSubmissions() {
    return v3Path('reviewer/course_work_submissions');
  },
  userCourseWorkSubmissions() {
    return v3Path('course_work_submissions');
  },
  communityService() {
    if (SheEnv.isProduction()) {
      return 'https://community.shelikes.jp';
    }
    if (SheEnv.isStaging()) {
      return 'https://staging.community.shelikes.jp';
    }
    return 'http://localhost:4000';
  },
  v2Migration() {
    return v3Path('migration');
  },
  goals() {
    return v3Path('home/goals');
  },
  idealCareer() {
    return v3Path('home/goals/ideal_career');
  },
  monthlyGoalSetting(year?: number, month?: number) {
    return v3Path('home/goals/monthly/setting', {
      search: (year && month && queryString.stringify({ year, month })) || '',
    });
  },
  monthlyGoalSettingComplete(year?: number, month?: number) {
    return v3Path('home/goals/monthly/setting/complete', {
      search: (year && month && queryString.stringify({ year, month })) || '',
    });
  },
  monthlyGoalReflection(year?: number, month?: number) {
    return v3Path('home/goals/monthly/reflection', {
      search: (year && month && queryString.stringify({ year, month })) || '',
    });
  },
  monthlyGoalReflectionComplete(year: number, month: number) {
    return v3Path('home/goals/monthly/reflection/complete', {
      search: (year && month && queryString.stringify({ year, month })) || '',
    });
  },
  reserveCoaching() {
    return v3Path('home/introduction/coaching');
  },
  eventReservationAttendanceComplete(event_reservation_id: number) {
    return v3Path(`event_reservations/${event_reservation_id}/attendance/complete`);
  },
  reservationAttendanceComplete(reservation_id: number) {
    return v3Path(`reservations/${reservation_id}/attendance/complete`);
  },
  subscriptionSuspensionCancel(subscriptionCode: string, subscriptionSuspensionCode: string) {
    return v3Path(`subscription/${subscriptionCode}/suspension/${subscriptionSuspensionCode}/cancel`);
  },
  subscriptionSuspensionInfo(subscriptionCode: string) {
    return v3Path(`subscriptions/${subscriptionCode}/suspension`);
  },
  subscriptionSuspend(subscriptionCode: string) {
    return v3Path(`subscriptions/${subscriptionCode}/suspend`);
  },
  subscriptionSuspended(subscriptionCode: string) {
    return v3Path(`subscriptions/${subscriptionCode}/suspended`);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  survey(key: SurveyKey | string, parameters: any = {}) {
    return v3Path(`surveys/${key}`, {
      search: queryString.stringify(parameters),
    });
  },
  trialLessonSurvey(id: TrialLessonId, service: Service = Service.LIKES) {
    return this.survey(getSurveyKeyForService(ByServiceSurveyKeys.TRIAL_LESSON, service), { trial_lesson_id: id });
  },
  eventSurvey(id: EventId, service: Service = Service.LIKES) {
    return this.survey(getSurveyKeyForService(ByServiceSurveyKeys.SPECIAL_EVENT, service), { event_id: id });
  },
  sessionSurvey(
    id: SessionId,
    sessionType: SessionType,
    service: Service = Service.LIKES,
    sessionTemplateKey: SessionTemplateKey
  ) {
    switch (sessionType) {
      case SessionType.GROUP_COACHING:
        if (
          // 2023年3月から運用開始
          sessionTemplateKey === SessionTemplateKey.ONLINE_INITIAL_COACHING_PREP_WORKSHOP ||
          sessionTemplateKey === SessionTemplateKey.OFFLINE_INITIAL_COACHING_PREP_WORKSHOP
        ) {
          return this.survey(getSurveyKeyForService(SurveyKeysPlane.WELCOME_WORKSHOP), {
            session_id: id,
          });
        } else {
          return this.survey(getSurveyKeyForService(ByServiceSurveyKeys.GROUP_COACHING, service), { session_id: id });
        }
      case SessionType.MOKUMOKU:
        return this.survey(getSurveyKeyForService(ByServiceSurveyKeys.MOKUMOKU, service), { session_id: id });
    }

    throw new Error('Unsupported sessionType : ' + sessionType);
  },
  withdrawalApply() {
    return v3Path('withdrawal');
  },
  withdrawalForm() {
    return v3Path('withdrawal/form');
  },
  withdrawalComplete() {
    return v3Path('withdrawal/complete');
  },
  withdrawalCancel() {
    return v3Path('withdrawal/cancel');
  },
  subscriptionSuspensionCancelInfo(code: string) {
    return v3Path(`subscriptions/${code}/suspension/cancel`);
  },
  forgotPassword() {
    return v3Path(`passwords/forgot`);
  },
  validateResetCode() {
    return v3Path(`passwords/reset_code`);
  },
  resetPassword() {
    return v3Path(`passwords/reset`);
  },
  resetPasswordComplete() {
    return v3Path(`passwords/reset/complete`);
  },
  lessonHomeworkSubmission(homeworkId: LessonHomeworkId) {
    return v3Path(`lesson_homeworks/${homeworkId}/submissions/new`);
  },
  lessonHomeworkSubmissionShow(token: LessonHomeworkSubmissionToken) {
    return v3Path(`lesson_homework_submissions/${token}`);
  },
  lessonHomeworkSubmissionShowForReviewer(token: LessonHomeworkSubmissionToken) {
    return v3Path(`reviewer/lesson_homework_submissions/${token}`);
  },
  lessonHomeworkSubmissionComplete(homeworkId: LessonHomeworkId) {
    return v3Path(`lesson_homeworks/${homeworkId}/submissions/complete`);
  },
  lessonHomeworkSubmissionsIndex(homeworkId: LessonHomeworkId) {
    return v3Path(`lesson_homeworks/${homeworkId}/submissions`);
  },
  NotificationDeliveryShowPage(token: NotificationDeliveryToken) {
    return v3Path(`notifications/${token}`);
  },
  NotificationDeliveryListPage() {
    return v3Path(`notifications`);
  },
  likesHome() {
    if (SheEnv.isProduction()) {
      return 'https://shelikes.jp';
    }
    if (SheEnv.isStaging()) {
      return 'https://staging.shelikes.jp';
    }
    return 'http://localhost:3000';
  },
  likesUrl(path: string) {
    if (SheEnv.isProduction()) {
      return `https://shelikes.jp${path}`;
    }
    if (SheEnv.isStaging()) {
      return `https://staging.shelikes.jp${path}`;
    }
    return `http://localhost:3000${path}`;
  },
  signOutUrl() {
    if (SheEnv.isProduction() || SheEnv.isStaging()) {
      return V3Routes.likesUrl('/auth/sign_out_account_page');
    }
    return '/auth/sign_out_account_page';
  },
};

function beautyPath(path: string, params: Partial<LocationDescriptorObject> = {}): EnhancedLocationDescriptor {
  let basePath = 'http://localhost:4001';
  if (SheEnv.isProduction()) {
    basePath = 'https://beauty.shelikes.jp';
  }
  if (SheEnv.isStaging()) {
    basePath = 'https://staging.beauty.shelikes.jp';
  }
  return {
    pathname: `${basePath}/${path}`,
    stringify() {
      const search = this.search ? `?${this.search}` : '';
      const hash = this.hash ? this.hash : '';
      return `/${path}${hash}${search}`;
    },
    toAbsolute() {
      return `${location.origin}${this.stringify()}`;
    },
    ...params,
  };
}

export const BeautyRoutes = {
  trialLessons() {
    return beautyPath(`trial_lessons`);
  },
  trialLessonReschedule(id: ReservationId) {
    return beautyPath(`trial_lessons/${id.toString()}/reschedule`);
  },
  home() {
    return beautyPath('');
  },
  memberHome() {
    return beautyPath('home');
  },
};

function moneyPath(path: string, params: Partial<LocationDescriptorObject> = {}): EnhancedLocationDescriptor {
  let basePath = 'http://localhost:5001';
  if (SheEnv.isProduction()) {
    basePath = 'https://money.shelikes.jp';
  }
  if (SheEnv.isStaging()) {
    basePath = 'https://staging.money.shelikes.jp';
  }
  return {
    pathname: `${basePath}/${path}`,
    stringify() {
      const search = this.search ? `?${this.search}` : '';
      const hash = this.hash ? this.hash : '';
      return `/${path}${hash}${search}`;
    },
    toAbsolute() {
      return `${location.origin}${this.stringify()}`;
    },
    ...params,
  };
}

export const MoneyRoutes = {
  trialLessons() {
    return moneyPath(`trial_lessons`);
  },
  home() {
    return moneyPath('');
  },
  memberHome() {
    return moneyPath('home');
  },
};

function accountPath(path: string, params: Partial<LocationDescriptorObject> = {}): EnhancedLocationDescriptor {
  let basePath = `${process.env.ACCOUNT_BASE_URL || window.location.origin}/account`;
  if (SheEnv.isProduction()) {
    basePath = 'https://account.shelikes.jp';
  }
  if (SheEnv.isStaging()) {
    basePath = 'https://staging.account.shelikes.jp';
  }
  const pathname = `${basePath}/${path}`;
  return {
    pathname,
    stringify() {
      const search = this.search ? `?${this.search}` : '';
      const hash = this.hash ? this.hash : '';
      return `${pathname}${hash}${search}`;
    },
    toAbsolute() {
      return pathname;
    },
    ...params,
  };
}

/**
 * Likes側のコードからアカウントページに遷移する時に用いる
 */
export const AbsoluteAccountRoutes = {
  home() {
    return accountPath('');
  },
};

/**
 * FAQ の URL
 */
export const FAQ_URL = 'https://support.shelikes.jp/';
