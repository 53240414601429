import { Page, PageHeader } from '../../components/layout/page';
import React from 'react';
import { observer } from 'mobx-react';
import { CardInput } from '../../../mainv3/components/molecules/payment/CardInput';
import { Button } from '../../../commonv3/components/atoms/buttons/Button';
import { usePaymentInfo } from './states/PaymentInfoState';
import './paymentInfoPage.module.scss';
import { LoaderRow } from '../../../mainv3/components/atoms/layout/LoaderRow';

export const PaymentInfoPage = observer(() => {
  const { formState, submitting, success, submit, initialized } = usePaymentInfo();
  if (!initialized) {
    return <LoaderRow />;
  }
  return (
    <Page id="payment-info-page">
      <PageHeader>お支払い情報の変更</PageHeader>
      <CardInput creditCardState={formState} />
      <div styleName="form-submit-button">
        <Button
          id="submit-button"
          size="m"
          type={success ? 'success' : 'primary'}
          loading={submitting}
          onClick={submit}
        >
          {success ? '更新しました' : '保存する'}
        </Button>
      </div>
    </Page>
  );
});
