import { MainV3ApiBase } from './base';
import { SubscriptionSummary } from '../types/subscriptionSummary';
import { Service } from '../../commonv3/types/service';

export const SubscriptionSummariesApis = {
  base() {
    return MainV3ApiBase.appendPath('users/current/subscription_summaries');
  },
  findAll(services?: Service[]): Promise<SubscriptionSummary[]> {
    return this.base().get({ services: services ? services.join(',') : undefined });
  },
};
