import React from 'react';
import classNames from 'classnames';
import './checkBoxCard.module.scss';
import CheckSvg from './images/check.svg';
import { MultiLineStringInput } from '../../../../commonv3/components/atoms/forms/primitive/StringInput';

type CheckBoxProps = {
  checked: boolean;
  onCheck: (checked: boolean) => void;
  className?: string;
  styleName?: string;
};

const CheckBox = ({ checked, onCheck, className = undefined }: CheckBoxProps) => (
  <div
    styleName={classNames('check-box', { checked: checked })}
    onClick={() => onCheck(!checked)}
    className={classNames(className, { checked: checked })}
  >
    {checked && <img styleName="checked-icon" src={CheckSvg} />}
  </div>
);

const Badge = ({ children }: { children: React.ReactNode }) => {
  return <span styleName="badge">{children}</span>;
};

type Props = {
  id?: string;
  checked: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  label: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subLabel?: any;
  description?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  badge?: any;
  onCheck: (checked: boolean) => void;
  widthType?: 's' | 'm' | 'l';
  className?: string;
  styleName?: string;
  'data-value'?: string;
};

export const CheckBoxCard = ({
  id,
  label,
  checked,
  badge,
  subLabel,
  description,
  onCheck,
  className,
  widthType = 'm',
  'data-value': dataAttribute,
}: Props) => (
  <div
    id={id}
    styleName={classNames('check-box-card', widthType, { checked: checked })}
    className={className}
    onClick={() => onCheck(!checked)}
    data-value={dataAttribute}
  >
    <div styleName="body">
      <div styleName="label">
        <CheckBox checked={checked} onCheck={onCheck} styleName="chk" />
        {label}
        {badge && <Badge>{badge}</Badge>}
      </div>
      {subLabel && <div styleName="sub-label">{subLabel}</div>}
    </div>
    {description && <div styleName="description">{description}</div>}
  </div>
);

type FreeAnswerProps = Props & {
  value: string;
  onChange: (value: string) => void;
};

export const FreeAnswerCheckBoxCard = ({
  label,
  checked,
  onCheck,
  value,
  onChange,
  className,
  widthType = 'm',
}: FreeAnswerProps) => (
  <div
    styleName={classNames('check-box-card-fa', widthType, { checked: checked })}
    className={className}
    onClick={() => onCheck(!checked)}
  >
    <div styleName="fa-top">
      <CheckBox checked={checked} onCheck={onCheck} styleName="chk" />
      <div styleName="label">{label}</div>
    </div>
    <div styleName="fa-bottom">
      <MultiLineStringInput
        styleName="fa-input"
        value={value}
        onChange={(v) => {
          onChange(v);
          onCheck(!!v && v !== '');
        }}
        onClick={(e) => e.stopPropagation()}
      />
    </div>
  </div>
);
