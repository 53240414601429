import React, { FC, useEffect } from 'react';
import { Page, PageBody, PageHeader } from '../../components/layout/page';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { usePlanListState } from '../../states/PlanListState';
import { SubscriptionSummaryListView } from '../../../mainv3/components/organisms/mypage/plans/SubscriptionSummaryListView';
import './planListPage.module.scss';
import { AccountWithdrawalState } from '../../states/WithdrawalState';
import { MembershipPlanGroupKey } from '../../../mainv3/types/membershipPlans';
import { WithdrawalLabel } from '../../../mainv3/components/organisms/mypage/withdrawal/WithdrawalLabel';
import { LoaderRow } from '../../../mainv3/components/atoms/layout/LoaderRow';
type Props = {
  id?: string;
  title: string;
};

const PlanSection: FC<Props> = ({ id, title, children }) => {
  return (
    <div styleName="section" id={id}>
      <div styleName="title">{title}</div>
      <div styleName="body">{children}</div>
    </div>
  );
};

export const PlanListPage = observer(() => {
  const { pageState } = useLocalObservable(() => ({
    pageState: new AccountWithdrawalState(),
  }));

  useEffect(() => {
    pageState.init();
  }, []);

  const { hasActiveMoneyPlans, hasFeatureMoneyPlans, moneySummaries, initializing } = usePlanListState();
  const { targetWithdrawal, isWithdrawalSet } = pageState;
  const isMoneyWithdrawalSet = isWithdrawalSet(MembershipPlanGroupKey.MONEY);

  if (initializing) {
    return <LoaderRow />;
  }

  return (
    <Page id="plan-list-page">
      <PageHeader>プランの確認</PageHeader>
      <PageBody>
        {(hasActiveMoneyPlans || hasFeatureMoneyPlans) && (
          <PlanSection id="money-section" title="SHEmoney">
            {isMoneyWithdrawalSet && (
              <div styleName="withdrawal-label-wrapper">
                <WithdrawalLabel
                  withdrawal={targetWithdrawal(MembershipPlanGroupKey.MONEY)}
                  canCancellWithdrawal={false}
                />
              </div>
            )}
            <SubscriptionSummaryListView summaries={moneySummaries} isWithdrawalSet={isMoneyWithdrawalSet} />
          </PlanSection>
        )}
      </PageBody>
    </Page>
  );
});
