import { action, computed, observable, makeObservable } from 'mobx';
import { FieldStateV3, FormContext } from '../../../../commonv3/states/form/FormStateV3';
import {
  DependencyValidators,
  EmailValidators,
  NumberValidators,
  PasswordValidators,
  StringValidators,
} from '../../../../commonv3/states/form/validators';
import { SubmitState } from '../../../../commonv3/states/SubmitState';
import { PasswordApis } from '../../../../mainv3/apis/passwords';
import { ResetCodeShowView } from '../../../../mainv3/types/resetCodes';
import { RemoteResourceV3, updateRemoteResourceV3 } from '../../../../commonv3/api/RemoteResourceV3';
import type { RouterStore } from '../../../../common/mobx-react-router';
import { ApiError } from '../../../../commonv3/api/protocol';
import { AccountRoutes } from '../../../routes/AccountRoutes';

export class ResetPasswordPageState {
  routerState: RouterStore | null = null;
  emailField = new FieldStateV3<string>([EmailValidators.simple()]);
  resetCodeField = new FieldStateV3<string>([
    NumberValidators.required(),
    NumberValidators.equalLength(6),
    NumberValidators.valid(),
  ]);
  passwordField = new FieldStateV3<string>([StringValidators.required(), PasswordValidators.minLength(6)]);
  passwordConfirmationField = new FieldStateV3<string>([
    StringValidators.required(),
    DependencyValidators.equalWith(this.passwordField, 'パスワードが一致しませんでした'),
  ]);
  submitState = new SubmitState();
  resetCodeResource: RemoteResourceV3<ResetCodeShowView> = RemoteResourceV3.loading();
  resetCodeId: number | null;
  resetCodeToken: string | null;
  email = '';
  resendCount: number | undefined = undefined;
  formError: string | null | undefined = undefined;
  resent = false;
  resetSuccess = false;

  constructor(routerState: RouterStore) {
    makeObservable(this, {
      emailField: observable,
      resetCodeField: observable,
      passwordField: observable,
      passwordConfirmationField: observable,
      submitState: observable,
      resetCodeResource: observable,
      resetCodeId: observable,
      resetCodeToken: observable,
      email: observable,
      resendCount: observable,
      formError: observable,
      resent: observable,
      resetSuccess: observable,
      submitting: computed,
      resetCode: computed,
      requestCode: action.bound,
      validateCode: action.bound,
      resendCode: action.bound,
      resetPassword: action.bound,
    });

    this.routerState = routerState;
    this.resetCodeId = null;
    this.resetCodeToken = null;
    this.emailField._init('email', new FormContext(), '');
    this.resetCodeField._init('reset_code', new FormContext(), '');
    this.passwordField._init('password', new FormContext(), '');
    this.passwordConfirmationField._init('password_confirmation', new FormContext(), '');
  }

  init() {
    this.resendCount = 0;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (AccountRoutes.forgotPassword().pathname != this.routerState!.location.pathname && !this.resetCodeId) {
      this.redirectToHome();
    }
  }

  redirectToHome() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.routerState!.replace(AccountRoutes.forgotPassword());
  }

  get submitting() {
    return this.submitState.submitting;
  }

  get resetCode() {
    return this.resetCodeResource.get();
  }

  async requestCode() {
    try {
      this.emailField.clearErrors();
      await this.emailField.validate();
      if (!this.emailField.hasErrors()) {
        await this.submitState.operate(async () => {
          await updateRemoteResourceV3(
            this.resetCodeResource,
            (v) => (this.resetCodeResource = v),
            () => PasswordApis.requestResetCode(this.emailField.getDirtyValue())
          );
          const resetCode = this.resetCodeResource.getOrThrow();
          this.resetCodeId = resetCode.id;
          this.resetCodeToken = resetCode.token;
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.routerState!.push(AccountRoutes.validateResetCode());
        });
      }
    } catch (e) {
      this.emailField.setErrors(['このメールアドレスは存在しません']);
    }
  }

  async validateCode() {
    try {
      this.resetCodeField.clearErrors();
      await this.resetCodeField.validate();
      if (!this.resetCodeField.hasErrors()) {
        await this.submitState.operate(async () => {
          await PasswordApis.validateResetCode(this.resetCodeField.getDirtyValue(), this.resetCodeToken);
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.routerState!.push(AccountRoutes.resetPassword());
        });
      }
    } catch (e) {
      if (e instanceof ApiError && e.isOperationalError()) {
        this.resetCodeField.setErrors([e.body]);
      } else {
        this.resetCodeField.setErrors(['このコードは存在しません']);
      }
    }
  }

  async resendCode() {
    try {
      this.formError = null;
      if (typeof this.resendCount === 'number' && this.resendCount > 2) {
        this.formError = '再送可能回数を超えています。もう一度やり直してください。';
      } else {
        await this.submitState.operate(async () => {
          await updateRemoteResourceV3(
            this.resetCodeResource,
            (v) => (this.resetCodeResource = v),
            () => PasswordApis.resendResetCode(this.resetCodeToken)
          );
          const resetCode = await this.resetCodeResource.getOrThrow();
          this.resetCodeId = resetCode.id;
          this.resetCodeToken = resetCode.token;
          if (typeof this.resendCount === 'number') {
            this.resendCount++;
          }
          this.resent = true;
          setTimeout(async () => {
            this.resent = false;
          }, 1000);
        });
      }
    } catch (e) {
      this.formError = '再送出来ませんでした。もう一度やり直してください。';
    }
  }

  async resetPassword() {
    try {
      this.clearPasswordErrors();
      await this.validatePasswordFields();
      if (!this.passwordFieldsHasError()) {
        await this.submitState.operate(async () => {
          await PasswordApis.resetPassword(
            this.resetCodeField.getDirtyValue(),
            this.resetCodeToken,
            this.passwordField.getDirtyValue(),
            this.passwordConfirmationField.getDirtyValue()
          );
          // Redirect to home
          this.resetSuccess = true;
          setTimeout(async () => {
            location.href = AccountRoutes.profile().stringify();
          }, 1500);
        });
      }
    } catch (e) {
      if (e instanceof ApiError && e.isOperationalError()) {
        this.formError = e.body;
      } else {
        this.formError = 'パスワードリセット出来ませんでした。もう一度やり直してください。';
      }
    }
  }

  clearPasswordErrors() {
    this.passwordField.clearErrors();
    this.passwordConfirmationField.clearErrors();
  }

  async validatePasswordFields() {
    await this.passwordField.validate();
    await this.passwordConfirmationField.validate();
  }

  passwordFieldsHasError(): boolean {
    return this.passwordField.hasErrors() || this.passwordConfirmationField.hasErrors();
  }
}
