import React from 'react';
import { DateQuestionState } from './states/QuestionState';
import { QuestionCard } from '../../molecules/surveys/QuestionCard';
import { observer } from 'mobx-react-lite';
import { DateInput } from '../../../../commonv3/components/atoms/forms/primitive/DateInput';
import './dateQuestionCard.module.scss';
import { SurveyQuestionFormat } from '../../../../commonv3/types/surveys';

type Props = {
  questionNumber: number;
  totalQuestions: number;
  questionState: DateQuestionState;
  format?: SurveyQuestionFormat;
};

export const DateQuestionCard = observer(({ questionNumber, totalQuestions, questionState, format }: Props) => {
  const { question, value, setValue, required, validate, errorMessage } = questionState;

  return (
    <QuestionCard
      questionNumber={questionNumber}
      totalQuestions={totalQuestions}
      sentence={question.sentence}
      subSentence={question.sub_sentence}
      youtubeKey={question.youtube_key}
      required={required}
      errorMessage={errorMessage}
      onBlur={validate}
      format={format}
    >
      <DateInput
        styleName="input"
        value={value}
        onChange={setValue}
        min={question.content.min}
        max={question.content.max}
      />
    </QuestionCard>
  );
});
