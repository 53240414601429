import React, { ComponentProps } from 'react';
import { Control, Controller, FieldValues, Path, UseControllerProps } from 'react-hook-form';
import { TextInput } from '@sheinc/shelikes-design-system';
import { joinErrorMessages } from './joinErrorMessages';

type TextInputProps = ComponentProps<typeof TextInput>;

type ReachHookFormProps<Values extends FieldValues> = {
  name: Path<Values>;
  control: Control<Values>;
  rules?: UseControllerProps<Values>['rules'];
};

type TextInputFieldProps = {
  labelLayout?: 'vertical' | 'horizontal';
};

type Props<Values extends FieldValues> = ReachHookFormProps<Values> &
  TextInputFieldProps &
  Omit<TextInputProps, 'layout'>;

export const TextInputField = <Values extends FieldValues>({
  name,
  control,
  rules,
  type,
  placeholder,
  label,
  labelLayout = 'vertical',
  help,
  required = false,
  disabled = false,
  testId,
}: Props<Values>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref: _, ...field }, fieldState: { error } }) => (
        <TextInput
          {...field}
          id={name}
          type={type}
          placeholder={placeholder}
          label={label}
          layout={labelLayout}
          help={help}
          required={required}
          disabled={disabled}
          error={joinErrorMessages(error)}
          testId={testId}
        />
      )}
    ></Controller>
  );
};
