import React from 'react';
import { DegreeQuestionState } from './states/QuestionState';
import { QuestionCard } from '../../molecules/surveys/QuestionCard';
import { observer } from 'mobx-react-lite';
import { DegreeSelectInput } from '../../atoms/select/DegreeSelectInput';
import { NonSpBr } from '../../../../commonv3/components/atoms/layouts/br';
import { GlobalStatesV3 } from '../../../../commonv3/states/GlobalStatesV3';
import './degreeQuestionCard.module.scss';
import { SurveyQuestionFormat } from '../../../../commonv3/types/surveys';

type Props = {
  questionNumber: number;
  totalQuestions: number;
  questionState: DegreeQuestionState;
  format?: SurveyQuestionFormat;
};

export const DegreeQuestionCard = observer(({ questionNumber, totalQuestions, questionState, format }: Props) => {
  const { question, value, setValue, required, validate, errorMessage } = questionState;
  const { isSp, isNonSp } = GlobalStatesV3.viewport;

  return (
    <QuestionCard
      questionNumber={questionNumber}
      totalQuestions={totalQuestions}
      sentence={question.sentence}
      subSentence={question.sub_sentence}
      youtubeKey={question.youtube_key}
      required={required}
      errorMessage={errorMessage}
      onBlur={validate}
      format={format}
    >
      <div styleName="upper">
        <div styleName="label">
          {isSp && <span>1 - </span>}全然
          <NonSpBr />
          おすすめしない
        </div>
        {isNonSp && (
          <div styleName="label">
            非常に
            <br />
            おすすめする
          </div>
        )}
      </div>
      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
      <DegreeSelectInput value={value!} onChange={setValue} />
      {isSp && (
        <div styleName="lower">
          <div styleName="label">非常におすすめする - 10</div>
        </div>
      )}
    </QuestionCard>
  );
});
