import React from 'react';
import { MoneyRoutes, V3Routes } from '../../../routes';
import { Link } from 'react-router-dom';
import './cancellationPolicy.module.scss';
import { TrialReservationIndexView } from '../../../types/eventReservations';
import { TrialLessonTarget } from '../../../../commonv3/types/trialLessons';
import { TrialLessonIndexView } from '../../../types/trialLessons';
import { MembershipPlanGroupKey } from '../../../types/membershipPlans';

type Props = {
  showRescheduleInformation: boolean;
  showMypageLink: boolean;
  styleName?: string;
  className?: string;
  reservation?: TrialReservationIndexView;
  lesson?: TrialLessonIndexView;
};

function trialLessonLinkForService(lesson: TrialLessonIndexView) {
  const plan_group_keys = lesson.membership_plan_group_keys;
  if (plan_group_keys.includes(MembershipPlanGroupKey.LIKES)) {
    return V3Routes.trialLessons();
  } else {
    return MoneyRoutes.trialLessons();
  }
}

function trialLessonLink(lesson: TrialLessonIndexView) {
  switch (lesson.target) {
    case TrialLessonTarget.NORMAL:
      return trialLessonLinkForService(lesson);
    case TrialLessonTarget.EXPERT:
      return V3Routes.trialLessonsMultiCreator();
    default:
      return V3Routes.trialLessons();
  }
}

export const TrialLessonCancellationPolicy = ({
  showRescheduleInformation,
  showMypageLink,
  className,
  lesson,
}: Props) => (
  <div styleName="cancellation-policy" className={className}>
    <div styleName="header">キャンセルについて</div>
    <ul styleName="body">
      {showMypageLink ? (
        <li>
          キャンセルの場合は<span styleName="red">前日までに</span>必ず
          <Link to={V3Routes.reservations()} styleName="link">
            マイページ
          </Link>
          からご自身でキャンセルをお願いします。
        </li>
      ) : (
        <li>
          キャンセルの場合は<span styleName="red">前日までに</span>キャンセルをお願いします。
        </li>
      )}
      <li>
        <span styleName="red">当日キャンセルの場合、キャンセル料として¥1,000を頂戴致します。</span>
        <br />
        また無断キャンセルの場合、今後一切の特典適用が出来かねますのでご注意下さい。
        <br />
        （尚、LINE@への連絡は必要御座いませんのでご留意下さい。）
      </li>
      {showRescheduleInformation && lesson && (
        <li>
          別日でのご参加をご希望の場合は、
          <Link to={trialLessonLink(lesson)} target="_blank" styleName="link">
            こちら
          </Link>
          よりご予約をお願いします。
        </li>
      )}
    </ul>
  </div>
);
