import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom';
import { initCsrfConfigV2 } from './commonv2/api/ApiV2';
import { viewportState } from './commonv2/states/globalStates';
import { initCsrfConfigV3 } from './commonv3/api/ApiV3';
import { initializeGlobalStateV3, GlobalStatesV3 } from './commonv3/states/GlobalStatesV3';
import { initCsrfConfig } from './main/api';
import { ApplicationAccount } from './application-account';
import './account.scss';
import { Provider as GraphqlProvider } from 'urql';
import { adminGraphQLClient as graphqlClient } from './commonv3/utils/gql';

const _App = () => {
  const [initialized, initialize] = useState(false);
  useEffect(() => {
    (async () => {
      initializeGlobalStateV3();
      await GlobalStatesV3.jwt.reloadJwt();
      initialize(true);
    })();
  }, []);
  return (
    <GraphqlProvider value={graphqlClient}>
      <DndProvider backend={HTML5Backend}>{initialized && <ApplicationAccount />}</DndProvider>
    </GraphqlProvider>
  );
};

document.addEventListener('DOMContentLoaded', () => {
  initCsrfConfig();
  initCsrfConfigV2();
  initCsrfConfigV3();

  window.addEventListener('resize', () => {
    viewportState.update();
    GlobalStatesV3.viewport.update();
  });

  ReactDOM.render(<_App />, document.getElementById('content'));
});
