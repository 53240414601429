import { datadogRum } from '@datadog/browser-rum';
import { SheEnv } from '../utils/envs';
import { GlobalStates } from '../../mainv2/states/globalStates';

export class DatadogRumClient {
  static init() {
    datadogRum.init({
      applicationId: '4f09f930-f72f-4cc6-8083-6465cdfb00cb',
      clientToken: 'pub72b735137d00b35d151157ff20b962b0',
      site: 'datadoghq.com',
      service: 'shelikes',
      env: SheEnv.get(),
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingOrigins: [/https:\/\/.*shelikes\.jp/],
    });
    if (GlobalStates.currentUser.isSignedIn()) {
      datadogRum.setUser({
        id: GlobalStates.currentUser.currentUser.id,
        name: GlobalStates.currentUser.currentUser.name,
        email: GlobalStates.currentUser.currentUser.email,
      });
    }
    datadogRum.startSessionReplayRecording();
  }
}
