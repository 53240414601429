import * as React from 'react';
import { GlobalStatesV3 } from '../../../commonv3/states/GlobalStatesV3';
import { SignInPath } from '../../routes/AuthPath';

/**
 * Redirects users to sign in page when she is not signed in
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function authenticated<P = any, T extends React.ComponentType<P> = any>(component: T): T {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  class Wrapped extends React.Component<P, any> {
    componentDidMount(): void {
      if (!GlobalStatesV3.currentUser.isSignedIn()) {
        location.href = SignInPath(location.href).toString();
      }
    }

    render() {
      if (!GlobalStatesV3.currentUser.isSignedIn()) {
        return null;
      }
      return React.createElement(component, this.props);
    }
  }
  return Wrapped as T;
}
