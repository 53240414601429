import React, { ReactNode } from 'react';
import './dateGroup.module.scss';
import moment from 'moment-timezone';
import { ISODateTime } from '../../../../admin/types/date';
import { DAY_OF_WEEKS } from '../../../../commonv3/types/dates';
import { Heading } from '@sheinc/shelikes-design-system';

type Props = {
  date: ISODateTime;
  children: ReactNode;
};
export const DateGroup = ({ date, children }: Props) => (
  <div styleName="date-group">
    <Heading size="XS">
      {moment(date).format('M月D日')}({DAY_OF_WEEKS[moment(date).weekday()]})
    </Heading>
    <div>{children}</div>
  </div>
);
