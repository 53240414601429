import React from 'react';
import moment from 'moment';
import { ISODate } from '../../../../commonv3/types/dates';

type DateRangeProps = {
  fromDate: ISODate;
  toDate: ISODate;
};
export const DateRange = ({ fromDate, toDate }: DateRangeProps) => (
  <span>
    {moment(fromDate).format('YYYY/MM/DD')}〜{toDate && moment(toDate).format('YYYY/MM/DD')}
  </span>
);

type CustomFormattedDateRangeProps = {
  fromDate: ISODate;
  toDate: ISODate;
};
export const CustomFormattedDateRange = ({ fromDate, toDate }: CustomFormattedDateRangeProps) => (
  <span>
    {fromDate && moment(fromDate).format('YYYY/MM/DD')}〜
    {toDate && moment(toDate).subtract(1, 'd').format('YYYY/MM/DD 23:59')}
  </span>
);
