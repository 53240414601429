import { FormState, FieldState } from '../../../../mainv2/states/FormState';
import { EditEmail } from '../../../../mainv3/types/user_info';

export class EmailChangeFormState extends FormState<EditEmail> {
  constructor() {
    super({
      email: new FieldState('', false),
      receive_promotion_emails: new FieldState<boolean>(true, false),
      receive_notification_emails: new FieldState<boolean>(true, false),
    });
  }
}
