import { observable, action, makeObservable } from 'mobx';
import { EmailChangeFormState } from './EmailChangeFormState';
import { UserInfoApis } from '../../../../mainv3/apis/user_info';
import { GlobalStatesV3 } from '../../../../commonv3/states/GlobalStatesV3';

export class EmailChangePageState {
  formState: EmailChangeFormState = new EmailChangeFormState();
  submitting = false;

  constructor() {
    makeObservable(this, {
      formState: observable,
      submitting: observable,
      submit: action.bound,
    });
  }

  init() {
    if (GlobalStatesV3.currentUser.isSignedIn()) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.formState.fields!.email.setValue(GlobalStatesV3.currentUser.currentUser.email);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.formState.fields!.receive_promotion_emails.setValue(
        GlobalStatesV3.currentUser.currentUser.user_preference.receive_promotion_emails || false
      );
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.formState.fields!.receive_notification_emails.setValue(
        GlobalStatesV3.currentUser.currentUser.user_preference.receive_notification_emails || false
      );
    }
  }

  async submit() {
    this.submitting = true;
    try {
      this.formState.clearErrors();
      try {
        await UserInfoApis.updateEmail(this.formState.asJson());
      } catch (error) {
        this.formState.setErrors(error.body);
      }
      GlobalStatesV3.currentUser.reloadUser();
    } finally {
      this.submitting = false;
    }
  }
}
