import { action, observable, makeObservable } from 'mobx';
import { ApiError } from '../../api/protocol';

export class FormContext<V> {
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  register(_id: string, _form: FormLike<V, any>) {}
}

export interface FormLike<V, E> {
  id: string;
  _init(id: string, context: FormContext<V>, value: V): void;
  getOriginalValue(): V;
  getDirtyValue(): V | null;
  hasErrors(): boolean;
  getErrors(): E;
  setErrors(errors: E): void;
  clearErrors(): void;
  validate(): Promise<void>;
}

export interface Validator<V, E> {
  validate(
    id: string,
    currentValue: V | null,
    context: FormContext<V> | null
  ): E | null | undefined | Promise<E | null | undefined>;
}

export type ValidatorFactory<V, E> = () => Validator<V, E>[];

export type SimpleFormSchema<T> = { [K in keyof T]: FieldStateV3<T[K]> };

export type ChangeHandler<V> = (value: V | null) => void;

export class FieldStateV3<V> implements FormLike<V, string[]> {
  id = '';
  // @ts-expect-error ts2564 この class は _init が呼び出される前提のため
  originalValue: V;
  dirtyValue: V | null = null;
  validators: Validator<V, string>[] | ValidatorFactory<V, string>;
  context: FormContext<V> | null = null;
  errors: string[] = [];
  validating = false;
  changeHandlers: ChangeHandler<V>[] = [];

  constructor(validators: Validator<V, string>[] | ValidatorFactory<V, string> = []) {
    makeObservable(this, {
      id: observable,
      originalValue: observable,
      dirtyValue: observable,
      errors: observable,
      validating: observable,
      setDirtyValue: action.bound,
      validate: action.bound,
    });

    this.validators = validators;
  }

  _init(id: string, _context: FormContext<V>, value: V) {
    this.id = id;
    this.originalValue = value;
    this.setDirtyValue(value);
  }

  getDirtyValue(): V | null {
    return this.dirtyValue;
  }

  setDirtyValue(v: V | null) {
    this.dirtyValue = v;
    this.changeHandlers.forEach((handler) => handler(v));
  }

  getOriginalValue(): V {
    return this.originalValue;
  }

  getErrors(): string[] {
    return this.errors;
  }

  hasErrors(): boolean {
    return this.errors.length > 0;
  }

  setErrors(errors: string[]) {
    this.errors = errors;
  }

  clearErrors() {
    this.errors = [];
  }

  clearChangeHandlers() {
    this.changeHandlers = [];
  }

  addChangeHandlers(handler: (v: V | null) => void) {
    this.changeHandlers.push(handler);
  }

  getValidators(): Validator<V, string>[] {
    if (Array.isArray(this.validators)) {
      return this.validators;
    } else {
      return this.validators();
    }
  }

  async validate() {
    try {
      this.validating = true;
      this.errors = (
        await Promise.all(this.getValidators().map((v) => v.validate(this.id, this.dirtyValue, this.context)))
      ).filter((v) => v) as string[];
    } finally {
      this.validating = false;
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormError<V> = { [key in keyof V]: any };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormSchema<V> = { [key in keyof V]: FormLike<V[key], any> };
export type SchemaFactory<S> = () => S;

export class FormStateV3<V = object, S extends FormSchema<V> = SimpleFormSchema<V>>
  implements FormLike<V, FormError<V>>
{
  id: string;
  originalValue: V = null;
  fields: S;
  context: FormContext;
  submitting = false;

  constructor(fields: S | SchemaFactory<S>) {
    makeObservable(this, {
      originalValue: observable,
      submitting: observable,
    });

    if (typeof fields === 'function') {
      this.fields = fields();
    } else {
      this.fields = fields;
    }
  }

  _init(id: string, context: FormContext, value: V) {
    this.id = id;
    this.context = context;
    this.originalValue = value;
    Object.keys(this.fields).forEach((key) => {
      const fieldId = id + '.' + key;
      this.fields[key]._init(fieldId, context, value[key]);
    });
  }

  clearErrors() {
    Object.keys(this.fields).forEach((key) => {
      this.fields[key].clearErrors();
    });
  }

  getDirtyValue(): V {
    return Object.keys(this.fields).reduce(
      (obj, key) => ({ ...obj, [key]: this.fields[key].getDirtyValue() }),
      this.originalValue
    );
  }

  getErrors(): FormError<V> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Object.keys(this.fields).reduce((obj, key) => ({ ...obj, [key]: this.fields[key].getErrors() }), {} as any);
  }

  getOriginalValue(): V {
    return this.originalValue;
  }

  hasErrors(): boolean {
    return Object.keys(this.fields).some((key) => this.fields[key].hasErrors());
  }

  setErrors(errors: FormError<V>) {
    Object.keys(this.fields).forEach((key) => this.fields[key].clearErrors());
    Object.keys(errors).forEach((key) => {
      if (this.fields[key]) {
        this.fields[key].setErrors(errors[key]);
      }
    });
  }

  async validate() {
    this.clearErrors();
    await Promise.all(Object.keys(this.fields).map((key) => this.fields[key].validate()));
  }

  async submitAsJson<T>(submitter: (value: V) => Promise<T>): Promise<T> {
    await this.validate();
    if (this.hasErrors()) {
      throw new Error('Unable to submit form when the form has errors');
    }
    try {
      this.submitting = true;
      return await submitter(this.getDirtyValue());
    } catch (e) {
      if (e instanceof ApiError && e.isValidationError()) {
        this.setErrors(e.body);
      }
      throw e;
    } finally {
      this.submitting = false;
    }
  }
}
