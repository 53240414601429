import { action, computed, observable, makeObservable } from 'mobx';
import { RemoteResourceV3, updateRemoteResourceV3 } from '../../commonv3/api/RemoteResourceV3';
import { WithdrawalApis } from '../../mainv3/apis/withdrawals';
import { SubmitState } from '../../commonv3/states/SubmitState';
import { MembershipPlanGroupKey } from '../../mainv3/types/membershipPlans';
import { Withdrawal } from '../../mainv3/types/withdrawal';
import autobind from 'autobind-decorator';

export class AccountWithdrawalState {
  initialized = false;
  initState = new SubmitState();
  withdrawalBase = RemoteResourceV3.loading();

  constructor() {
    makeObservable(this, {
      initialized: observable,
      initState: observable,
      withdrawalBase: observable,
      init: action.bound,
      reload: action.bound,
      withdrawals: computed,
      loading: computed,
      initializing: computed,
    });
  }

  async init() {
    await this.initState.operate(async () => {
      await this.reload();
    });
  }

  async reload() {
    await updateRemoteResourceV3(
      this.withdrawalBase,
      (v) => (this.withdrawalBase = v),
      () => WithdrawalApis.findAll()
    );
  }

  get withdrawals(): Withdrawal[] {
    return this.withdrawalBase.getOrElse([]);
  }

  @autobind
  isWithdrawalSet(membershipPlanGroup: MembershipPlanGroupKey) {
    return this.withdrawals.some((s) => s.membership_plan_group_key == membershipPlanGroup);
  }

  @autobind
  targetWithdrawal(membershipPlanGroup: MembershipPlanGroupKey) {
    return this.withdrawals.find((w) => w.membership_plan_group_key == membershipPlanGroup);
  }

  get loading() {
    return this.withdrawalBase.loading;
  }

  get initializing() {
    return this.initState.submitting;
  }
}
