import { observable, action, computed, makeObservable } from 'mobx';
import { SubmitState } from '../../../../commonv3/states/SubmitState';
import { CreditCardState } from '../../../../mainv3/components/molecules/payment/states/CreditCardFormState';
import { GlobalStatesV3 } from '../../../../commonv3/states/GlobalStatesV3';
import { translateErrorMessage } from '../../../../commonv3/api/protocol';
import { createContext, useContext } from 'react';
import { AccountRoutes } from '../../../routes/AccountRoutes';
import { PaymentListState, usePaymentList } from './PaymentListState';

export class PaymentInfoState {
  initialized = false;
  initState = new SubmitState();
  formState: CreditCardState = new CreditCardState(GlobalStatesV3.currentUser);
  submitState = new SubmitState();
  success = false;
  paymentListState: PaymentListState;

  constructor(paymentListState: PaymentListState) {
    makeObservable(this, {
      initialized: observable,
      initState: observable,
      formState: observable,
      submitState: observable,
      success: observable,
      init: action.bound,
      initializing: computed,
      validateCardInfo: action.bound,
      submitting: computed,
      submit: action.bound,
    });

    this.paymentListState = paymentListState;
  }

  async init() {
    try {
      this.success = false;
      await this.formState.init();
    } finally {
      this.initialized = true;
    }
  }

  get initializing() {
    return this.initState.submitting;
  }

  async validateCardInfo() {
    await this.formState.validateNewCard();
  }

  get submitting() {
    return this.submitState.submitting;
  }

  async submit() {
    await this.submitState.operate(async () => {
      try {
        await this.validateCardInfo();
      } catch (e) {
        return;
      }
      try {
        await this.formState.update();
        this.success = true;
        setTimeout(() => {
          GlobalStatesV3.routing.push(AccountRoutes.payments());
        }, 1000);
        await Promise.all([this.init(), this.paymentListState.init()]);
      } catch (e) {
        alert('お支払い情報の更新に失敗しました：' + translateErrorMessage(e));
      }
    });
  }
}

const PaymentInfoContext = createContext<{ value: PaymentInfoState | null }>({ value: null });

export const usePaymentInfo = (): PaymentInfoState => {
  const paymentListState = usePaymentList();
  const state = useContext(PaymentInfoContext);
  if (!state.value) {
    state.value = new PaymentInfoState(paymentListState);
    state.value.init();
  }
  return state.value;
};
