import { ImageUrlMap } from '../../mainv2/types/common';

type ImageParams = {
  src?: string;
  srcSet?: string;
};

export function expandImageParams(url: ImageUrlMap): ImageParams {
  if (url['1x'] && url['2x']) {
    return {
      src: url['1x'],
      srcSet: `${url['1x']} 1x, ${url['2x']} 2x`,
    };
  } else if (url['original']) {
    return {
      src: url['original'],
    };
  } else {
    return {};
  }
}
