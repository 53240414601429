import React from 'react';
import moment from 'moment-timezone';
import './subscriptionSummaryListView.module.scss';
import { MobBr, SpBr } from '../../../../../commonv3/components/atoms/layouts/br';
import { SubscriptionSummary } from '../../../../types/subscriptionSummary';
import { DateRange, CustomFormattedDateRange } from '../../../atoms/dates/DateRange';
import { SlashFormattedDate } from '../../../atoms/dates/Date';

const SubscriptionSummaryRow = ({
  title,
  children,
  className = undefined,
}: {
  title: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}) => (
  <div styleName="subscription-summary-row" className={className}>
    <div styleName="title">{title}</div>
    <div styleName="body">{children}</div>
  </div>
);

type Props = {
  summary: SubscriptionSummary;
  isWithdrawalSet: boolean;
};

export const SubscriptionSummaryView = ({ summary, isWithdrawalSet }: Props) => {
  const {
    subscribed,
    next_period_membership_item,
    next_period_membership_item_id,
    current_period_membership_item_id,
    suspended,
    future_suspension,
    subscription_suspension,
    next_payment_date,
    plan_item_price,
    plan_item_price_with_tax,
    chain_id,
    plan_name,
    starts_at,
    strict_expires_at,
    current_period_starts_at,
    next_period_starts_at,
  } = summary;
  // プラン開始前だったら true、プランが開始していたら false
  const future = moment(starts_at).isAfter(moment());
  // プラン変更を予約中かどうか
  const hasFutureChangePlan =
    next_period_membership_item != null && next_period_membership_item_id != current_period_membership_item_id;
  // 休会中かどうか
  const isSuspended = subscribed && suspended;
  // 休会予約が存在しているかどうか
  const hasFutureSuspension = subscribed && subscription_suspension && future_suspension;
  // 次回の支払い予定があるかどうか
  const hasNextPayment =
    subscribed &&
    !isWithdrawalSet &&
    !hasFutureChangePlan &&
    !hasFutureSuspension &&
    next_payment_date &&
    plan_item_price > 0;

  return (
    <>
      <div
        styleName="subscription-summary"
        className="subscription-summary-view"
        id={`subscription-summary-view-${chain_id}`}
      >
        {future ? (
          <div>
            <SubscriptionSummaryRow title={<span>ご利用中のプラン</span>}>
              <div data-value="current-plan" styleName="current-plan">
                {plan_name}
              </div>
            </SubscriptionSummaryRow>
            <SubscriptionSummaryRow title="サービス利用開始日" className="start-date-info">
              <SlashFormattedDate date={starts_at} />
            </SubscriptionSummaryRow>
          </div>
        ) : (
          <div>
            <SubscriptionSummaryRow title={<span>ご利用中のプラン</span>}>
              <div data-value="current-plan" styleName="current-plan">
                {plan_name}
                {isSuspended && (
                  <div>
                    <span styleName="suspension-status">休会中</span>
                  </div>
                )}
              </div>
            </SubscriptionSummaryRow>
            {hasFutureSuspension && (
              <SubscriptionSummaryRow title="休会予約" className="suspension-schedule-info">
                <div>
                  <span styleName="suspension-period">
                    <DateRange fromDate={subscription_suspension.starts_at} toDate={subscription_suspension.ends_at} />
                  </span>
                  での休会が予約されています
                </div>
              </SubscriptionSummaryRow>
            )}
            <SubscriptionSummaryRow title="現プランの受講可能期間" className="subscription-period-info">
              {strict_expires_at && current_period_starts_at && (
                <CustomFormattedDateRange fromDate={current_period_starts_at} toDate={strict_expires_at} />
              )}
              {!strict_expires_at && <SlashFormattedDate date={current_period_starts_at} />}
            </SubscriptionSummaryRow>
            {isSuspended && (
              <SubscriptionSummaryRow title="休会期間" className="suspension-info">
                <CustomFormattedDateRange
                  fromDate={subscription_suspension!.starts_at}
                  toDate={subscription_suspension!.ends_at}
                />
              </SubscriptionSummaryRow>
            )}
            {hasNextPayment && (
              <SubscriptionSummaryRow title="次回プラン更新" className="next-payment-info">
                <div>
                  <SlashFormattedDate date={next_payment_date} />に{plan_item_price_with_tax.toLocaleString()}
                  円（税込）
                  <SpBr />
                  を請求予定です。
                  <br />
                  <span styleName="red">※プランは自動更新となります</span>
                </div>
              </SubscriptionSummaryRow>
            )}
            {hasFutureChangePlan && (
              <SubscriptionSummaryRow title="プラン変更予約">
                <div styleName="update-schedule">
                  <div>
                    <b>
                      <SlashFormattedDate date={next_period_starts_at} />
                    </b>
                    に<b>{next_period_membership_item.name}</b>
                    <MobBr />
                    への変更が予約されています。
                  </div>
                </div>
              </SubscriptionSummaryRow>
            )}
          </div>
        )}
      </div>
    </>
  );
};
