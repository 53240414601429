import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useViewportState } from '../../../commonv3/hooks/useViewportState';
import SheLogoImg from './she.png';
import './navigation.module.scss';
import { HamburgerButton } from '../../../commonv3/components/atoms/buttons/HamburgerButton';
import { OverlayContent } from '../../../commonv3/components/molecules/layout/OverlayPage';
import { animated, useSpring } from 'react-spring';
import { SpUserInfo } from '../../../commonv3/components/organisms/navigation/SpUserInfo';
import { GlobalStatesV3 } from '../../../commonv3/states/GlobalStatesV3';
import { DesktopUserInfo } from '../../../commonv3/components/organisms/navigation/DesktopUserInfo';
import { Menu } from './Menu';

export const Logo = () => <img src={SheLogoImg} styleName="logo" id="she-logo" />;

export const NonSpNavigation = () => {
  return (
    <div styleName="desktop-navigation" id="non-sp-navigation">
      <Logo />
      <div styleName="user-info-wrapper">
        <DesktopUserInfo currentUser={GlobalStatesV3.currentUser} active={false} />
      </div>
    </div>
  );
};

type OverlayMenuProps = {
  open: boolean;
  onClick: () => void;
  setRef: React.RefObject<HTMLDivElement>;
};

const OverlayMenu: React.FC<OverlayMenuProps> = ({ open, setRef, children, onClick }) => {
  const props = useSpring({
    left: open ? 0 : -window.innerWidth * 0.6,
    opacity: open ? 1 : 0,
  });
  return (
    <animated.div styleName="menu" style={props} ref={setRef} onClick={onClick}>
      {children}
    </animated.div>
  );
};

export const SpNavigation = () => {
  const [open, setOpen] = useState(false);
  return (
    <div styleName="sp-navigation" id="sp-navigation">
      <div styleName="sp-navigation-header">
        <HamburgerButton color="dark" active={open} onClick={() => setOpen(!open)} styleName="hamburger-button" />
        <Logo />
        <SpUserInfo currentUser={GlobalStatesV3.currentUser} />
      </div>
      <OverlayContent
        open={open}
        onRequestClose={() => setOpen(false)}
        childElem={(_st, setRef) => (
          <OverlayMenu open={open} setRef={setRef} onClick={() => setOpen(false)}>
            <Menu />
          </OverlayMenu>
        )}
      />
    </div>
  );
};

export const Navigation = observer(() => {
  const viewport = useViewportState();
  return viewport.isSp ? <SpNavigation /> : <NonSpNavigation />;
});
