import queryString from 'querystring';

const generatePath = (path: string, search?: string): URL => {
  const fallback = location ? `${location.protocol}//${location.host}` : '';
  const origin = new URL(process.env.AUTH_BASE_URL || fallback);
  origin.pathname = path;
  origin.search = search || '';
  return origin;
};

export const SignInPath = (redirect_url?: string) =>
  generatePath('/sign_in', redirect_url ? queryString.stringify({ redirect_url }) : undefined);
