import { useState } from 'react';

export const useSubmitting = <T>(process: () => Promise<T>) => {
  const [submitting, setSubmitting] = useState(false);

  const submit = async () => {
    if (submitting) return;
    try {
      setSubmitting(true);
      return await process();
    } finally {
      setSubmitting(false);
    }
  };

  return { submitting, submit };
};
