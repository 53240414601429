import React, { useEffect } from 'react';
import './profileChangePage.module.scss';
import { Page, PageBody, PageHeader } from '../../components/layout/page';
import { ProfileChangePageState } from './states/ProfileChangePageState';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { FormInput } from '../../../mainv3/components/atoms/forms/FormInput';
import { GlobalLoader } from '../../../mainv2/components/GlobalLoader';
import { NotFoundPage } from '../../../mainv2/containers/common/NotFoundPage';
import DefaultUserProfile from './default-user-profile.png';
import { Button } from '../../../commonv3/components/atoms/buttons/Button';

export const ProfileChangePage = observer(() => {
  const { pageState } = useLocalObservable(() => ({ pageState: new ProfileChangePageState() }));

  useEffect(() => {
    pageState.init();
  }, []);

  const { submitting, birthdayPersisted, formState, currentProfile, newProfileUrl, setProfileImage, submit } =
    pageState;
  if (currentProfile.loading) {
    return <GlobalLoader />;
  }
  if (currentProfile.isNotFound()) {
    return <NotFoundPage />;
  }

  const profileSrc = newProfileUrl || currentProfile.getOrThrow().profile_url;

  return (
    <Page>
      <PageHeader>プロフィールを編集</PageHeader>
      <PageBody>
        <div styleName="form-group">
          <img src={profileSrc || DefaultUserProfile} styleName={'profile-image'} />
          <div styleName="image-form-input-wrapper">
            <label styleName="image-form-input">
              写真を変更する
              <input type="file" accept="image/*" onChange={(e) => setProfileImage(e.target.files)} />
            </label>
          </div>
        </div>
        <div styleName="form-group">
          <div styleName="form-label">会員ID</div>
          <FormInput
            styleName="id"
            name="id"
            value={formState.getValue('id')}
            placeholder=""
            onChange={() => undefined}
            className="account-page-form-input"
            disabled={true}
          />
        </div>
        <div styleName="form-group">
          <div styleName="form-label">表示名</div>
          <FormInput
            styleName="name"
            name="name"
            value={formState.getValue('name')}
            placeholder=""
            onChange={(e) => formState.setValue('name', e.target.value)}
            error={formState.getError('name')}
            className="account-page-form-input"
          />
        </div>
        <div styleName="form-group">
          <div styleName="form-label">生年月日</div>
          <FormInput
            styleName="birthday"
            type="date"
            name="birthday"
            value={formState.getValue('birthday')}
            placeholder=""
            disabled={birthdayPersisted}
            onChange={(e) => formState.setValue('birthday', e.target.value)}
            className="account-page-form-input"
          />
        </div>
        <div styleName="form-group">
          <div styleName="form-label">会社名</div>
          <FormInput
            styleName="job-company"
            name="job_company"
            value={formState.getValue('job_company')}
            placeholder=""
            onChange={(e) => formState.setValue('job_company', e.target.value)}
            error={formState.getError('job_company')}
            className="account-page-form-input"
          />
        </div>
        <div styleName="form-group">
          <div styleName="form-label">部署</div>
          <FormInput
            styleName="job-department"
            name="job_department"
            value={formState.getValue('job_department')}
            placeholder=""
            onChange={(e) => formState.setValue('job_department', e.target.value)}
            error={formState.getError('job_department')}
            className="account-page-form-input"
          />
        </div>
        <div styleName="form-group">
          <div styleName="form-label">職種</div>
          <FormInput
            styleName="job-title"
            name="job_title"
            value={formState.getValue('job_title')}
            placeholder=""
            onChange={(e) => formState.setValue('job_title', e.target.value)}
            error={formState.getError('job_title')}
            className="account-page-form-input"
          />
        </div>
        <div styleName="form-submit-button">
          <Button
            id="profile-change-submit"
            type="primary"
            size="m"
            styleName="button"
            loading={submitting}
            onClick={() => submit()}
          >
            保存する
          </Button>
        </div>
      </PageBody>
    </Page>
  );
});
