import React from 'react';
import './notFoundPage.module.scss';
import { V3Routes } from '../../../mainv3/routes';
import { Link } from 'react-router-dom';

type Props = {
  entityType?: string;
};

export const NotFoundPage = ({ entityType = 'ページ' }: Props) => (
  <div styleName="not-found-page" id="not-found-page">
    <div styleName="message-box">
      お探しの{entityType}は見つかりませんでした。 URLをご確認ください。
      <br />
      <br />
      <Link to={V3Routes.home()} styleName="go-home-link">
        ホームに戻る
      </Link>
    </div>
  </div>
);
