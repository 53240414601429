import { LocationDescriptorObject } from 'history';
import { EnhancedLocationDescriptor } from '../../mainv3/routes';
import { GlobalStatesV3 } from '../../commonv3/states/GlobalStatesV3';
import * as queryString from 'querystring';

/**
 * アカウント用のルーティングパスを生成する
 * 環境によってページがホストされるパスが変わるので、環境によらず正しいパスを返すようにする。
 * 例：プロフィールページのパス
 * - staging, production : account.shelikes.jp/profile
 * - local: localhost:3000/account/profile
 * @param path
 * @param params
 */
function accountPath(path: string, params: Partial<LocationDescriptorObject> = {}): EnhancedLocationDescriptor {
  const pathname = GlobalStatesV3.routing.location.pathname.startsWith('/account') ? `/account/${path}` : `/${path}`;
  return {
    pathname,
    stringify() {
      const search = this.search ? `?${this.search}` : '';
      const hash = this.hash ? this.hash : '';
      return `${pathname}${hash}${search}`;
    },
    toAbsolute() {
      return `${process.env.ACCOUNT_BASE_URL || location.origin}${this.stringify()}`;
    },
    ...params,
  };
}

export const AccountRoutes = {
  homeLocation() {
    return accountPath('home_location');
  },
  plan() {
    return accountPath('plans');
  },
  payments() {
    return accountPath('payments');
  },
  paymentInfo() {
    return accountPath('payment_info');
  },
  password() {
    return accountPath('password');
  },
  email() {
    return accountPath('email');
  },
  profile() {
    return accountPath('profile');
  },
  reservations() {
    return accountPath('reservations');
  },
  attendance() {
    return accountPath('attendance');
  },
  eventReservationAttendanceComplete(eventReservationId: number) {
    return accountPath(`event_reservations/${eventReservationId}/attendance/complete`);
  },
  eventReservationAttendanceCompleteRoute() {
    return accountPath(`event_reservations/:eventReservationId/attendance/complete`);
  },
  reservationAttendanceComplete(reservationId: number) {
    return accountPath(`reservations/${reservationId}/attendance/complete`);
  },
  reservationAttendanceCompleteRoute() {
    return accountPath(`reservations/:reservationId/attendance/complete`);
  },
  signOut() {
    return accountPath('sign_out');
  },
  signIn(redirect_url = undefined) {
    return accountPath('sign_in', {
      search: redirect_url ? queryString.stringify({ redirect_url }) : undefined,
    });
  },
  forgotPassword() {
    return accountPath(`passwords/forgot`);
  },
  validateResetCode() {
    return accountPath(`passwords/reset_code`);
  },
  resetPassword() {
    return accountPath(`passwords/reset`);
  },
  address() {
    return accountPath('address');
  },
  retryPayment(code: string) {
    return accountPath(`retry_payment/${code}`);
  },
};
