import { GlobalStatesV3 } from '../../commonv3/states/GlobalStatesV3';
import { SheEnv } from '../../commonv3/utils/envs';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

declare const SENTRY_RELEASE: string;

export class SheRavenClient {
  static init() {
    // Initialize Sentry
    Sentry.init({
      dsn: 'https://f79fd165f638437880b6b17495c1c27c@o145555.ingest.sentry.io/5657338',
      integrations: [new Integrations.BrowserTracing()],
      beforeSend(event) {
        return SheEnv.isProduction() || SheEnv.isStaging() ? event : null;
      },
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
      release: SENTRY_RELEASE,
      environment: SheEnv.get(),
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'ログインしてください',
        'UNAUTHORIZED',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
      ],
      denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Sentry.setUser(GlobalStatesV3.currentUser.currentUser || ({} as any));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static captureException(e: Error, errorContext?: Record<string, any>) {
    Sentry.setExtras(errorContext);
    Sentry.captureException(e);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static setUserContext(context: Record<string, any>) {
    Sentry.setUser(context);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static context(wrapper: (scope) => any) {
    Sentry.withScope(wrapper);
  }
}
