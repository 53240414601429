import React, { FC } from 'react';
import { Page, PageBody, PageHeader } from '../../components/layout/page';
import { ReservationListState, useReservationListState } from './states/ReservationListState';
import { groupByDate, Reservable, ReservableType } from '../../../commonv3/types/reservable';
import { observer } from 'mobx-react';
import { DateGroup } from '../../../mainv3/components/molecules/reservable/DateGroup';
import { ScheduleListItem } from '../../../mainv3/components/molecules/reservable/ScheduleListItem';
import { Button } from '../../../commonv3/components/atoms/buttons/Button';
import { LoaderRow } from '../../../mainv3/components/atoms/layout/LoaderRow';
import { TrialLessonCancellationPolicy } from '../../../mainv3/components/molecules/reservations/TrialLessonCancellationPolicy';
import { TrialLessonIndexView } from '../../../mainv3/types/trialLessons';
import { ReservationCancelSurveyShowPage } from '../../../mainv3/containers/surveys/ReservationCancelSurveyPage';
import { OverlayPage } from '../../../commonv3/components/molecules/layout/OverlayPage';
import { CancelStep } from '../../../commonv3/types/cancel';
import { ModalDialog } from '@sheinc/shelikes-design-system';

type PolicyProps = {
  reservable: Reservable;
  reservableType: ReservableType;
};

const CancellationPolicy: FC<PolicyProps> = ({ reservable, reservableType }) => {
  switch (reservableType) {
    case ReservableType.TRIAL_LESSON:
      return (
        <TrialLessonCancellationPolicy
          showRescheduleInformation={true}
          showMypageLink={false}
          // @ts-expect-error ts2352
          lesson={reservable as TrialLessonIndexView}
        />
      );
    default:
      return null;
  }
};

type DialogProps = {
  reservationList: ReservationListState;
};

const CancelDialogs = observer(({ reservationList }: DialogProps) => {
  const { cancelDialog, cancelStep, cancellingReservation, processingState, confirmCancel } = reservationList;
  switch (cancelStep) {
    case CancelStep.CONFIRMATION:
      return (
        <ModalDialog
          testId="cancel-confirmation"
          title="本当にキャンセルしますか？"
          confirmationButtonLabel="キャンセルする"
          confirmationButtonVariant="danger"
          open={cancelDialog.isOpen}
          onClickCancel={cancelDialog.close}
          onClose={cancelDialog.close}
          showCancelButton={true}
          loadingConfirmationButton={processingState.submitting}
          disabledConfirmationButton={processingState.submitting}
          disabledCancelButton={processingState.submitting}
          cancelButtonLabel="戻る"
          onClickConfirm={confirmCancel}
        >
          {cancellingReservation && (
            <CancellationPolicy
              // @ts-expect-error ts2322
              reservable={cancellingReservation.reservable}
              reservableType={cancellingReservation.reservable_type}
            />
          )}
        </ModalDialog>
      );
    case CancelStep.FORM:
      return (
        <OverlayPage id="cancel-form" open={cancelDialog.isOpen} onRequestClose={cancelDialog.close}>
          <ReservationCancelSurveyShowPage
            reservationId={reservationList.cancellingReservationId as number}
            reservationListState={reservationList}
          />
        </OverlayPage>
      );
    case CancelStep.COMPLETE:
      return (
        <ModalDialog
          testId="cancel-complete"
          title="予約キャンセルが完了しました"
          confirmationButtonLabel="閉じる"
          confirmationButtonVariant="secondary"
          open={cancelDialog.isOpen}
          onClose={cancelDialog.close}
          loadingConfirmationButton={processingState.submitting}
          onClickConfirm={cancelDialog.close}
          showCancelButton={false}
        />
      );
  }
  return null;
});

export const ReservationListPage = observer(() => {
  const state = useReservationListState();
  const groups = groupByDate(state.allReservationList, (r) => r.reservable.starts_at);
  const { processingState, requestCancel } = state;
  return (
    <Page>
      <PageHeader>予約の確認</PageHeader>
      <PageBody>
        {state.loading && <LoaderRow />}
        {groups.map((g) => {
          return (
            <DateGroup key={g.date} date={g.date}>
              {g.entries.map((entry) => {
                return (
                  <ScheduleListItem
                    id={`reservation-item-${entry.reservable_type}-${entry.reservable.id}`}
                    key={entry.id}
                    title={entry.reservable.title}
                    // @ts-expect-error ts2769
                    reservable={entry.reservable}
                    reservableType={entry.reservable_type}
                    showZoomLink={true}
                    actionItem={
                      <Button
                        type="normal"
                        size="m"
                        disabled={processingState.submitting}
                        id={`cancel-button-${entry.reservable_type}-${entry.reservable.id}`}
                        onClick={() => requestCancel(entry)}
                      >
                        キャンセル
                      </Button>
                    }
                    showServiceLabel={true}
                  />
                );
              })}
            </DateGroup>
          );
        })}
        <CancelDialogs reservationList={state} />
      </PageBody>
    </Page>
  );
});
