import { FacebookAnalyticsClient } from './../../commonv2/modules/facebookAnalytics';
import { MainV3ApiBase } from '../../mainv3/apis/base';

type Payload = {
  event_id: string;
  event_name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  custom_data?: { [key: string]: any };
  test_event_code?: string;
  event_source_url: string;
};

export const FacebookApis = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async send(params: Payload): Promise<any> {
    return await MainV3ApiBase.appendPath('facebook_api').post(params);
  },
};

export const exposeFbq = () => {
  // @ts-expect-error ts7015
  window['fbqWithConversion'] = (
    event: string,
    eventName: string,
    customData: Record<string, unknown>,
    testEventCode: string
  ) => FacebookAnalyticsClient.sendFacebookEvent(event, eventName, customData, testEventCode);
};
