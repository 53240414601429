import { MainV3ApiBase } from './base';
import { Token } from '../types/tokens';
import { Service } from '../../commonv3/types/service';

export const AvailableTokenApis = {
  base() {
    return MainV3ApiBase.appendPath('users/current/available_tokens');
  },
  findAll(current_service?: Service): Promise<Token[]> {
    return this.base().get({ current_service: current_service });
  },
};
