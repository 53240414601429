import { action, computed, observable, makeObservable } from 'mobx';
import { SecureUser, V3MembershipType } from '../types/user';
import { UserApis } from '../api';
import { TokenListState } from '../../mainv3/types/tokens';
import { AvailableTokenApis } from '../../mainv3/apis/tokens';
import { SubmitState } from '../../commonv3/states/SubmitState';
import { UserPreferenceState } from './UserPreferenceState';
import { PaymentApis } from '../../mainv3/apis/payments';
import { PaymentIndexView } from '../../mainv3/types/payments';
import { updateRemoteResourceV3, RemoteResourceV3 } from '../../commonv3/api/RemoteResourceV3';
import { HomeLocationState } from '../../commonv3/states/HomeLocationState';
import { WithdrawalState } from '../../commonv3/states/WithdrawalsState';
import autobind from 'autobind-decorator';
import { MembershipPlanGroupKey } from '../../mainv3/types/membershipPlans';
import { Service } from '../../commonv3/types/service';
import { diffInMonths } from '../../commonv3/utils/date';

declare const initialAppState: {
  currentUser: SecureUser | null;
  enabledFlagKeys: string[];
  activeVariantKeys: string[];
};

export class CurrentUserState {
  loadState: SubmitState = new SubmitState();
  currentUser: SecureUser = initialAppState.currentUser;
  availableTokens: TokenListState = new TokenListState();
  homeLocation: HomeLocationState = new HomeLocationState();
  withdrawal: WithdrawalState = new WithdrawalState();
  payments: RemoteResourceV3<PaymentIndexView[]> = RemoteResourceV3.loading();

  constructor() {
    makeObservable(this, {
      loadState: observable,
      currentUser: observable,
      availableTokens: observable,
      homeLocation: observable,
      withdrawal: observable,
      payments: observable,
      reloadUser: action,
      preference: computed,
      enabledFlagKeys: computed,
      activeVariantKeys: computed,
      loading: computed,
      userId: computed,
      failedTransactions: computed,
      hasFailedTransactions: computed,
      isDeactivated: computed,
      updateCurrentUser: action,
    });
  }

  getOrThrow() {
    if (!this.currentUser) {
      throw new Error('User must be signed in');
    }
  }

  /**
   * Look up value for the current user.
   * Intended for reliable look up of user values regardless whether the user is signed in or not
   * @param key the key to look up (field of SecureUser)
   * @param defaultValue the default value
   */
  @autobind
  getValue<K extends keyof SecureUser>(key: keyof SecureUser, defaultValue: SecureUser[K]): SecureUser[K] {
    if (!this.currentUser) {
      return defaultValue;
    }
    return this.currentUser[key];
  }

  async reloadUser(): Promise<SecureUser | null> {
    await this.loadState.operate(async () => {
      this.currentUser = await UserApis.getCurrentUser();
      if (this.currentUser) {
        await Promise.all([
          await updateRemoteResourceV3(
            this.payments,
            (v) => (this.payments = v),
            () => PaymentApis.findAll([Service.LIKES])
          ),
          this.availableTokens.init(await AvailableTokenApis.findAll(Service.LIKES)),
          this.homeLocation.init(),
          this.withdrawal.init(),
        ]);
      } else {
        this.availableTokens.init([]);
      }
    });
    return this.currentUser;
  }

  get preference(): UserPreferenceState {
    if (!this.currentUser) {
      return new UserPreferenceState({});
    }
    return new UserPreferenceState(this.currentUser.user_preference);
  }

  /**
   * このユーザに対して現在有効なFlagのキーの一覧を返す
   */
  get enabledFlagKeys(): string[] {
    if (!initialAppState.enabledFlagKeys) {
      return [];
    }
    return initialAppState.enabledFlagKeys;
  }

  get activeVariantKeys(): string[] {
    return initialAppState.activeVariantKeys;
  }

  hasEnabledFlagKey(key: string): boolean {
    return this.enabledFlagKeys.includes(key);
  }

  hasActiveVariant(flagKey: string, value: string) {
    return this.activeVariantKeys.includes(`${flagKey}-${value}`);
  }

  get loading(): boolean {
    return this.loadState.submitting;
  }

  get userId() {
    return this.currentUser ? this.currentUser.id : null;
  }

  isSignedIn(): boolean {
    return this.currentUser != null;
  }

  isMember(): boolean {
    return this.isSignedIn() && this.currentUser.v2_member;
  }

  isV3Member(): boolean {
    return this.isSignedIn() && this.currentUser.v3_member;
  }

  isLikesUser(): boolean {
    return this.isSignedIn() && this.currentUser.membership_plan_group_keys.includes(MembershipPlanGroupKey.LIKES);
  }

  isMultiCreatorUser(): boolean {
    return (
      this.isSignedIn() && this.currentUser.membership_plan_group_keys.includes(MembershipPlanGroupKey.MULTICREATOR)
    );
  }

  isEffectiveMultiCreatorUser(): boolean {
    return this.isSignedIn() && this.currentUser.has_effective_multicretor_membership_token;
  }

  hasEffectiveMembershipToken(): boolean {
    return this.isSignedIn() && this.currentUser.has_effective_membership_token;
  }

  isV3StandardMember(): boolean {
    return this.isSignedIn() && this.currentUser.v3_membership_type == V3MembershipType.STANDARD;
  }

  isV3TrialMember(): boolean {
    return this.isSignedIn() && this.currentUser.v3_membership_type == V3MembershipType.TRIAL;
  }

  isNonMember(): boolean {
    return !this.isMember();
  }

  hasCard(): boolean {
    return this.isSignedIn() && !!this.currentUser.payment_info;
  }

  hasLikesActiveSubscription(): boolean {
    return this.isSignedIn() && this.currentUser.has_likes_active_subscription;
  }

  get failedTransactions(): PaymentIndexView[] {
    return this.payments.getOrElse([]).filter((p) => p.status === 'error');
  }

  get hasFailedTransactions() {
    return this.failedTransactions.length > 0;
  }

  get isDeactivated(): boolean {
    return this.failedTransactions.find((p) => diffInMonths(p.due_date) >= 1) != null;
  }

  /**
   * テストのためのメソッド。
   * テストの実行時に`initialAppState.currentUser`をモックすることが難しいため、このメソッドを使用する。
   *
   * FIXME: `initialAppState.currentUser`をモックしやすいように`GlobalStatesV3`などの構造を修正する。
   */
  updateCurrentUser(currentUser: Partial<SecureUser> | null) {
    this.currentUser = { ...this.currentUser, ...currentUser };
  }
}
