import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useFormErrors } from './useFormErrors';

export const useFormSubmit = <V extends FieldValues, T>(
  form: UseFormReturn<V>,
  process: (values: V) => Promise<T>,
  option: {
    validate?: () => Promise<boolean>;
  } = {}
) => {
  const { setFormErrors, hasErrors } = useFormErrors(form);

  const submit = async () => {
    form.clearErrors();

    if (option.validate) {
      const invalid = await option.validate();
      if (invalid) throw new Error('Unable to submit form when the form has errors');
    }

    try {
      const values = form.getValues();
      return await process(values);
    } catch (e) {
      setFormErrors(e);
    }
  };

  return { submit, setFormErrors, hasErrors };
};
