import React from 'react';
import { MultiLineFreeAnswerQuestionState } from './states/QuestionState';
import { QuestionCard } from '../../molecules/surveys/QuestionCard';
import { observer } from 'mobx-react-lite';
import { MultiLineStringInput } from '../../../../commonv3/components/atoms/forms/primitive/StringInput';
import './multiLineFreeAnswerCard.module.scss';
import { SurveyQuestionFormat } from '../../../../commonv3/types/surveys';

type Props = {
  questionNumber: number;
  totalQuestions: number;
  questionState: MultiLineFreeAnswerQuestionState;
  format?: SurveyQuestionFormat;
};

export const MultiLineFreeAnswerCard = observer(({ questionNumber, totalQuestions, questionState, format }: Props) => {
  const { question, value, setValue, required, errorMessage, validate } = questionState;

  return (
    <QuestionCard
      questionNumber={questionNumber}
      totalQuestions={totalQuestions}
      sentence={question.sentence}
      subSentence={question.sub_sentence}
      youtubeKey={question.youtube_key}
      required={required}
      errorMessage={errorMessage}
      onBlur={validate}
      format={format}
    >
      <div styleName="content">
        <MultiLineStringInput
          id={`textarea-${questionNumber}`}
          styleName="string-input"
          value={value || undefined}
          onChange={setValue}
        />
      </div>
    </QuestionCard>
  );
});
