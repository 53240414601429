import React from 'react';
import classNames from 'classnames';
import './hamburgerButton.module.scss';

type Props = {
  color: 'white' | 'dark';
  active: boolean;
  onClick?: () => void;
  className?: string;
};

export const HamburgerButton = ({ active, color, onClick, className }: Props) => (
  <div
    id="hamburger-button"
    styleName={classNames('hamburger-button', color, { open: active })}
    className={className}
    onClick={onClick}
  >
    <span />
    <span />
    <span />
  </div>
);
