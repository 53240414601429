import { MainV3ApiBase } from './base';
import { LineName, WithdrawalId } from '../../commonv3/types/withdrawals';
import { Withdrawal } from '../types/withdrawal';
import { SurveySubmissionId } from '../../commonv3/types/survey_submissions';
import { ISODate } from '../../commonv3/types/dates';
import { SurveyKey } from '../../commonv3/types/surveys';

export const WithdrawalApis = {
  base() {
    return MainV3ApiBase.appendPath('users/current/withdrawals');
  },
  findAll(): Promise<Withdrawal[]> {
    return this.base().get();
  },
  create(
    lineName: LineName,
    effectiveAt: ISODate,
    surveyKey: SurveyKey,
    parameters: Record<string, unknown>,
    answers: Record<string, unknown>
  ) {
    return this.base().post({
      line_name: lineName,
      effective_at: effectiveAt,
      survey_key: surveyKey,
      parameters,
      answers,
    });
  },
  update(withdrawalId: WithdrawalId, surveySubmissionId: SurveySubmissionId) {
    return this.base().withId(withdrawalId).put({
      survey_submission_id: surveySubmissionId,
    });
  },
  cancel(withdrawalId: WithdrawalId) {
    return this.base().withId(withdrawalId).put({
      status: 'cancelled',
    });
  },
};
