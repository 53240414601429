import React, { useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { LoaderRow } from '../../components/atoms/layout/LoaderRow';
import { authenticated } from '../../components/hocs/authenticated';
import { ReservationCancelSurveyPageState } from '../surveys/states/ReservationCancelSurveyPageState';
import { QuestionCardOneQuestion } from './QuestionCard';
import { Button } from '../../../commonv3/components/atoms/buttons/Button';
import './reservationCancelSurvey.module.scss';
import { NotFoundPage } from '../../../mainv2/containers/common/NotFoundPage';
import { ReservationListState } from '../../components/organisms/home/states/ReservationListState';
import { ReservationListState as AccountReservationListState } from '../../../account/containers/reservation/states/ReservationListState';

type Props = {
  reservationId: number | null;
  reservationListState: ReservationListState | AccountReservationListState;
};

export const ReservationCancelSurveyShowPage = authenticated(
  observer(({ reservationId, reservationListState }: Props) => {
    const { pageState } = useLocalObservable(() => ({ pageState: new ReservationCancelSurveyPageState() }));

    const init = async () => {
      await pageState.init(reservationListState);
      pageState.reservationId = reservationId;
    };

    useEffect(() => {
      init();
    }, []);

    const { submit } = pageState;
    const { initialized, questions, getQuestionStateFor, nextQuestion, prevQuestion, currentQuestionNumber, survey } =
      pageState.surveyState;

    if (!initialized) {
      return <LoaderRow />;
    }

    if (survey.isEmpty()) {
      return <NotFoundPage entityType="体験レッスンキャンセルフォーム" />;
    }

    const q = questions[currentQuestionNumber - 1];
    const questionState = getQuestionStateFor(q.survey_question_id);
    const totalQuestions = questions.length;
    return (
      <>
        <QuestionCardOneQuestion
          questionNumber={currentQuestionNumber}
          totalQuestions={totalQuestions}
          question={q.survey_question}
          questionState={questionState}
          format="one_by_one"
        />
        <div styleName="button-box">
          {currentQuestionNumber !== 1 && (
            <Button
              type="normal"
              size="l"
              disabled={false}
              onClick={prevQuestion}
              id={`back-button-${currentQuestionNumber}`}
              styleName="fixed-button fixed-button__prev"
            >
              前に戻る
            </Button>
          )}
          {currentQuestionNumber !== totalQuestions && (
            <Button
              type="primary"
              size="l"
              disabled={questionState.required && !questionState.value}
              onClick={nextQuestion}
              id={`next-button-${currentQuestionNumber}`}
              styleName="fixed-button"
            >
              次に進む
            </Button>
          )}
          {currentQuestionNumber === totalQuestions && (
            <Button
              id="submit-cancel-button"
              styleName="fixed-button"
              type="primary"
              size="l"
              disabled={questionState.required && !questionState.value}
              onClick={submit}
            >
              キャンセルする
            </Button>
          )}
        </div>
      </>
    );
  })
);
