import React from 'react';
import moment from 'moment-timezone';
import classNames from 'classnames';
import './LegacyPaymentView.module.scss';
import { PaymentIndexView, PaymentShowView } from '../../../types/payments';
import { routingStore } from '../../../../common/stores/routing';
import { V3Routes } from '../../../routes';
import { Alert, AlertContent, BodyText, Button, LabelText } from '@sheinc/shelikes-design-system';
import { GlobalStatesV3 } from '../../../../commonv3/states/GlobalStatesV3';
import { LegacyPaymentStatusView } from './LegacyPaymentStatusView';
import { LegacyPaymentMethodView } from './LegacyPaymentMethodView';
import { AccountRoutes } from '../../../../account/routes/AccountRoutes';

type Props = {
  payment: PaymentIndexView | PaymentShowView;
  showAction?: boolean;
  isHideBilling?: boolean;
  retry_payment_route?: 'mypage' | 'account';
};

/**
 * @deprecated REST API に依存したコンポーネントです。GraphQL APIと使用する場合は PaymentView を使用してください。
 */
export class LegacyPaymentView extends React.Component<Props> {
  render() {
    const isSp = GlobalStatesV3.viewport.isSp;

    const { payment, showAction = true, isHideBilling = false, retry_payment_route = 'mypage' } = this.props;
    const Notes = (
      <div styleName="alert">
        <Alert icon={true}>
          <AlertContent
            description={
              <>
                以下の条件が全て当てはまる場合、再支払いと同時にSHEポイントが受講料割引として利用されます。
                <br />
                ・お支払方法を「クレジットカードでの支払い」に設定している
                <br />
                ・SHEポイント合計が0ではない
              </>
            }
          ></AlertContent>
        </Alert>
      </div>
    );

    const BankPaymentNote = (
      <div styleName="alert">
        <Alert icon={true}>
          <AlertContent
            description={<>銀行振込でのお支払いがお済みの場合は、SHElikes公式LINEへご連絡ください</>}
          ></AlertContent>
        </Alert>
      </div>
    );

    return (
      <div styleName="payment-history" className="payment-history">
        {(payment.status == 'unpaid' || payment.status == 'error') && payment.payment_method == 'transfer' && (
          <>{BankPaymentNote}</>
        )}
        {payment.status == 'error' && <>{Notes}</>}
        <div styleName="payment-history-inner">
          <div styleName="col title">
            <BodyText size="M">{payment.order_title}</BodyText>
          </div>
          <div styleName={classNames('col detail')}>
            <table>
              <tbody>
                <tr>
                  <th>
                    <LabelText color="lightest" size="S">
                      決済状況
                    </LabelText>
                  </th>
                  <td>
                    <LegacyPaymentStatusView paymentStatus={payment.status} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <LabelText color="lightest" size="S">
                      支払い日
                    </LabelText>
                  </th>
                  <td>
                    <LabelText size="M">{moment(payment.paid_at).format('YYYY/MM/DD')}</LabelText>
                  </td>
                </tr>
                <tr>
                  <th>
                    <LabelText color="lightest" size="S">
                      お支払方法
                    </LabelText>
                  </th>
                  <td>
                    <BodyText lineHeight="tight" size="S">
                      <LegacyPaymentMethodView paymentMethod={payment.payment_method} />
                    </BodyText>
                  </td>
                </tr>
                <tr>
                  <th>
                    <LabelText color="lightest" size="S">
                      金額
                    </LabelText>
                  </th>
                  <td>
                    <LabelText size="M">
                      {payment.amount.toLocaleString('ja-JP', {
                        style: 'currency',
                        currency: 'JPY',
                      })}
                    </LabelText>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {showAction && (
            <div styleName="col action">
              {payment.status == 'paid' && !isHideBilling && (
                <Button
                  variant="secondary"
                  size="small"
                  onClick={() => routingStore.push(V3Routes.generate_receipt(payment.code))}
                  fullWidth={isSp}
                >
                  領収書
                </Button>
              )}
              {payment.status == 'error' && (
                <Button
                  variant="danger"
                  size="small"
                  onClick={() =>
                    routingStore.push(
                      retry_payment_route === 'account'
                        ? AccountRoutes.retryPayment(payment.code)
                        : V3Routes.retry_payment(payment.code)
                    )
                  }
                  fullWidth={isSp}
                >
                  再支払いする
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
