import React from 'react';
import { observer } from 'mobx-react';
import { PaymentStatus, PaymentShowViewStatus } from '../../../types/payments';
import { StatusLabel } from '@sheinc/shelikes-design-system';

type PaymentStatusViewProps = {
  paymentStatus: PaymentStatus | PaymentShowViewStatus;
};

/**
 * @deprecated REST API に依存したコンポーネントです。GraphQL APIと使用する場合は PaymentStatusView を使用してください。
 */
export const LegacyPaymentStatusView = observer(({ paymentStatus }: PaymentStatusViewProps) => {
  switch (paymentStatus) {
    case 'paid':
      return (
        <StatusLabel color="success" size="S">
          決済完了
        </StatusLabel>
      );
    case 'unpaid':
      return (
        <StatusLabel color="defaultLight" size="S">
          決済予定
        </StatusLabel>
      );
    case 'refunded':
      return (
        <StatusLabel color="successLight" size="S">
          返金済み
        </StatusLabel>
      );
    case 'error':
      return (
        <StatusLabel color="danger" size="S">
          決済エラー
        </StatusLabel>
      );
    case 'canceled':
      return (
        <StatusLabel color="defaultLight" size="S">
          キャンセル済み
        </StatusLabel>
      );
    default:
      return (
        <StatusLabel color="defaultLight" size="S">
          不明
        </StatusLabel>
      );
  }
});
