// FIXME:
// mobx-react-router の内部で使われている mobx のバージョンが古いため、
// 元の実装（https://github.com/alisd23/mobx-react-router）を参考に再実装している。
// 将来的に MobX を使い続ける場合は、このプロジェクトで使っている react-router の
// バージョンを上げた上で @superwf/mobx-react-router を使うなどの対応が望ましい。

import { observe, observable, action, makeObservable } from 'mobx';
import type { History, Location, UnregisterCallback, LocationListener, LocationDescriptorObject } from 'history';

export class RouterStore {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  location: Location = null!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  history: History = null!;

  constructor() {
    makeObservable(this, {
      location: observable,
      _updateLocation: action,
      push: action.bound,
      replace: action.bound,
      go: action.bound,
      goBack: action.bound,
      goForward: action.bound,
    });
  }

  _updateLocation(newState: Location): void {
    this.location = newState;
  }

  /*
   * History methods
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  push(location: string | LocationDescriptorObject, state?: any): void {
    // @ts-expect-error ts2345
    this.history.push(location, state);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  replace(location: string | LocationDescriptorObject, state?: any): void {
    // @ts-expect-error ts2345
    this.history.replace(location, state);
  }
  go(n: number): void {
    this.history.go(n);
  }
  goBack(): void {
    this.history.goBack();
  }
  goForward(): void {
    this.history.goForward();
  }
}

interface SynchronizedHistory extends History {
  subscribe: (listener: LocationListener) => UnregisterCallback;
  unsubscribe?: UnregisterCallback;
}

export const syncHistoryWithStore = (history: History, store: RouterStore): SynchronizedHistory => {
  // Initialize store
  store.history = history;

  // Handle update from history object
  const handleLocationChange = (location: Location): void => {
    store._updateLocation(location);
  };

  const unsubscribeFromHistory = history.listen(handleLocationChange);
  handleLocationChange(history.location);

  const subscribe = (listener: LocationListener): UnregisterCallback => {
    const onStoreChange = (): void => {
      const rawLocation = { ...store.location };
      listener(rawLocation, history.action);
    };

    // Listen for changes to location state in store
    const unsubscribeFromStore = observe(store, 'location', onStoreChange);

    listener(store.location, history.action);

    return unsubscribeFromStore;
  };

  // @ts-expect-error ts2339
  history.subscribe = subscribe;
  // @ts-expect-error ts2339
  history.unsubscribe = unsubscribeFromHistory;

  return history as SynchronizedHistory;
};
