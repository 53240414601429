import React from 'react';
import classNames from 'classnames';
import SatisfactionCheck from './images/satisfaction-check.svg';
import './satisfactionSelectInput.module.scss';
import { Button } from '../../../../commonv3/components/atoms/buttons/Button';

const levels = [1, 2, 3, 4, 5] as const;
type Level = typeof levels[number];

export const SatisfactionCircle = ({
  checked,
  level,
  onCheck,
}: {
  checked: boolean;
  level: Level;
  onCheck: (checked: boolean) => void;
}) => (
  <div
    styleName={classNames('satisfaction-circle', `level-${level}`, { checked: checked })}
    onClick={() => onCheck(!checked)}
  >
    <img src={SatisfactionCheck} styleName="check" />
  </div>
);

export const SatisfactionOption = ({
  checked,
  level,
  label,
  onCheck,
}: {
  checked: boolean;
  level: Level;
  label: string;
  onCheck: (checked: boolean) => void;
}) => (
  <div styleName={classNames('option', `level-${level}`)}>
    <div styleName="option-body">
      <SatisfactionCircle checked={checked} level={level} onCheck={onCheck} />
    </div>
    <div styleName="label">{label}</div>
  </div>
);

const levelToLabel = (level: Level) => {
  switch (level) {
    case 1:
      return 'とても悪い';
    case 2:
      return '悪い';
    case 3:
      return 'どちらでもない';
    case 4:
      return '良い';
    case 5:
      return 'とても良い';
  }
};

const levelToValue = (level: Level) => {
  return `${level}. ${levelToLabel(level)}`;
};

type Props = {
  value: string | null;
  otherOptions: string[];
  onChange: (nextValue: string) => void;
};

export const SatisfactionSelectInput = ({ value, otherOptions, onChange }: Props) => {
  return (
    <div>
      <div styleName="standard-options">
        {levels.map((level) => (
          <SatisfactionOption
            key={level}
            level={level}
            checked={value === levelToValue(level)}
            label={levelToLabel(level)}
            onCheck={() => onChange(levelToValue(level))}
          />
        ))}
      </div>
      <div styleName="other-options">
        {otherOptions.map((o) => (
          <Button
            key={o}
            type={value === o ? 'primary' : 'normal'}
            styleName="other-option"
            size="l"
            onClick={() => onChange(o)}
          >
            {o}
          </Button>
        ))}
      </div>
    </div>
  );
};
