import React from 'react';
import { SatisfactionQuestionState } from './states/QuestionState';
import { QuestionCard } from '../../molecules/surveys/QuestionCard';
import { SatisfactionSelectInput } from '../../atoms/select/SatisfactionSelectInput';
import { observer } from 'mobx-react-lite';
import { SurveyQuestionFormat } from '../../../../commonv3/types/surveys';

type Props = {
  questionNumber: number;
  totalQuestions: number;
  questionState: SatisfactionQuestionState;
  format?: SurveyQuestionFormat;
};

export const SatisfactionQuestionCard = observer(({ questionNumber, totalQuestions, questionState, format }: Props) => {
  const { question, value, setValue, required, validate, errorMessage } = questionState;

  return (
    <QuestionCard
      questionNumber={questionNumber}
      totalQuestions={totalQuestions}
      sentence={question.sentence}
      subSentence={question.sub_sentence}
      youtubeKey={question.youtube_key}
      required={required}
      errorMessage={errorMessage}
      onBlur={validate}
      format={format}
    >
      <SatisfactionSelectInput value={value} onChange={setValue} otherOptions={question.content.extra_options} />
    </QuestionCard>
  );
});
