import React, { useEffect } from 'react';
import './paymentRetryPane.module.scss';
import { PaymentRetryPaneState } from '../../states/PaymentRetryPaneState';
import { useLocalObservable, observer } from 'mobx-react-lite';
import { LoaderRow } from '../../../../mainv3/components/atoms/layout/LoaderRow';
import { Button } from '../../../../commonv3/components/atoms/buttons/Button';
import { CreditCardInput } from '../../../../mainv3/components/molecules/payment/CardInput';
import { LegacyPaymentView } from '../../../../mainv3/components/organisms/mypage/LegacyPaymentView';
import { Heading } from '@sheinc/shelikes-design-system';

type Props = {
  match: {
    params: {
      code: string;
    };
  };
};

export const PaymentRetryPane = observer(({ match: { params } }: Props) => {
  const { pageState } = useLocalObservable(() => ({
    pageState: new PaymentRetryPaneState(),
  }));

  const { payment: paymentResource, cardState, response, responseError } = pageState;
  const payment = paymentResource.get();

  useEffect(() => {
    const { code } = params;
    pageState.init(code);
  }, []);

  return (
    <>
      <Heading size="XXS">再支払いする内容</Heading>
      <div styleName="section-body">
        {paymentResource.loading ? <LoaderRow /> : <LegacyPaymentView payment={payment} showAction={false} />}
      </div>
      <Heading size="XXS">使用するカード</Heading>
      <div styleName="section-body">
        <div styleName="payment-info">
          <CreditCardInput cardState={cardState} />
        </div>
        {responseError && <div styleName="error">{responseError.body}</div>}
      </div>
      <Button
        type={response && response.status === 'paid' ? 'success' : 'primary'}
        size="m"
        styleName="action-button"
        loading={pageState.submitState.submitting}
        onClick={() => pageState.submit(payment.code)}
        disabled={payment?.status !== 'error'}
      >
        {response && response.status === 'paid' ? '再支払いが完了しました' : '再支払いする'}
      </Button>
    </>
  );
});
