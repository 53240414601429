import React from 'react';
import { TopKSelectQuestionState } from './states/QuestionState';
import { QuestionCard } from '../../molecules/surveys/QuestionCard';
import { observer } from 'mobx-react-lite';
import { range } from 'lodash';
import { SelectInput } from '../../../../commonv3/components/atoms/forms/primitive/SelectInput';
import './topKSelectQuestionCard.module.scss';
import { SurveyQuestionFormat } from '../../../../commonv3/types/surveys';

const SelectWrapper = ({ label, children }: { label: React.ReactNode; children: React.ReactNode }) => {
  return (
    <div styleName="select-wrapper">
      <div styleName="label">{label}</div>
      <div styleName="body">{children}</div>
    </div>
  );
};

type Props = {
  questionNumber: number;
  totalQuestions: number;
  questionState: TopKSelectQuestionState;
  format?: SurveyQuestionFormat;
};

export const TopKSelectQuestionCard = observer(({ questionNumber, totalQuestions, questionState, format }: Props) => {
  const { question, required, validate, errorMessage, getOptions, selection, setSelection } = questionState;
  const { selection_count, selection_text } = question.content;

  return (
    <QuestionCard
      questionNumber={questionNumber}
      totalQuestions={totalQuestions}
      sentence={question.sentence}
      subSentence={question.sub_sentence}
      youtubeKey={question.youtube_key}
      required={required}
      errorMessage={errorMessage}
      bodyClassName={'top-k-select-question-card__body'}
      onBlur={validate}
      format={format}
    >
      {range(0, selection_count || 0).map((index) => {
        return (
          <SelectWrapper key={index} label={selection_text.replace('%index%', String(index + 1))}>
            <SelectInput
              key={index}
              value={selection[index]}
              placeholder={'選択してください'}
              onChange={(v) => setSelection(index, v)}
              options={getOptions(index)}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              serializer={(v) => v!}
              deserializer={(v) => v}
              styleName="select"
            />
          </SelectWrapper>
        );
      })}
    </QuestionCard>
  );
});
