import React from 'react';
import { CurrentUserState } from '../../../../mainv2/states/CurrentUserState';
import { observer } from 'mobx-react';
import DefaultUserProfile from '../../../../mainv3/containers/navigation/default-user-profile.png';
import classNames from 'classnames';
import './desktopUserInfo.module.scss';

type UserInfoProps = {
  currentUser: CurrentUserState;
  active: boolean;
  onMouseEnter?: () => void;
};

export const DesktopUserInfo = observer(({ currentUser, active, onMouseEnter }: UserInfoProps) => {
  if (currentUser.isSignedIn()) {
    const { name, profile_url } = currentUser.currentUser;
    return (
      <div id="user-info" styleName="user-info" onMouseEnter={onMouseEnter}>
        <div styleName={classNames('user-info-inner', { active: active })}>
          <img styleName="profile" src={profile_url ? profile_url : DefaultUserProfile} />
          <div styleName="name">{name}</div>
        </div>
      </div>
    );
  } else {
    return null;
  }
});
