import { observable, action, computed, makeObservable } from 'mobx';

export class FieldState<T> {
  value: T;
  error: string | null = null;
  defaultValue: T;
  required;
  emptyCheck: (value: T | null) => boolean;

  constructor(defaultValue: T, required: boolean, emptyCheck: (value: T | null) => boolean = (v) => !v) {
    makeObservable(this, {
      value: observable,
      error: observable,
      empty: computed,
      clearError: action.bound,
      setValue: action.bound,
      setError: action.bound,
      clear: action.bound,
    });

    this.value = defaultValue;
    this.defaultValue = defaultValue;
    this.required = required;
    this.emptyCheck = emptyCheck;
  }

  get empty(): boolean {
    if (this.emptyCheck === null) return false;
    return this.emptyCheck(this.value);
  }

  clearError() {
    this.error = null;
  }

  setValue(value: T) {
    this.value = value;
  }

  setError(error: string) {
    this.error = error;
  }

  isEdited(): boolean {
    return this.defaultValue !== this.value;
  }

  clear() {
    this.error = null;
    this.value = this.defaultValue;
  }
}

export type ApiFormError = {
  [key: string]: string[];
};

type FieldStates<T> = { [key in keyof T]: FieldState<T[key]> };

export class FormState<T extends object> {
  fields: FieldStates<T>;

  constructor(fields: FieldStates<T>) {
    makeObservable(this, {
      fields: observable,
      setValues: action,
      setErrors: action,
      setValue: action,
      clearErrors: action,
      clear: action,
    });

    this.fields = fields;
  }

  isRequiredFieldsFilled() {
    for (const key in this.fields) {
      const field = this.fields[key];
      if (field.required && field.empty) {
        return false;
      }
    }
    return true;
  }

  setValues(values: T) {
    for (const key in values) {
      if (this.keyInThisFields(key)) {
        this.fields[key].setValue(values[key]);
      }
    }
  }

  isEdited(): boolean {
    for (const key in this.fields) {
      if (this.fields[key].isEdited()) {
        return true;
      }
    }
    return false;
  }

  setErrors(errors: ApiFormError) {
    for (const key in errors) {
      if (this.keyInThisFields(key) && errors[key].length > 0) {
        this.fields[key].setError(errors[key][0]);
      }
    }
  }

  hasError<S extends keyof T>(key: S): boolean {
    return this.fields[key].error != null;
  }

  hasErrors() {
    for (const key in this.fields) {
      if (this.hasError(key)) {
        return true;
      }
    }
    return false;
  }

  getError<S extends keyof T>(key: S): string {
    return this.fields[key].error || '';
  }

  setValue<S extends keyof T>(key: S, value: T[S]) {
    this.fields[key].setValue(value);
  }

  getValue<S extends keyof T>(key: S): T[S] {
    return this.fields[key].value;
  }

  clearErrors() {
    for (const key in this.fields) {
      this.fields[key].clearError();
    }
  }

  asJson(): T {
    const json = Object();
    for (const key in this.fields) {
      json[key] = this.fields[key].value;
    }
    return json;
  }

  clear() {
    for (const key in this.fields) {
      this.fields[key].clear();
    }
  }

  private keyInThisFields(key: unknown): key is keyof T {
    return this.fields !== null && (key as any) in this.fields;
  }
}
