import moment, { Moment } from 'moment-timezone';
import { ISODateTime } from '../types/dates';

/**
 * 時刻関連の初期化をまとめておこなう
 * - ロケールとタイムゾーンの統一
 */
export async function initTime() {
  moment.locale('ja');
  moment.tz.setDefault('Asia/Tokyo');
}

/**
 * baseTime以降で、isoWeekDayが指定された値になる日を返す
 * @param baseTime
 * @param weekDay 曜日。Sunday = 0, Monday = 1, ..., Saturday = 6
 */
export function nextWeekday(baseTime: Moment | ISODateTime, weekDay: number): Moment {
  const base = moment(baseTime).startOf('day');
  return moment(base).isoWeekday(weekDay).isBefore(base) ? moment(base).isoWeekday(7 + weekDay) : base;
}

export const convertMinutesToFormattedString = (minutes: number) => {
  if (minutes < 60) {
    return `${minutes}分`;
  } else {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}時間${remainingMinutes}分`;
  }
};
