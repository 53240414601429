import { observer } from 'mobx-react';
import DefaultUserProfile from '../../../../mainv3/containers/navigation/default-user-profile.png';
import React from 'react';
import { CurrentUserState } from '../../../../mainv2/states/CurrentUserState';
import './spUserInfo.module.scss';

type UserInfoProps = {
  currentUser: CurrentUserState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: () => any;
};

export const SpUserInfo = observer(({ currentUser, onClick }: UserInfoProps) => {
  if (currentUser.isSignedIn()) {
    const { profile_url } = currentUser.currentUser;
    return (
      <div id="user-info" styleName="user-info" onClick={onClick}>
        <img styleName="profile" src={profile_url ? profile_url : DefaultUserProfile} />
      </div>
    );
  } else {
    return null;
  }
});
