import * as React from 'react';
import './resetPassword.module.scss';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { Page, Pane } from '../../../../mainv3/components/atoms/applications/page';
import { Button } from '../../../../commonv3/components/atoms/buttons/Button';
import { StringFieldInput } from '../../../../mainv3/components/atoms/forms/vertical/StringField';
import { AccountRoutes } from '../../../routes/AccountRoutes';
import { SignInNavigation } from '../navigation/SignInNavigation';
import { ResetPasswordPageState } from '../../../containers/passwords/states/ResetPasswordPageState';
import { GlobalStatesV3 } from '../../../../commonv3/states/GlobalStatesV3';

type Props = {
  pageState: ResetPasswordPageState;
};

export const ForgotPasswordPane = inject('pageState')(
  observer(({ pageState }: Props) => {
    const { requestCode, submitting, emailField } = pageState;
    return (
      <>
        {GlobalStatesV3.viewport.isNonSp && <SignInNavigation />}
        <Page>
          <Pane>
            <div styleName="header">パスワードのリセット</div>
            <div styleName="subheader">SHEにご登録いただいたメールアドレスをご入力ください。</div>
            <div styleName="body">
              <div styleName="form">
                <StringFieldInput
                  label="メールアドレス"
                  placeholder="she@she-inc.co.jp"
                  type="text"
                  state={emailField}
                />
                <div id="request-code-button-wrapper" styleName="button-wrapper">
                  <Button
                    id="request-code-button"
                    styleName="button"
                    type="primary"
                    size="l"
                    onClick={requestCode}
                    loading={submitting}
                  >
                    次へ進む
                  </Button>
                </div>
                <Button type="link" size="l" styleName="link" id="login-button" to={AccountRoutes.signIn()}>
                  ログインに戻る
                </Button>
              </div>
            </div>
          </Pane>
        </Page>
      </>
    );
  })
);
