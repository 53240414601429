import { MembershipItemId, MembershipPlanId } from '../../commonv3/types/membershipPlans';
import { V3MembershipPlanKey } from '../../mainv2/types/user';

export enum MembershipPlanGroupKey {
  LIKES = 'likes',
  MULTICREATOR = 'multicreator',
  BEAUTY = 'beauty',
  MONEY = 'money',
}

export enum MembershipPlanKey {
  CASUAL = 'casual',
  LIKES_STANDARD = 'regular',
  MONEY_CURRICULUM = 'money_curriculum',
}

export type MembershipPlanIndexView = {
  id: MembershipPlanId;
  key: MembershipPlanKey;
  name: string;
  items: MembershipItemIndexView[];
  lesson_deactivation: boolean;
};

export type SuccinctCampaign = {
  name: string;
  discount: number;
};

export type MembershipItemIndexView = {
  id: MembershipItemId;
  name: string;
  total_price: number;
  price: number;
  price_with_tax: number;
  base_price: number;
  discount: number;
  tax: number;
  duration_month: number;
  discount_campaigns: SuccinctCampaign[];
  membership_plan_id: MembershipPlanId;
  membership_plan: MembershipPlanIndexView;
  membership_plan_key: V3MembershipPlanKey;
};
