import { CommonsV1ApiBase, MainV3ApiBase } from './base';
import { ReservationIndexView } from '../types/reservations';
import { ReservationId } from '../../commonv3/types/reservations';
import { ReservableType } from '../../commonv3/types/reservable';
import { Service } from '../../commonv3/types/service';
import { ReservationSummary } from '../types/reservationSummaries';

export const ReservationApis = {
  base() {
    return CommonsV1ApiBase.appendPath('users/current/reservations');
  },
  findAll(services?: Service[]): Promise<ReservationIndexView[]> {
    return this.base().get({ services: services ? services.join(',') : undefined });
  },
  find(id: ReservationId): Promise<ReservationIndexView> {
    return this.base().withId(id).get();
  },
  findByDate(date: Date): Promise<ReservationIndexView[]> {
    return this.base().get({ session_date: new Date(date) });
  },
  create(reservable_type: ReservableType, reservable_id: number): Promise<ReservationIndexView> {
    return this.base().post({
      reservable_type,
      reservable_id,
    });
  },
  cancel(reservationId: ReservationId) {
    return this.base().withId(reservationId).put({
      status: 'cancelled',
    });
  },
  attend(reservationId: ReservationId) {
    return this.base().withId(reservationId).put({
      attendance: 'attended',
    });
  },
};

export const ReservationSummaryApis = {
  show() {
    return MainV3ApiBase.appendPath('users/current/reservation_summary').get<ReservationSummary>();
  },
};
