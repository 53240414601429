import React from 'react';
import './page.module.scss';

type PageProps = {
  id?: string;
  children: React.ReactNode;
  className?: string;
  styleName?: string;
};

/**
 * お申し込みページ用のページコンポーネント
 */
export const Page = ({ children, id = undefined, className = undefined }: PageProps) => (
  <div styleName="page" className={className} id={id}>
    {children}
  </div>
);

export const Pane = ({ children, id = undefined, className = undefined }: PageProps) => (
  <div styleName="pane" className={className} id={id}>
    {children}
  </div>
);
