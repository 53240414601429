import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './checkBoxCardNoBorder.module.scss';
import CheckSvg from './images/check.svg';
import { Check } from '@material-ui/icons';

type CheckBoxProps = {
  color: 'white' | 'primary';
  checked: boolean;
  className?: string;
  styleName?: string;
  disabled: boolean;
};

const CheckBox = ({ checked, color = 'white', className = undefined, disabled }: CheckBoxProps) => (
  <div
    styleName={classNames('check-box', color, { checked, disabled })}
    className={className}
    data-value-checked={checked}
  >
    {checked && color === 'white' && <img styleName="checked-icon" src={CheckSvg} />}
    {checked && color !== 'white' && <Check styleName="checked-icon" />}
  </div>
);

type Props = {
  id?: string;
  checked: boolean;
  label: string | ReactNode;
  color?: 'white' | 'primary';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCheck: (checked: boolean) => any;
  widthType?: 's' | 'm';
  className?: string;
  styleName?: string;
  'data-value'?: string;
  disabled?: boolean;
  dataTestid?: string;
};

export const CheckBoxCardNoBorder = ({
  label,
  checked,
  onCheck,
  className,
  widthType = 'm',
  color = 'white',
  'data-value': dataAttribute,
  id,
  disabled = false,
  dataTestid,
}: Props) => (
  <div
    styleName={classNames('check-box-card', widthType, { checked, disabled })}
    className={classNames('check-box-card', className, widthType, { checked: checked })}
    onClick={() => !disabled && onCheck(!checked)}
    data-value={dataAttribute}
    id={id}
    data-testid={dataTestid}
  >
    <CheckBox checked={checked} styleName="chk" color={color} disabled={disabled} />
    {label}
  </div>
);
