import React from 'react';
import classNames from 'classnames';
import './sheModal.module.scss';
import { OverlayContent } from './OverlayPage';

type Props = {
  open: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRequestClose: () => any;
  showCloseButton?: boolean;
  modalClassName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  id?: string;
  size?: ModalSize;
  testId?: string;
};

export const ModalSize = {
  Default: 'default',
  Small: 'small',
  Medium: 'medium',
  PopupNotificationLarge: 'popup-notification-large',
  PopupNotificationSmall: 'popup-notification-small',
} as const;

export type ModalSize = typeof ModalSize[keyof typeof ModalSize];

/**
 * @deprecated デザインシステム移行中につき今後の利用は非推奨。ModalDialog を使用してください。
 */
export const SheModal = ({
  open,
  children,
  onRequestClose,
  showCloseButton = true,
  modalClassName,
  id,
  size = 'default',
  testId,
}: Props) => {
  return (
    <OverlayContent
      open={open}
      onRequestClose={onRequestClose}
      childElem={(st, setRef, options) => {
        return (
          <div id={id} styleName={classNames(`modal ${size}`)} data-testid={testId}>
            {showCloseButton && (
              <div styleName="button-wrapper">
                <div id="close-button" styleName={classNames('close-button', st)} onClick={onRequestClose}>
                  <div styleName={classNames('close-button-inner', st)}>
                    <div styleName="bar leftright" />
                    <div styleName="bar rightleft" />
                  </div>
                </div>
              </div>
            )}
            <div
              styleName={classNames('modal-body', st, {
                'vertically-centered': options.verticallyCentered,
              })}
              ref={setRef}
              className={modalClassName}
            >
              {children}
            </div>
          </div>
        );
      }}
    />
  );
};
