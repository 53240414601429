import React from 'react';
import './questionCard.module.scss';
import { CardFormat } from '../../atoms/surveys/cardFormat';
import { OneByOneFormat } from '../../atoms/surveys/oneByOneFormat';
import { SurveyQuestionFormat } from '../../../../commonv3/types/surveys';
import { useOuterClickHandler } from '../../../hooks/useOuterClickHandler';

type Props = {
  questionNumber: number;
  totalQuestions: number;
  sentence: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subSentence?: any;
  youtubeKey?: string;
  required: boolean;
  errorMessage?: string | null;
  children?: React.ReactNode;
  onBlur?: () => void;
  className?: string;
  bodyClassName?: string;
  styleName?: string;
  format?: SurveyQuestionFormat;
};

export const QuestionCard = ({
  questionNumber,
  totalQuestions,
  sentence,
  subSentence,
  youtubeKey,
  required,
  errorMessage,
  className,
  bodyClassName,
  children,
  onBlur,
  format,
}: Props) => {
  switch (format) {
    case 'card':
      return (
        <CardFormat
          questionNumber={questionNumber}
          totalQuestions={totalQuestions}
          sentence={sentence}
          subSentence={subSentence}
          youtubeKey={youtubeKey}
          required={required}
          errorMessage={errorMessage}
          className={className}
          bodyClassName={bodyClassName}
          onBlur={onBlur}
          useOuterClickHandler={useOuterClickHandler}
        >
          {children}
        </CardFormat>
      );
    case 'one_by_one':
      return (
        <OneByOneFormat
          questionNumber={questionNumber}
          totalQuestions={totalQuestions}
          sentence={sentence}
          subSentence={subSentence}
          youtubeKey={youtubeKey}
          required={required}
          errorMessage={errorMessage}
          className={className}
          bodyClassName={bodyClassName}
          onBlur={onBlur}
          useOuterClickHandler={useOuterClickHandler}
        >
          {children}
        </OneByOneFormat>
      );
    default:
      return (
        <CardFormat
          questionNumber={questionNumber}
          totalQuestions={totalQuestions}
          sentence={sentence}
          subSentence={subSentence}
          youtubeKey={youtubeKey}
          required={required}
          errorMessage={errorMessage}
          className={className}
          bodyClassName={bodyClassName}
          onBlur={onBlur}
          useOuterClickHandler={useOuterClickHandler}
        >
          {children}
        </CardFormat>
      );
  }
};
