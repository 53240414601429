import { Api as _Api } from '../../common/api/Api';
import { ApiV2 } from '../../commonv2/api/ApiV2';
import { locatePath } from '../../common/RelativeUrl';
import { SecureUser, User } from '../types/user';
import { CardSummary, PayjpTokenResponse } from '../types/card';
import { FieldSetType, UserInfo } from '../containers/common/userInfo/states/UserFormState';
import { Lesson, LessonId } from '../types/lesson';

enum MenuStep {
  FUTURE = 'future',
  PAST = 'past',
  WANTS = 'wants',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let Payjp: any;

export const UserApis = {
  base() {
    const baseHost = process.env.REST_API_BASE_URL || `${location.protocol}//${location.host}`;
    return new _Api(baseHost + locatePath('/api/v1'), '');
  },
  baseV2() {
    const baseHost = process.env.REST_API_BASE_URL || `${location.protocol}//${location.host}`;
    return new ApiV2(baseHost + locatePath('/api/v2'), '');
  },
  /**
   * Returns the current user as {@link SecureUser}, or null if the user is not signed in
   */
  getCurrentUser(): Promise<SecureUser> {
    return this.base().appendPath('users/current').get({});
  },
  /**
   * Returns the current user as {@link User}
   */
  async getCurrentUserV2(): Promise<User> {
    return await this.baseV2().appendPath('users/current').get();
  },
  /**
   * Returns reserved lessons
   * @param status the status of lessons to fetch (TODO: the parameter name is not meaningful. Need refactoring)
   * @returns {Promise<Lesson[]>}
   */
  async getReservedLessons(status: MenuStep): Promise<Lesson[]> {
    return await this.baseV2().appendPath('users/current/reserved_lessons').get({ status: status });
  },
  /**
   * Cancel the reserved_lessons
   * @returns {Promise<Lesson[]>}
   */
  async cancelReservedLessons(lessonIds: LessonId[]): Promise<void> {
    return await this.baseV2()
      .appendPath('users/current/reserved_lessons/multiple')
      .put({ reserved_lesson_ids: lessonIds });
  },
  /**
   * Returns the {@link CardSummary} that the current user uses, or null if she doesn't have any cards.
   */
  getCurrentCard(): Promise<CardSummary> {
    return this.baseV2().appendPath('users/current/card').get();
  },
  /**
   * Validates the correctness of user info
   * @param {UserInfo} userInfo : the {@link UserInfo} to post
   * @param {FieldSetType} fieldSetType : the field set to validate
   * @returns {Promise<void>}
   */
  async validateForm(userInfo: Partial<UserInfo>, fieldSetType: FieldSetType): Promise<void> {
    return await this.baseV2()
      .appendPath('users/validate')
      .post({ ...userInfo, validation_type: fieldSetType });
  },
  /**
   * Creates user from the given user info
   * @param {UserInfo} userInfo
   * @returns {Promise<SecureUser>}
   */
  async createUser(userInfo: Partial<UserInfo>): Promise<SecureUser> {
    return await this.baseV2().appendPath('users').post(userInfo);
  },
  /**
   * Updates the current from user given user info
   * @param {UserInfo} userInfo
   * @returns {Promise<SecureUser>}
   */
  async updateUser(userInfo: Partial<UserInfo>): Promise<SecureUser> {
    return await this.baseV2().appendPath('users/current').put(userInfo);
  },
  /**
   * Updates the current card of the user
   * @param {string} token : the token provided from Payjp. Use {@link #createToken} for details
   * @returns {Promise<void>}
   */
  updateCard(token: string): Promise<void> {
    return this.baseV2().appendPath('users/current/card').put({ card_token: token });
  },
  /**
   * Creates the secure card token from the given cardElement
   * Or throws {@link PayjpError} if the request is invalid.
   * @param {cardElement}
   * @returns {Promise<PayjpTokenResponse>}
   */
  // TODO: ステートから外に出して usePaymentProvider 経由で利用できるようにする
  async createToken(cardElement: unknown): Promise<PayjpTokenResponse> {
    const res = await payjp.createToken(cardElement);
    if ('error' in res) {
      return Promise.reject(res.error);
    }
    return res;
  },
};
