import * as React from 'react';
import classNames from 'classnames';
import './questionLabel.module.scss';

type BaseProps = {
  type: 'danger' | 'secondary';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  className?: string;
  styleName?: string;
};

export const QuestionLabel = ({ type, children, className }: BaseProps) => {
  return (
    <div styleName={classNames('label', type)} className={className}>
      {children}
    </div>
  );
};

type Props = {
  className?: string;
  styleName?: string;
};

export const RequiredLabel = ({ className }: Props) => (
  <QuestionLabel type="danger" className={className}>
    必須
  </QuestionLabel>
);
export const OptionalLabel = ({ className }: Props) => (
  <QuestionLabel type="secondary" className={className}>
    任意
  </QuestionLabel>
);
