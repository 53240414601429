import * as React from 'react';
import { Loader } from '../forms/loader';
import './loaderRow.module.scss';
import { SheEnv } from '../../../../commonv3/utils/envs';

export const LoaderRow = ({ suppressInTest = false }) => {
  if (suppressInTest && SheEnv.isTest()) {
    return null;
  }
  return (
    <div className="loader-row" styleName="loader-row">
      <Loader styleName="loader" className="loader-row" />
    </div>
  );
};

export const HiddenLoaderRow = () => {
  return <div className="loader-row" style={{ visibility: 'hidden' }} />;
};
