import React, { useEffect } from 'react';
import './SignOutPage.module.scss';
import { Page } from '../../../mainv3/components/atoms/layout/page/Page/Page';
import { PageBody } from '../../../mainv3/components/atoms/layout/page/PageBody/PageBody';
import { V3Routes } from '../../../mainv3/routes';

export const SignOutPage = () => {
  useEffect(() => {
    location.href = V3Routes.signOutUrl();
  }, []);
  return (
    <Page>
      <PageBody>
        <div styleName="sign-out-text">ログアウトしています...</div>
      </PageBody>
    </Page>
  );
};
