import React from 'react';
import './singleSelectPullDownQuestionCard.module.scss';
import { SingleSelectPullDownQuestionState } from './states/QuestionState';
import { QuestionCard } from '../../molecules/surveys/QuestionCard';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { SurveyQuestionFormat } from '../../../../commonv3/types/surveys';
import { SelectBoxCard, SelectBoxOptions } from '../../atoms/select/SelectBoxCard';

type Props = {
  questionNumber: number;
  totalQuestions: number;
  questionState: SingleSelectPullDownQuestionState;
  format?: SurveyQuestionFormat;
};

export const SingleSelectPullDownQuestionCard = observer(
  ({ questionNumber, totalQuestions, questionState, format }: Props) => {
    const { question, required, validate, errorMessage, setNormalValue } = questionState;
    const questions: SelectBoxOptions[] = question.content.options.map((item) => ({ label: item, value: item }));

    return (
      <QuestionCard
        questionNumber={questionNumber}
        totalQuestions={totalQuestions}
        sentence={question.sentence}
        subSentence={question.sub_sentence}
        youtubeKey={question.youtube_key}
        required={required}
        errorMessage={errorMessage}
        onBlur={validate}
        format={format}
      >
        <div styleName={classNames('container')}>
          <SelectBoxCard
            id={question.key}
            placeholder="選択してください"
            options={questions}
            onChange={setNormalValue}
          />
        </div>
      </QuestionCard>
    );
  }
);
