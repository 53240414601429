import React from 'react';
import { Heading } from '@sheinc/shelikes-design-system';
import './page.module.scss';

type HeaderProps = { title: string | undefined; hasBackButton?: boolean };

export const PaneSubHeader = ({ title }: HeaderProps) => (
  <div styleName="pane-sub-header">
    <Heading size="XXS">{title}</Heading>
  </div>
);
