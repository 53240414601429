import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { CheckBoxCardNoBorder } from '../select/CheckBoxCardNoBorder';
import './checkBoxInput.module.scss';

type CheckBoxInputProps = {
  checked: boolean;
  label: string | ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCheck: (checked: boolean) => any;
  error?: string;
  className?: string;
  styleName?: string;
  dataTestid?: string;
};

export const CheckBoxInput = ({
  checked,
  onCheck,
  error,
  label,
  className,
  styleName,
  dataTestid,
}: CheckBoxInputProps) => (
  <div styleName={classNames('check-box-input-wrapper', styleName)}>
    <CheckBoxCardNoBorder
      label={label}
      checked={checked}
      onCheck={onCheck}
      className={className}
      dataTestid={dataTestid}
    />
    {error && <div styleName="error">{error}</div>}
  </div>
);
