import React from 'react';
import { SingleSelectQuestionState } from './states/QuestionState';
import { QuestionCard } from '../../molecules/surveys/QuestionCard';
import { observer } from 'mobx-react-lite';
import { FreeAnswerRadioCard, RadioCard } from '../../atoms/select/RadioCard';
import classNames from 'classnames';
import './singleSelectQuestion.module.scss';
import { SurveyQuestionFormat } from '../../../../commonv3/types/surveys';

type Props = {
  questionNumber: number;
  totalQuestions: number;
  questionState: SingleSelectQuestionState;
  format?: SurveyQuestionFormat;
};

export const SingleSelectQuestionCard = observer(({ questionNumber, totalQuestions, questionState, format }: Props) => {
  const {
    question,
    required,
    validate,
    errorMessage,
    normalValue,
    setNormalValue,
    otherValue,
    otherValueChecked,
    setOtherValue,
    setOtherValueChecked,
  } = questionState;

  return (
    <QuestionCard
      questionNumber={questionNumber}
      totalQuestions={totalQuestions}
      sentence={question.sentence}
      subSentence={question.sub_sentence}
      youtubeKey={question.youtube_key}
      required={required}
      errorMessage={errorMessage}
      onBlur={validate}
      format={format}
    >
      <div styleName={classNames('container', question.content.size)}>
        {question.content.options.map((opt) => (
          <RadioCard
            key={opt}
            styleName={classNames('option', question.content.size)}
            checked={opt === normalValue}
            label={opt}
            onCheck={() => setNormalValue(opt)}
          />
        ))}
        {question.content.allow_other && (
          <FreeAnswerRadioCard
            styleName={classNames('option', question.content.size)}
            checked={otherValueChecked}
            label="その他"
            onCheck={setOtherValueChecked}
            value={otherValue}
            onChange={setOtherValue}
          />
        )}
      </div>
    </QuestionCard>
  );
});
