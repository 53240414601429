import { MainV3ApiBase } from './base';
import { NewPassword, CurrentProfile, EditEmail } from '../types/user_info';

export const UserInfoApis = {
  base() {
    return MainV3ApiBase.appendPath('users/current/user_info');
  },
  updateEmail(formData: EditEmail): Promise<void> {
    return this.base().put(formData);
  },
  updatePassword(formData: NewPassword): Promise<void> {
    return this.base().put(formData);
  },
  updateProfile(formData: FormData): Promise<void> {
    return this.base().putForm(formData);
  },
  findProfile(): Promise<CurrentProfile> {
    return this.base().get();
  },
};
