import { TokenId, TokenType } from '../../commonv3/types/tokens';
import { computed, observable, makeObservable } from 'mobx';
import { ISODateTime } from '../../commonv3/types/dates';
import moment from 'moment-timezone';
import { SessionIndexView } from './sessions';

export type Token = {
  id: TokenId;
  token_type: TokenType;
  amount: number;
  effective_at: ISODateTime;
  expires_at: ISODateTime;
};

export class TokenState {
  token: Token = null;

  constructor(token: Token) {
    makeObservable(this, {
      token: observable,
      effectiveAt: computed,
      expiresAt: computed,
    });

    this.token = token;
  }

  /**
   * Returns true if this token can afford the required tokenType and amount at the specified timestamp
   * @param token_type the {@link TokenType}
   * @param amount the amount
   * @param timestamp the
   */
  isAffordable(token_type: TokenType, amount: number, timestamp: ISODateTime | moment.Moment) {
    if (token_type !== this.token.token_type) {
      return false;
    }
    if (!this.isAvailableAt(timestamp)) {
      return false;
    }
    if (this.token.amount < 0) {
      return true;
    }
    return this.token.amount >= amount;
  }

  isUnlimited(token_type: TokenType) {
    return this.token.token_type === token_type && this.token.amount < 0;
  }

  get effectiveAt() {
    return moment(this.token.effective_at);
  }

  get expiresAt() {
    return moment(this.token.expires_at);
  }

  /**
   * Returns true if this token is available at the given timestamp
   * @param timestamp
   */
  isAvailableAt(timestamp: ISODateTime | moment.Moment) {
    const ts = moment(timestamp);
    return ts.isBefore(this.expiresAt) && ts.isSameOrAfter(this.effectiveAt);
  }
}

/**
 * Provides various operations to a list of tokens
 */
export class TokenListState {
  tokens: TokenState[] = [];

  constructor() {
    makeObservable(this, {
      tokens: observable,
    });
  }

  init(tokens: Token[]) {
    this.tokens = tokens.map((t) => new TokenState(t));
  }

  /**
   * Returns a {@link Token} available for the given token_type, amount at the given timestamp
   * @param token_type the {@link TokenType}
   * @param amount the amount
   * @param timestamp the timestamp
   */
  findAffordableToken(token_type: TokenType, amount: number, timestamp: ISODateTime | moment.Moment): Token | null {
    const match = this.tokens.find((t) => t.isAffordable(token_type, amount, timestamp));
    if (match) {
      return match.token;
    }
    return null;
  }

  /**
   * Returns true if this list of tokens can afford the given requirement
   * @param token_type the {@link TokenType}
   * @param amount the amount
   * @param timestamp the timestamp
   */
  isAffordable(token_type: TokenType, amount: number, timestamp: ISODateTime | moment.Moment): boolean {
    return this.findAffordableToken(token_type, amount, timestamp) != null;
  }

  /**
   *
   * @param token_type
   */
  isUnlimited(token_type: TokenType, timestamp = moment()) {
    return this.tokens.find((t) => t.isUnlimited(token_type) && t.isAvailableAt(timestamp)) != null;
  }

  isSessionAffordable(session: SessionIndexView) {
    return this.isAffordable(session.required_token_type, session.required_token_amount, session.starts_at);
  }
}
