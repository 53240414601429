import { ApiError } from './protocol';

export class RemoteResourceV3<T> {
  loading: boolean;
  value: T | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any | undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(loading: boolean, value: T = null, error: any = null) {
    this.loading = loading;
    this.value = value;
    this.error = error;
  }

  get(): T {
    return this.value;
  }

  getOrThrow(): T {
    if (this.isEmpty()) {
      throw new Error('Value must exist');
    }
    return this.value;
  }

  getOrElse(defaultValue: T): T {
    if (this.isEmpty()) {
      return defaultValue;
    }
    return this.value;
  }

  map<S>(func: (value: T) => S): RemoteResourceV3<S> {
    if (this.value === undefined || this.value === null) {
      return new RemoteResourceV3<S>(false, null, this.error);
    }
    return new RemoteResourceV3<S>(false, func(this.value), this.error);
  }

  isEmpty() {
    return this.value === undefined || this.value === null;
  }

  isError() {
    return this.error !== undefined && this.error !== null;
  }

  isFatalError() {
    return this.isError() && this.error instanceof ApiError && this.error.isFatalError();
  }

  isNotFound() {
    return this.isError() && this.error instanceof ApiError && this.error.isNotFoundError();
  }

  isOperationalError() {
    return this.isError() && this.error instanceof ApiError && this.error.isOperationalError();
  }

  isValidationError() {
    return this.isError() && this.error instanceof ApiError && this.error.isValidationError();
  }

  withLoading(loading = true) {
    return new RemoteResourceV3(loading, this.value, this.error);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  withError(error: any) {
    return new RemoteResourceV3(this.loading, this.value, error);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static empty(): RemoteResourceV3<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new RemoteResourceV3<any>(false, null, null);
  }

  static of<T>(value: T): RemoteResourceV3<T> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new RemoteResourceV3<any>(false, value, null);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static loading(): RemoteResourceV3<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new RemoteResourceV3<any>(true, null, null);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static error(error: any): RemoteResourceV3<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new RemoteResourceV3<any>(false, null, error);
  }
}

type CallableLike<T> = () => Promise<T>;

export async function updateRemoteResourceV3<T>(
  original: RemoteResourceV3<T>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setter: (v: RemoteResourceV3<T>) => any,
  callable: CallableLike<T>
): Promise<T> {
  setter(original.withLoading(true).withError(null));
  try {
    const value = await callable();
    setter(RemoteResourceV3.of(value));
    return value;
  } catch (e) {
    setter(RemoteResourceV3.error(e));
  }
}
