import { ApiV2 } from '../../commonv2/api/ApiV2';
import { locatePath } from '../../common/RelativeUrl';
import { TrialLessonConfig } from '../../admin/types/trial';

export const TrialLessonConfigApis = {
  findGlobal(): Promise<TrialLessonConfig> {
    const baseHost = process.env.REST_API_BASE_URL || `${location.protocol}//${location.host}`;
    return new ApiV2(baseHost + locatePath('/api/v2/trial_lesson_config')).get();
  },
};
