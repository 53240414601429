import { FormState, FieldState } from '../../../../mainv2/states/FormState';
import { NewProfile } from '../../../../mainv3/types/user_info';

export class ProfileChangeFormState extends FormState<NewProfile> {
  constructor() {
    super({
      id: new FieldState('', false),
      name: new FieldState('', false),
      birthday: new FieldState('', false),
      job_company: new FieldState('', false),
      job_department: new FieldState('', false),
      job_title: new FieldState('', false),
    });
  }
}
