import { SurveyKey } from '../../commonv3/types/surveys';
import { MainV3ApiBase } from '../../mainv3/apis/base';

export const SurveySubmissionParameterApis = {
  validate(surveyKey: SurveyKey, parameters: object) {
    return MainV3ApiBase.appendPath('surveys')
      .withId(surveyKey)
      .appendPath('survey_submission_parameters/validate')
      .post({ parameters });
  },
};
