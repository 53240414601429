import { MembershipItemIndexView } from './membershipPlans';
import { MembershipItemId } from '../../commonv3/types/membershipPlans';
import { SubscriptionId, SubscriptionSuspension, SubscriptionSuspensionId } from './subscriptionSuspension';
import { SubscriptionIndexView } from './subscriptions';
import { ISODate } from '../../commonv3/types/dates';
import { Service } from '../../commonv3/types/service';

export enum StatusType {
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
}

export type SubscriptionSummary = {
  plan_name: string;
  plan_key: string;
  plan_item_price: number;
  plan_item_price_with_tax: number;
  plan_item_duration_month: number;
  will_change_plan: boolean;
  next_payment_date: ISODate | null;
  next_payment_amount: number;
  next_updatable_date: ISODate | null;
  // 新Subscription
  current_period_membership_item_id: MembershipItemId;
  current_period_membership_item: MembershipItemIndexView;
  current_period_starts_at: ISODate;
  next_period_membership_item_id: MembershipItemId;
  next_period_membership_item: MembershipItemIndexView;
  next_period_starts_at: ISODate;
  starts_at: ISODate;
  cancels_at: ISODate | null;
  // 以下のフィールドはMembershipTokenから産出する必要がある
  expires_at: ISODate;
  strict_expires_at: ISODate;
  suspended: boolean;
  subscription_suspension_id: SubscriptionSuspensionId | null;
  subscription_suspension: SubscriptionSuspension | null;
  chain_id: string; // Just used in SubscriptionSummaryListView.tsx to generate the id of the container div (-> E2E)
  parent_chain_id: string; // Just used in SubscriptionSummaryListView.tsx (findNextSummary)
  subscribed: boolean;
  future_suspension: boolean;
  subscription: SubscriptionIndexView | null;
  subscription_id: SubscriptionId | null;
  subscription_code: string | null;
  service: Service;
  active: boolean;
  status: StatusType;
};
