import * as React from 'react';
import './formInput.module.scss';
import { observer } from 'mobx-react';
import classNames from 'classnames';

type BaseFormInputProps = {
  type?: string;
  name: string;
  value: string;
  placeholder: string;
  error?: string;
  styleName?: string;
  className?: string;
  disabled?: boolean;
};

type TextareaFormInputProps = {
  type: 'textarea';
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
} & BaseFormInputProps;

type InputFormInputProps = {
  type?: 'password' | 'number' | 'text' | 'date';
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & BaseFormInputProps;

type FormInputProps = TextareaFormInputProps | InputFormInputProps;

export const FormInput = observer(
  ({ type, name, value, placeholder, onChange, error, className, disabled }: FormInputProps) => {
    const formInputStyleNames = classNames('form-input', {
      'account-page-form-input': className?.includes('account-page-form-input'),
    });

    if (type === 'textarea') {
      return (
        <div styleName="form-input-wrapper" className={className}>
          <textarea
            styleName={formInputStyleNames}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
          />
          {error && <div styleName="error">{error}</div>}
        </div>
      );
    }
    return (
      <div styleName="form-input-wrapper" className={className}>
        <input
          type={type || 'text'}
          styleName={formInputStyleNames}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
        />
        {error && <div styleName="error">{error}</div>}
      </div>
    );
  }
);
