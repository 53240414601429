import React from 'react';
import { Primary } from '../../../atoms/membership_plans/Primary';
import moment from 'moment-timezone';
import { GlobalStatesV3 } from '../../../../../commonv3/states/GlobalStatesV3';
import { V3Routes } from '../../../../routes';
import './withdrawalLabel.module.scss';
import { WithdrawalDateTime } from '../../../atoms/withdrawals/WithdrawalDateTime';
import { Withdrawal } from '../../../../types/withdrawal';
import { Button } from '@sheinc/shelikes-design-system';

type LabelProps = {
  withdrawal?: Withdrawal;
  canCancellWithdrawal?: boolean;
};
export const WithdrawalLabel = ({ withdrawal, canCancellWithdrawal = true }: LabelProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const withdrawalEffectiveAt = moment(withdrawal!.effective_at);
  const membershipLimit = moment(withdrawalEffectiveAt).add(-1, 'second');
  return (
    <div styleName="box-wrapper" id="withdrawal-label">
      <Primary>
        <div styleName="box">
          <div styleName="text">
            <div styleName="label">退会申請済</div>
            <div>
              <WithdrawalDateTime dateTime={withdrawalEffectiveAt} />
              に退会予定
            </div>
          </div>
          {canCancellWithdrawal && (
            <div styleName="button">
              <Button
                id="withdrawal-cancel-button"
                size="small"
                onClick={() => GlobalStatesV3.routing.push(V3Routes.withdrawalCancel())}
                fullWidth={GlobalStatesV3.viewport.isSp}
              >
                退会申請を取り消す
              </Button>
            </div>
          )}
        </div>
      </Primary>
      <div styleName="foot-note">
        ※<WithdrawalDateTime dateTime={membershipLimit} />
        までサービスをご利用いただけます
      </div>
    </div>
  );
};
