import { observable, makeObservable } from 'mobx';

export class SubmitState {
  submitting: boolean;
  constructor(initialState = false) {
    makeObservable(this, {
      submitting: observable,
    });

    this.submitting = initialState;
  }

  async operate<T>(procedure: () => T) {
    try {
      this.submitting = true;
      return await procedure();
    } finally {
      this.submitting = false;
    }
  }
}
