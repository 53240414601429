import * as React from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { Page } from '../../../mainv3/components/atoms/applications/page';
import '../../../mainv3/containers/attendances/attendancePage.module.scss';
import { LoaderRow } from '../../../mainv3/components/atoms/layout/LoaderRow';
import { AttendancePageState } from '../../../mainv3/containers/attendances/states/AttendancePageState';
import { ReservationCardList } from '../../../mainv3/components/molecules/reservations/ReservationCardList';
import { GlobalStatesV3 } from '../../../commonv3/states/GlobalStatesV3';
import { authenticated } from '../../../mainv3/components/hocs/authenticated';
import { MembershipPlanGroupKey } from '../../../mainv3/types/membershipPlans';
import { useEffect } from 'react';

export const AttendancePage = authenticated(
  observer(() => {
    const { pageState } = useLocalObservable(() => ({ pageState: new AttendancePageState() }));

    useEffect(() => {
      pageState.init();
    });

    const { loading, allReservationList, attend } = pageState;
    const currentUser = GlobalStatesV3.currentUser;
    const reservationListOfMoney = allReservationList.filter((reservation) =>
      reservation.reservable.membership_plan_group_keys.includes(MembershipPlanGroupKey.MONEY)
    );
    return (
      <Page>
        {loading && <LoaderRow />}
        <div styleName="header">
          {GlobalStatesV3.currentUser.isSignedIn() ? (
            <div styleName="text">
              <span styleName="name">{currentUser.currentUser.name}</span> さんこんにちは！
            </div>
          ) : (
            <div />
          )}
          <div styleName="text">予約内容を確認し出席ボタンをタップしてください</div>
        </div>
        <div styleName="body">
          <div styleName="reservation_count">
            本日の予約数<span styleName="no">{reservationListOfMoney.length}</span>件
          </div>
          {reservationListOfMoney.length > 0 ? (
            <ReservationCardList
              reservations={reservationListOfMoney}
              onRequestUpdate={(reservation) => attend(reservation, true)}
            />
          ) : (
            <div styleName="no_reservation">
              本日予約されているイベントは
              <br />
              ありません
            </div>
          )}
        </div>
      </Page>
    );
  })
);
