/* eslint-disable */

/**
 * A portable form of api endpoint bound with required parameters
 * This consists of 2 components
 *  - id : the identifier of this endpoint used for update detection
 *  - callApi : actuall calls the endpoint and returns the result
 * Meant to be used in higher order component
 * e.g. {@link bindRemoteResources}
 */
export class ApiCallable<T> {
  id: string;
  callApi: () => Promise<T>;

  constructor(id: string, callApi: () => Promise<T>) {
    this.id = id;
    this.callApi = callApi;
  }

  /**
   * Provides a different view by applying the given function to the return type.
   * The identifier remains invariant
   * @param func
   * @returns {ApiCallable}
   */
  map<S>(func: (value: T) => S): ApiCallable<S> {
    return new ApiCallable(this.id, async () => func(await this.callApi()));
  }

  static of(value: T): ApiCallable<T> {
    return new ApiCallable(`of_` + JSON.stringify(value), async () => value);
  }
}
