import moment from 'moment-timezone';
import { ISODateTime } from '../types/dates';

export type Sorter<S> = (x: S, y: S) => number; // eslint-disable-line
export type MapFunction<S, T> = (x: S) => T; // eslint-disable-line

export class Sorters {
  static transform<S, T>(sorter: Sorter<T>, mapFunc: MapFunction<S, T>): Sorter<S> {
    return (x: S, y: S) => sorter(mapFunc(x), mapFunc(y));
  }
  static reverse<S>(sorter: Sorter<S>): Sorter<S> {
    return (x: S, y: S) => -sorter(x, y);
  }
}
export class DateSorters {
  static asc() {
    return (x: ISODateTime, y: ISODateTime) => {
      const xStart = moment(x);
      const yStart = moment(y);
      return xStart.diff(yStart);
    };
  }
}
