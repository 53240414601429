import { Page, PageHeader } from '../../components/layout/page';
import React from 'react';
import { usePaymentList } from './states/PaymentListState';
import { PaneSubHeader } from '../../../mainv3/components/molecules/mypage/page';
import { LoaderRow } from '../../../mainv3/components/atoms/layout/LoaderRow';
import { CurrentCardSummary } from '../../../mainv3/components/organisms/payments/CurrentCardSummary';
import { CurrentCardPlaceholder } from '../../../mainv3/components/organisms/payments/CurrentCardPlaceholder';
import { observer } from 'mobx-react';
import { GlobalStatesV3 } from '../../../commonv3/states/GlobalStatesV3';
import { AccountRoutes } from '../../routes/AccountRoutes';
import { LegacyPaymentView } from '../../../mainv3/components/organisms/mypage/LegacyPaymentView';
import { gql } from 'urql';
import { useCurrentUserCreditCardQuery } from '../../../generated/graphql';
import { CardSummary } from '../../../mainv2/types/card';

gql`
  query CurrentUserCreditCard {
    currentUser {
      creditCard {
        brand
        expMonth
        expYear
        last4
      }
    }
  }
`;

export const PaymentListPage = observer(() => {
  const state = usePaymentList();
  const { paymentsResource, loading } = state;
  const payments = paymentsResource.getOrElse([]);

  const [creditCardResult] = useCurrentUserCreditCardQuery();
  const { data: creditCardData, fetching: creditCardFetching } = creditCardResult;
  const creditCard = creditCardData?.currentUser?.creditCard;
  return (
    <Page id="payment-list-page">
      <PageHeader>お支払い情報 / 変更</PageHeader>
      {creditCardFetching && <LoaderRow />}
      {creditCard && (
        <CurrentCardSummary
          cardSummary={creditCard as CardSummary}
          onRequestUpdate={() => GlobalStatesV3.routing.push(AccountRoutes.paymentInfo())}
        />
      )}
      {!creditCard && (
        <CurrentCardPlaceholder onRequestCreate={() => GlobalStatesV3.routing.push(AccountRoutes.paymentInfo())} />
      )}
      <PaneSubHeader title="お支払い履歴 / 予定" />
      {loading && <LoaderRow />}
      {payments.map((payment, i) => {
        if (payment.order_base_price > 0) {
          return (
            <LegacyPaymentView
              payment={payment}
              showAction={true}
              isHideBilling={true}
              retry_payment_route={'account'}
              key={i}
            />
          );
        }
      })}
    </Page>
  );
});
