import * as React from 'react';
import type { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { GlobalStatesV3 } from '../../../states/GlobalStatesV3';
import { Transition } from 'react-transition-group';
import classNames from 'classnames';
import './overlayPage.module.scss';
import autobind from 'autobind-decorator';
import { ModalSpec } from '../../../states/ModalState';
import { SheModal } from './SheModal';
import ResizeObserver from 'resize-observer-polyfill';
import { observable, makeObservable } from 'mobx';

type LayoutOptions = {
  verticallyCentered: boolean;
};

type BaseProps = {
  open: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRequestClose: () => any;
  childElem: (st: string, setRef: React.RefObject<HTMLDivElement>, options: LayoutOptions) => ReactNode;
};

export const OverlayContent = observer(
  class OverlayContent extends React.Component<BaseProps> {
    overlayElem: HTMLDivElement = null;
    modalElem: HTMLDivElement = null;
    modalCentered = false;

    constructor(props: BaseProps) {
      super(props);

      makeObservable(this, {
        modalCentered: observable,
      });
    }

    componentDidMount(): void {
      if (this.props.open) {
        GlobalStatesV3.modal.push(new ModalSpec(this.onActivate, this.onDeactivate));
      }
    }

    componentDidUpdate(prevProps: Readonly<BaseProps>): void {
      if (this.props.open !== prevProps.open) {
        if (this.props.open) {
          GlobalStatesV3.modal.push(new ModalSpec(this.onActivate, this.onDeactivate));
          this.adjustModalHeight();
        } else {
          GlobalStatesV3.modal.pop();
        }
      }
    }

    componentWillUnmount(): void {
      if (this.props.open) {
        GlobalStatesV3.modal.pop();
      }
    }

    @autobind
    private onActivate() {
      if (this.overlayElem) {
        this.overlayElem.classList.remove('overflow-hidden');
      }
    }

    @autobind
    private onDeactivate() {
      if (this.overlayElem) {
        this.overlayElem.classList.add('overflow-hidden');
      }
    }

    @autobind
    private updateModalRef(ref: HTMLDivElement) {
      this.modalElem = ref;
      if (ref) {
        const ro = new ResizeObserver(this.adjustModalHeight);
        ro.observe(ref);
        this.adjustModalHeight();
      }
    }
    @autobind
    private updateOverlayRef(ref: HTMLDivElement) {
      this.overlayElem = ref;
    }

    @autobind
    private adjustModalHeight() {
      if (this.modalElem && this.overlayElem) {
        if (this.modalElem.getBoundingClientRect().height < window.innerHeight * 0.8) {
          this.modalCentered = true;
        } else {
          this.modalCentered = false;
        }
      }
    }

    @autobind
    private handleOverlayClick(e) {
      if (this.modalElem) {
        if (!this.modalElem.contains(e.target)) {
          this.props.onRequestClose();
        }
      }
    }
    render() {
      const { open, childElem } = this.props;
      const verticallyCentered = this.modalCentered;
      return (
        <Transition in={open} timeout={400}>
          {(st) => (
            <div
              styleName={classNames('overlay', st, { centered: verticallyCentered })}
              onClick={this.handleOverlayClick}
              ref={this.updateOverlayRef}
            >
              {childElem(st, this.updateModalRef, { verticallyCentered })}
            </div>
          )}
        </Transition>
      );
    }
  }
);

type Props = {
  open: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRequestClose: () => any;
  id?: string;
  testId?: string;
};

const FullOverlay = observer(
  class FullOverlay extends React.Component<Props> {
    render() {
      const { open, onRequestClose, children, testId } = this.props;
      return (
        <OverlayContent
          open={open}
          onRequestClose={onRequestClose}
          childElem={(transitionStatus, setRef) => (
            <div styleName={classNames('full-overlay-body', transitionStatus)} ref={setRef} data-testid={testId}>
              {children}
            </div>
          )}
        />
      );
    }
  }
);

export const OverlayPage = observer(
  class OverlayPage extends React.Component<Props> {
    render() {
      const { open, onRequestClose, children, id, testId } = this.props;
      if (GlobalStatesV3.viewport.isDesktop) {
        return (
          <SheModal open={open} onRequestClose={onRequestClose} id={id} testId={testId}>
            {children}
          </SheModal>
        );
      } else {
        return (
          <FullOverlay open={open} onRequestClose={onRequestClose} testId={testId}>
            {children}
          </FullOverlay>
        );
      }
    }
  }
);
