import axios from 'axios';
import axiosJsonp from 'axios-jsonp';
type ZipcodeResult = {
  address1: string;
  address2: string;
  address3: string;
};
type ZipcodeResponse =
  | {
      status: 200;
      results: ZipcodeResult[];
    }
  | {
      status: 400 | 500;
      message: string;
    };

export const ZipCodeApis = {
  /**
   * 与えられた郵便番号をZipCloudで解決し、解決できればその結果を返す
   * @param zipcode
   * @return 解決結果が見つかればZipCodeResultを返す。見つからなければnull
   */
  async resolve(zipcode: string): Promise<ZipcodeResult | null> {
    const response: ZipcodeResponse = (
      await axios.get('https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + zipcode, {
        adapter: axiosJsonp,
      })
    ).data;
    if (response.status === 200) {
      if (response.results && response.results.length > 0) {
        return response.results[0];
      }
      return null;
    }
    throw new Error(response.message);
  },
};
