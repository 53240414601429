export const SheEnv = {
  get() {
    if (typeof window['RAILS_ENV'] === 'undefined') {
      return null;
    }
    return window['RAILS_ENV'];
  },
  isProduction() {
    return this.get() === 'production';
  },
  isStaging() {
    return this.get() === 'staging';
  },
  isDevelopment() {
    return this.get() === 'development';
  },
  isTest() {
    return this.get() === 'test';
  },
};
