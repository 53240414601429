import { action, computed, observable, makeObservable } from 'mobx';

export class DialogState {
  private _open = false;

  constructor(initialOpen = false) {
    makeObservable<DialogState, '_open'>(this, {
      _open: observable,
      isOpen: computed,
      open: action.bound,
      close: action.bound,
      toggle: action.bound,
    });

    this._open = initialOpen;
  }

  get isOpen() {
    return this._open;
  }

  open() {
    this._open = true;
  }

  close() {
    this._open = false;
  }

  toggle() {
    this._open = !this._open;
  }
}
