import { CommonsV1ApiBase } from './base';
import { EventId } from '../../commonv3/types/events';
import { EventReservationIndexView } from '../types/eventReservations';
import { EventReservationId } from '../../commonv3/types/eventReservations';
import { Service } from '../../commonv3/types/service';

export type CancelRequest = {
  cancel_reason: string;
  cancel_survey_submission_id: number;
};

export const EventReservationApis = {
  base() {
    return CommonsV1ApiBase.appendPath('users/current/event_reservations');
  },
  findAll(services?: Service[]): Promise<EventReservationIndexView[]> {
    return this.base().get({ services: services ? services.join(',') : undefined });
  },
  find(id: EventReservationId): Promise<EventReservationIndexView> {
    return this.base().withId(id).get();
  },
  findByDate(date: Date): Promise<EventReservationIndexView[]> {
    return this.base().get({ event_date: new Date(date) });
  },
  create(eventId: EventId): Promise<EventReservationIndexView> {
    return this.base().post({ event_id: eventId });
  },
  cancel(
    reservationId: EventReservationId,
    cancellationParams: Partial<CancelRequest> = {}
  ): Promise<EventReservationIndexView> {
    return this.base()
      .withId(reservationId)
      .put({ status: 'cancelled', ...cancellationParams });
  },
  attend(reservationId: EventReservationId): Promise<EventReservationIndexView> {
    return this.base().withId(reservationId).put({ status: 'attended' });
  },
};
