import { MainV3ApiBase } from './base';
import { ResetCodeShowView } from '../types/resetCodes';

export const PasswordApis = {
  base() {
    return MainV3ApiBase.appendPath('passwords');
  },
  /**
   * Request Reset code
   * @param {string} email : the email
   * @returns {Promise<ResetCodeShowView>}
   */
  async requestResetCode(email: string): Promise<ResetCodeShowView> {
    return await this.base().appendPath('reset').post({ email: email });
  },

  /**
   * Validate Reset code
   * @param {string} reset_code : the 6 digits reset code
   * @param {string} token : the token (UUID)
   * @returns {Promise<ResetCodeShowView>}
   */
  async validateResetCode(reset_code: string, token: string): Promise<ResetCodeShowView> {
    return await this.base().appendPath('validate_code').post({ reset_code, token });
  },

  /**
   * Resend Reset code
   * @param {string} token : the token
   * @returns {Promise<ResetCodeShowView>}
   */
  async resendResetCode(token: string): Promise<ResetCodeShowView> {
    return await this.base().appendPath('resend_code').post({ token });
  },

  /**
   * Reset Password
   * @param {string} reset_code : the 6 digits reset code
   * @param {string} token : the token
   * @param {string} password : the new password
   * @param {string} password_confirmation : the new password (confirmation)
   */
  async resetPassword(reset_code: string, token: string, password: string, password_confirmation: string) {
    return await this.base().appendPath('reset_password').post({
      reset_code,
      token,
      password,
      password_confirmation,
    });
  },
};
