import React, { useEffect } from 'react';
import { gql } from 'urql';
import { UserAddressInput, useUpdateCurrentUserMutation } from '../../../../generated/graphql';
import { ZipCodeApis } from '../../../apis/zipcodes';
import { UserAddress } from '../../../types/userAddresses';
import { useUserPersonalForm } from '../../organisms/mypage/hooks/useUserPersonalForm';
import { useFormSubmit } from '../../../../../app/_components/_rhf/useFormSubmit';
import { useSubmitting } from '../../../../../app/_components/_rhf/useSubmitting';
import { TextInputGroup } from '@sheinc/shelikes-design-system';
import { TextInputField } from '../../../../../app/_components/_rhf/TextInputField';
import { ButtonWrapper, SubmitButton } from '../../atoms/forms/vertical/base';

gql`
  mutation UpdateCurrentUser(
    $firstName: String
    $firstNameKana: String
    $lastName: String
    $lastNameKana: String
    $phoneNumber: String
    $userAddress: UserAddressInput
  ) {
    updateCurrentUser(
      input: {
        firstName: $firstName
        firstNameKana: $firstNameKana
        lastName: $lastName
        lastNameKana: $lastNameKana
        phoneNumber: $phoneNumber
        userAddress: $userAddress
      }
    ) {
      currentUser {
        firstName
        firstNameKana
        lastName
        lastNameKana
        phoneNumber
        userAddress {
          zipcode
          prefecture
          city
          address
          building
        }
      }
      errors {
        message
        path
      }
    }
  }
`;

type Props = {
  firstName: string;
  firstNameKana: string;
  lastName: string;
  lastNameKana: string;
  phoneNumber: string;
  userAddress: UserAddress;
};

export const UserPersonalForm = ({
  firstName,
  firstNameKana,
  lastName,
  lastNameKana,
  phoneNumber,
  userAddress,
}: Props) => {
  const [, updateCurrentUser] = useUpdateCurrentUserMutation();
  const form = useUserPersonalForm();
  const { control, reset, handleSubmit } = form;

  const { submit: update } = useFormSubmit(form, (values) => {
    const currentUserVariables = {};

    if (!firstName) currentUserVariables['firstName'] = values.firstName;
    if (!firstNameKana) currentUserVariables['firstNameKana'] = values.firstNameKana;
    if (!lastName) currentUserVariables['lastName'] = values.lastName;
    if (!lastNameKana) currentUserVariables['lastNameKana'] = values.lastNameKana;
    if (!phoneNumber) currentUserVariables['phoneNumber'] = values.phoneNumber;

    const userAddressInputValue: UserAddressInput = {
      zipcode: values.userAddress.zipcode,
      prefecture: values.userAddress.prefecture,
      city: values.userAddress.city,
      address: values.userAddress.address,
      building: values.userAddress.building,
    };
    if (!userAddress) currentUserVariables['userAddress'] = userAddressInputValue;

    return updateCurrentUser(currentUserVariables);
  });

  const { submitting, submit: handleUpdate } = useSubmitting(async () => {
    try {
      const result = await update();
      const currentUser = result.data?.updateCurrentUser?.currentUser;
      firstName = currentUser?.firstName as string;
      firstNameKana = currentUser?.firstNameKana as string;
      lastName = currentUser?.lastName as string;
      lastNameKana = currentUser?.lastNameKana as string;
      phoneNumber = currentUser?.phoneNumber as string;
      userAddress = currentUser?.userAddress as UserAddress;
    } catch {
      alert('エラーが発生しました');
    }
  });

  const autoSetAddress = async (zipcode: string) => {
    const result = await ZipCodeApis.resolve(zipcode).catch(() => {
      return;
    });
    if (result) {
      form.setValue('userAddress.prefecture', result.address1);
      form.setValue('userAddress.city', result.address2);
      form.setValue('userAddress.address', result.address3);
    }
  };

  useEffect(() => {
    reset({
      firstName: firstName || '',
      firstNameKana: firstNameKana || '',
      lastName: lastName || '',
      lastNameKana: lastNameKana || '',
      phoneNumber: phoneNumber || '',
      userAddress: {
        zipcode: userAddress?.zipcode || '',
        prefecture: userAddress?.prefecture || '',
        city: userAddress?.city || '',
        address: userAddress?.address || '',
        building: userAddress?.building || '',
      },
    });
  }, [firstName, firstNameKana, lastName, lastNameKana, phoneNumber, userAddress]);

  return (
    <div>
      <TextInputGroup gapSize="M">
        <TextInputField
          name="lastName"
          control={control}
          label="姓"
          rules={{
            required: '姓を入力してください',
          }}
          disabled={!!lastName}
          testId="last-name-form"
        />
        <TextInputField
          name="firstName"
          control={control}
          label="名"
          rules={{
            required: '名を入力してください',
          }}
          disabled={!!firstName}
          testId="first-name-form"
        />
        <TextInputField
          name="lastNameKana"
          control={control}
          label="姓カナ"
          rules={{
            required: '姓カナを入力してください',
          }}
          disabled={!!lastNameKana}
          testId="last-name-kana-form"
        />
        <TextInputField
          name="firstNameKana"
          control={control}
          label="名カナ"
          rules={{
            required: '名カナを入力してください',
          }}
          disabled={!!firstNameKana}
          testId="first-name-kana-form"
        />
        <TextInputField
          name="phoneNumber"
          control={control}
          label="電話番号"
          disabled={!!phoneNumber}
          testId="phone-number-form"
        />
        <TextInputField
          name="userAddress.zipcode"
          control={control}
          placeholder="郵便番号"
          label="郵便番号"
          rules={{
            required: '郵便番号を入力してください',
            validate: async () => {
              const result = await ZipCodeApis.resolve(form.getValues('userAddress.zipcode')).catch(() => {
                return false;
              });
              if (!result) return '無効な郵便番号です';
            },
            onBlur: (e) => autoSetAddress(e.target.value),
          }}
          disabled={!!userAddress}
          testId="zipcode-form"
        />
        <TextInputField
          name="userAddress.prefecture"
          control={control}
          placeholder="入力してください"
          label="都道府県"
          rules={{
            required: '都道府県を入力してください',
          }}
          disabled={!!userAddress}
          testId="prefecture-form"
        />
        <TextInputField
          name="userAddress.city"
          control={control}
          placeholder="港区"
          label="市区町村"
          rules={{
            required: '市区町村を入力してください',
          }}
          disabled={!!userAddress}
          testId="city-form"
        />
        <TextInputField
          name="userAddress.address"
          control={control}
          placeholder="xxx-x"
          label="番地"
          rules={{
            required: '番地を入力してください',
          }}
          disabled={!!userAddress}
          testId="address-form"
        />
        <TextInputField
          name="userAddress.building"
          control={control}
          placeholder={userAddress ? '' : '〇〇ビルディング'}
          label="建物名"
          disabled={!!userAddress}
          testId="building-form"
        />
      </TextInputGroup>
      <ButtonWrapper>
        <SubmitButton id="submit-button" loading={submitting} onClick={handleSubmit(handleUpdate)}>
          登録する
        </SubmitButton>
      </ButtonWrapper>
    </div>
  );
};
