import { MainV3ApiBase } from './base';
import { PaymentIndexView, PaymentShowView } from '../types/payments';
import { Service } from '../../commonv3/types/service';

export const PaymentApis = {
  base() {
    return MainV3ApiBase.appendPath('users/current/payments');
  },
  findAll(services?: Service[]): Promise<PaymentIndexView[]> {
    return this.base().get({ services: services ? services.join(',') : undefined });
  },
  show(code: string): Promise<PaymentShowView> {
    return this.base().withId(code).get();
  },
  create(code: string): Promise<PaymentShowView> {
    return this.base().post({
      id: code,
    });
  },
};
