import React from 'react';
import { FieldStateV3 } from '../../../../../commonv3/states/form/FormStateV3';
import { observer } from 'mobx-react-lite';
import { SectionLabel, FormSection, FormErrors, FormError, SectionBody } from './base';
import './stringField.module.scss';

type Props = {
  label: string;
  type: 'text' | 'password';
  placeholder?: string;
  state: FieldStateV3<string>;
};

export const StringFieldInput = observer(({ label, type, placeholder, state }: Props) => (
  <FormSection>
    <SectionLabel>{label}</SectionLabel>
    <SectionBody>
      <input
        styleName="input"
        id={state.id}
        name={state.id}
        type={type}
        placeholder={placeholder}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        value={state.getDirtyValue()!}
        onBlur={() => state.validate()}
        onChange={(e) => state.setDirtyValue(e.target.value)}
      />
      {state.hasErrors() && (
        <FormErrors>
          {state.getErrors().map((error, idx) => (
            <FormError key={idx}>{error}</FormError>
          ))}
        </FormErrors>
      )}
    </SectionBody>
  </FormSection>
));
