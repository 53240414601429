import * as React from 'react';
import './questionCardFormat.module.scss';
import { QuestionNumberIndicator } from '../../atoms/surveys/QuestionNumberIndicator';
import { OptionalLabel, RequiredLabel } from '../../atoms/surveys/QuestionLabel';
import { QuestionError } from '../../atoms/surveys/QuestionError';
import { useRef, useState } from 'react';
import { YoutubeIframe } from './youtubeIframe';

type Props = {
  questionNumber: number;
  totalQuestions: number;
  sentence: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subSentence?: any;
  youtubeKey?: string;
  required: boolean;
  errorMessage?: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur?: () => any;
  className?: string;
  bodyClassName?: string;
  styleName?: string;
  useOuterClickHandler: <T extends HTMLElement>(ref: React.RefObject<T>, handler: () => void) => void;
};

export const CardFormat = ({
  questionNumber,
  totalQuestions,
  sentence,
  subSentence,
  youtubeKey,
  required,
  errorMessage,
  className,
  bodyClassName,
  children,
  onBlur,
  useOuterClickHandler,
}: Props) => {
  const [focused, setFocused] = useState(false);
  const ref = useRef(null);
  useOuterClickHandler(ref, () => {
    if (focused) {
      onBlur && onBlur();
      setFocused(false);
    }
  });
  return (
    <div
      styleName="question-card"
      className={className}
      ref={ref}
      onFocus={() => setFocused(true)}
      onClick={() => setFocused(true)}
    >
      <div styleName="question-header">
        <QuestionNumberIndicator
          questionNumber={questionNumber}
          totalQuestions={totalQuestions}
          styleName="number-indicator"
        />
        {required ? <RequiredLabel styleName="label" /> : <OptionalLabel styleName="label" />}
        <div styleName="sentence">{sentence}</div>
        {subSentence && <div styleName="sub-sentence" dangerouslySetInnerHTML={{ __html: subSentence }}></div>}
        {youtubeKey && <YoutubeIframe youtubeKey={youtubeKey} />}
        {errorMessage && <QuestionError styleName="error">{errorMessage}</QuestionError>}
      </div>
      <div styleName="question-body" className={bodyClassName}>
        {children}
      </div>
    </div>
  );
};
