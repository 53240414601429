import { observable, action, makeObservable } from 'mobx';
import { UserInfoApis } from '../../../../mainv3/apis/user_info';
import { ProfileChangeFormState } from './ProfileChangeFormState';
import { RemoteResourceV3, updateRemoteResourceV3 } from '../../../../commonv3/api/RemoteResourceV3';
import { CurrentProfile, NewProfile } from '../../../../mainv3/types/user_info';
import { GlobalStatesV3 } from '../../../../commonv3/states/GlobalStatesV3';

export class ProfileChangePageState {
  currentProfile: RemoteResourceV3<CurrentProfile> = RemoteResourceV3.loading();
  newProfile?: Blob | null = null;
  newProfileUrl?: string | null = null;
  formState: ProfileChangeFormState = new ProfileChangeFormState();
  submitting = false;
  birthdayPersisted = false;

  constructor() {
    makeObservable(this, {
      currentProfile: observable,
      newProfile: observable,
      newProfileUrl: observable,
      formState: observable,
      submitting: observable,
      birthdayPersisted: observable,
      init: action.bound,
      reloadProfileUrl: action.bound,
      setProfileImage: action.bound,
      submit: action.bound,
    });
  }

  async init() {
    this.reloadProfileUrl();
  }

  async reloadProfileUrl() {
    const currentProfile = await updateRemoteResourceV3(
      this.currentProfile,
      (v) => (this.currentProfile = v),
      () => UserInfoApis.findProfile()
    );

    const newProfile: NewProfile = {
      id: currentProfile.id || '',
      name: currentProfile.name || '',
      birthday: currentProfile.birthday || '',
      job_company: currentProfile.job_company || '',
      job_department: currentProfile.job_department || '',
      job_title: currentProfile.job_title || '',
    };

    this.formState.setValues(newProfile);

    this.birthdayPersisted = (currentProfile.birthday || '') !== '';
  }

  async setProfileImage(files: FileList | null) {
    if (files && files[0]) {
      const [file] = Array.from(files);
      this.newProfileUrl = window.URL.createObjectURL(file);
      this.newProfile = file;
    }
  }

  async submit() {
    this.submitting = true;
    try {
      this.formState.clearErrors();
      try {
        const formData = new FormData();
        formData.append('name', this.formState.getValue('name'));
        formData.append('birthday', this.formState.getValue('birthday'));
        formData.append('job_company', this.formState.getValue('job_company'));
        formData.append('job_department', this.formState.getValue('job_department'));
        formData.append('job_title', this.formState.getValue('job_title'));
        if (this.newProfile) {
          formData.append('profile', this.newProfile);
        }
        await UserInfoApis.updateProfile(formData);

        this.birthdayPersisted = (this.formState.getValue('birthday') || '') !== '';
      } catch (error) {
        this.formState.setErrors(error.body);
      }
      GlobalStatesV3.currentUser.reloadUser();
    } finally {
      this.submitting = false;
    }
  }
}
