import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { CreditCardState } from './states/CreditCardFormState';
import './cardInput.module.scss';
import { BrandIcon } from '../../../../mainv2/containers/common/payment/CardSummaryView';
import { Button } from '../../../../commonv3/components/atoms/buttons/Button';
import type { CardSummary as CardSummaryType } from '../../../../mainv2/types/card';

type CardSummaryProps = {
  card: CardSummaryType;
  modifiable?: boolean;
  onModifyRequest?: () => void;
  className?: string;
};

export const CardSummary = ({
  card,
  modifiable = false,
  onModifyRequest = () => null,
  className = undefined,
}: CardSummaryProps) => (
  <div styleName="card-summary" className={className}>
    <div styleName="summary-main">
      <BrandIcon styleName="brand-icon" brand={card.brand} />
      <div styleName="number-mask">
        {'●'.repeat(4)} {'●'.repeat(4)} {card.last4}
      </div>
    </div>
    {modifiable && (
      <Button type="normal" size="s" onClick={onModifyRequest}>
        変更する
      </Button>
    )}
  </div>
);

type CardInputProps = {
  creditCardState: CreditCardState;
};

export const CardInput = observer(({ creditCardState }: CardInputProps) => {
  useEffect(() => {
    creditCardState.setCardInputErrorMessage({ number: '', expiry: '' });
    creditCardState.setCardInputComplete({ number: false, expiry: false, cvc: false });
    const elements = payjp.elements();
    const numberElement = elements.create('cardNumber', { placeholder: '例：1234 1234 1234 1234' });
    const expiryElement = elements.create('cardExpiry', { placeholder: '月（MM）/ 年（YY）' });
    const cvcElement = elements.create('cardCvc', { placeholder: '例：123' });
    numberElement.mount('#number-form');
    expiryElement.mount('#expiry-form');
    cvcElement.mount('#cvc-form');
    numberElement.on('change', (e) => {
      creditCardState.setCardInputErrorMessage({ number: e.error?.message || '' });
      creditCardState.setCardInputComplete({ number: e.complete });
    });
    expiryElement.on('change', (e) => {
      creditCardState.setCardInputErrorMessage({ expiry: e.error?.message || '' });
      creditCardState.setCardInputComplete({ expiry: e.complete });
    });
    cvcElement.on('change', (e) => {
      creditCardState.setCardInputComplete({ cvc: e.complete });
    });
    creditCardState.setCardElement(numberElement);
  }, []);
  return (
    <div styleName="form">
      <div>
        {['visa', 'master', 'american', 'diners', 'jcb'].map((b) => (
          <BrandIcon key={b} styleName="brand-icon" brand={b} />
        ))}
      </div>
      <div styleName="form-row">
        <div>
          <div styleName="label">カード番号</div>
          <div id="number-form" styleName="payjs-outer"></div>
          <div styleName="form-error" className="form-error">
            {creditCardState.cardInputErrorMessage.number}
          </div>
        </div>
      </div>
      <div styleName="form-row">
        <div styleName="label">有効期限</div>
        <div styleName="form-row-body">
          <div styleName="input-wrapper">
            <div id="expiry-form" styleName="payjs-outer"></div>
            <div styleName="form-error" className="form-error">
              {creditCardState.cardInputErrorMessage.expiry}
            </div>
          </div>
        </div>
      </div>
      <div styleName="form-row">
        <div styleName="label">セキュリティコード</div>
        <div styleName="form-row-body">
          <div styleName="input-wrapper security">
            <div id="cvc-form" styleName="payjs-outer"></div>
          </div>
        </div>
      </div>
    </div>
  );
});

type CreditCardInputProps = {
  cardState: CreditCardState;
};

export const CreditCardInput = observer(({ cardState }: CreditCardInputProps) => {
  const { editMode, card, setEditMode } = cardState;
  if (editMode) {
    return <CardInput creditCardState={cardState} />;
  }
  if (!card) {
    return null;
  }
  return <CardSummary card={card} modifiable={true} onModifyRequest={() => setEditMode(true)} />;
});
