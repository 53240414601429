import { action, computed, observable, makeObservable } from 'mobx';
import { RemoteResourceV3, updateRemoteResourceV3 } from '../../../../commonv3/api/RemoteResourceV3';
import { EventReservationIndexView } from '../../../../mainv3/types/eventReservations';
import { SubmitState } from '../../../../commonv3/states/SubmitState';
import { EventReservationApis } from '../../../../mainv3/apis/eventReservations';
import { Service } from '../../../../commonv3/types/service';
import { AllReservationIndexView } from '../../../../mainv3/components/molecules/reservations/ReservationCardList';
import { createContext, useContext, useEffect } from 'react';
import { DialogState } from '../../../../commonv3/states/dialogState';
import { ReservableType } from '../../../../commonv3/types/reservable';
import { EventReservationId } from '../../../../commonv3/types/eventReservations';
import { ReservationId } from '../../../../commonv3/types/reservations';
import { ReservationIndexView } from '../../../../mainv3/types/reservations';
import { translateErrorMessage } from '../../../../commonv3/api/protocol';
import { ReservationApis } from '../../../../mainv3/apis/reservations';
import { DateSorters, Sorters } from '../../../../commonv3/utils/sorters';
import { AccountRoutes } from '../../../routes/AccountRoutes';
import { SurveyKeys, SurveyKeysTrialLessonReservationCancel } from '../../../../commonv3/types/surveys';
import { TrialLessonIndexView } from '../../../../mainv3/types/trialLessons';
import { MembershipPlanGroupKey } from '../../../../mainv3/types/membershipPlans';
import { BeautyRoutes } from '../../../../mainv3/routes';
import { CancelStep } from '../../../../commonv3/types/cancel';

export class ReservationListState {
  eventReservations: RemoteResourceV3<EventReservationIndexView[]> = RemoteResourceV3.loading();
  reservations: RemoteResourceV3<ReservationIndexView[]> = RemoteResourceV3.loading();
  processingState: SubmitState = new SubmitState();
  initState: SubmitState = new SubmitState();
  initialized = false;
  cancelDialog: DialogState = new DialogState(false);
  cancellingReservableType: ReservableType | undefined = undefined;
  cancellingReservationId: EventReservationId | ReservationId | null = null;
  cancellingReservationService: MembershipPlanGroupKey | null = null;
  cancelStep: CancelStep | null = null;
  reservationCancelCompletePath = AccountRoutes.reservations();

  constructor() {
    makeObservable(this, {
      eventReservations: observable,
      reservations: observable,
      processingState: observable,
      initState: observable,
      initialized: observable,
      cancelDialog: observable,
      cancellingReservableType: observable,
      cancellingReservationId: observable,
      cancellingReservationService: observable,
      cancelStep: observable,
      init: action.bound,
      confirmCancel: action.bound,
      completeCancelSurvey: action.bound,
      requestRescheduleTrialReservation: action.bound,
      requestCancel: action.bound,
      reservationCancelSurveyKey: computed,
      isTrialLessonCancelled: computed,
      isBeautyCancelled: computed,
      allReservationList: computed,
      loading: computed,
      submitting: computed,
      initializing: computed,
      submitCancel: action.bound,
      cancellingReservation: computed,
    });
  }

  async init() {
    await this.initState.operate(async () => {
      // 本来このページは全ての予約を表示するべきであるが、暫定的にBEAUTYの予約のみを表示する
      await Promise.all([
        updateRemoteResourceV3(
          this.eventReservations,
          (v) => (this.eventReservations = v),
          () => EventReservationApis.findAll([Service.BEAUTY, Service.MONEY])
        ),
        updateRemoteResourceV3(
          this.reservations,
          (v) => (this.reservations = v),
          () => ReservationApis.findAll([Service.BEAUTY, Service.MONEY])
        ),
      ]);
    });
    this.initialized = true;
  }

  async confirmCancel() {
    if (this.reservationCancelSurveyKey) {
      this.cancelStep = CancelStep.FORM;
    } else {
      await this.submitCancel();
    }
  }

  async completeCancelSurvey() {
    this.init();
    this.cancelStep = CancelStep.COMPLETE;
  }

  async requestRescheduleTrialReservation() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const pathname = BeautyRoutes.trialLessonReschedule(this.cancellingReservation!.reservable.id as number).pathname;

    window.location.replace(pathname as string);
  }

  requestCancel(reservation: EventReservationIndexView | ReservationIndexView) {
    this.cancelStep = CancelStep.CONFIRMATION;
    this.cancellingReservableType = reservation.reservable_type;
    this.cancellingReservationId = reservation.id;
    this.cancelDialog.open();
    this.cancellingReservationService = null;

    if (this.isTrialLessonCancelled) {
      const reservable = reservation.reservable as TrialLessonIndexView;

      this.cancellingReservationService = reservable.membership_plan_group_keys[0];
    }
  }

  get reservationCancelSurveyKey(): SurveyKeys | null {
    if (this.isTrialLessonCancelled && this.isBeautyCancelled) {
      return SurveyKeysTrialLessonReservationCancel[Service.BEAUTY];
    }

    return null;
  }

  get isTrialLessonCancelled(): boolean {
    return this.cancellingReservableType === ReservableType.TRIAL_LESSON;
  }

  get isBeautyCancelled(): boolean {
    return this.cancellingReservationService === MembershipPlanGroupKey.BEAUTY;
  }

  get allReservationList(): AllReservationIndexView[] {
    return [...this.eventReservations.getOrElse([]), ...this.reservations.getOrElse([])].sort(
      Sorters.transform(DateSorters.asc(), (x) => x.reservable.starts_at)
    );
  }

  get loading(): boolean {
    return this.eventReservations.loading || this.reservations.loading;
  }

  get submitting(): boolean {
    return this.processingState.submitting;
  }

  get initializing(): boolean {
    return this.initState.submitting;
  }

  async submitCancel() {
    await this.processingState.operate(async () => {
      try {
        switch (this.cancellingReservableType) {
          case ReservableType.EVENT:
          case ReservableType.TRIAL_LESSON:
            await EventReservationApis.cancel(this.cancellingReservationId as number);
            break;
          case ReservableType.SESSION:
            await ReservationApis.cancel(this.cancellingReservationId as number);
            break;
        }
        await this.init();
      } catch (e) {
        alert('キャンセルに失敗しました：' + translateErrorMessage(e));
      } finally {
        this.cancelStep = CancelStep.COMPLETE;
      }
    });
  }

  get cancellingReservation() {
    return this.allReservationList.find(
      (r) => r.id == this.cancellingReservationId && r.reservable_type == this.cancellingReservableType
    );
  }
}

const ReservationListContext = createContext(new ReservationListState());

/**
 * 共通マイページ用の予約一覧ステートを取得する。
 * Contextを利用して、ツリーのどこから読んでも、同じステートを参照できるようになっている。
 * 最初にこのコンポーネントを読み込んだ時点で初期化される。
 * @return ReservationListState
 */
export const useReservationListState = (): ReservationListState => {
  const state = useContext(ReservationListContext);
  useEffect(() => {
    if (!state.initialized && !state.initializing) {
      state.init();
    }
  }, []);
  return state;
};
