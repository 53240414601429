import { FacebookApis } from './../../adminv3/api/facebook';
import { SheRavenClient } from './ravenClient';
import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const fbq: any;

export const FacebookAnalyticsClient = {
  /**
   * Send the specified event to facebook analytics
   * @param functions: The type of event (e.g. init track trackCustom )
   * @param eventName: The type of tacking (e.g. 'PageView')
   * @param customData: Detailed tracking information
   * see https://developers.facebook.com/docs/facebook-pixel
   */
  async sendFacebookEvent(
    functions: string,
    eventName: string,
    customData?: Record<string, unknown>,
    testEventCode?: string
  ) {
    const event_id = uuidv4();
    if (typeof fbq !== 'undefined') {
      try {
        // Pixelイベント送信
        fbq(functions, eventName, customData, { eventID: event_id });
      } catch {
        SheRavenClient.captureException(new Error(`Facebook Pixel error`), {
          functions,
          eventName,
          fields: customData,
        });
      }
    } else {
      SheRavenClient.captureException(new Error('sendFbqEvent called without ga set up'), {
        functions,
        eventName,
        fields: customData,
      });
    }
    try {
      // CAPIイベント送信
      await FacebookApis.send({
        event_id: event_id,
        event_name: eventName,
        custom_data: customData || {},
        test_event_code: testEventCode,
      });
    } catch (e) {
      // 試験運用を経て、特にクリティカルなエラーは検知できないことがわかったためIgnore.
      // SheRavenClient.captureException(new Error(`Facebook Conversion API error: ${e.message}`), {
      //     functions,
      //     eventName,
      //     fields: customData,
      // });
    }
  },
};
