import { GlobalStates } from '../../mainv2/states/globalStates';
import { ViewportStateV3 } from './ViewportStateV3';
import { routingStore } from '../../common/stores/routing';
import { ModalState } from './ModalState';
import { JwtState } from './JwtState';
import { CurrentUserState } from '../../mainv2/states/CurrentUserState';
import { RouterStore } from '../../common/mobx-react-router';

type GlobalStatesV3 = {
  currentUser: CurrentUserState;
  viewport: ViewportStateV3;
  modal: ModalState;
  routing: RouterStore;
  jwt: JwtState;
};

export const GlobalStatesV3: GlobalStatesV3 = {
  currentUser: GlobalStates.currentUser,
  // @ts-expect-error initializeGlobalStateV3 が最初に呼ばれる想定
  viewport: undefined,
  // @ts-expect-error initializeGlobalStateV3 が最初に呼ばれる想定
  modal: undefined,
  routing: routingStore,
  // @ts-expect-error initializeGlobalStateV3 が最初に呼ばれる想定
  jwt: undefined,
};

export const initializeGlobalStateV3 = () => {
  GlobalStatesV3.viewport = new ViewportStateV3();
  GlobalStatesV3.modal = new ModalState();
  GlobalStatesV3.jwt = new JwtState();
};
