import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './radioCard.module.scss';
import RadioCheckedSvg from './images/radio-checked.svg';
import RadioBlankSvg from './images/radio-blank.svg';
import { MultiLineStringInput } from '../../../../commonv3/components/atoms/forms/primitive/StringInput';

type RadioButtonProps = {
  checked: boolean;
  onCheck?: (checked: boolean) => void;
  className?: string;
  styleName?: string;
};

export const RadioButton = ({ checked, onCheck, className = undefined }: RadioButtonProps) => (
  <img
    styleName={classNames('radio', { checked: checked })}
    onClick={onCheck !== undefined ? () => onCheck(!checked) : undefined}
    className={className}
    src={checked ? RadioCheckedSvg : RadioBlankSvg}
  />
);

type Props = {
  id?: string;
  checked: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  label: any;
  subLabel?: ReactNode;
  onCheck?: (checked: boolean) => void;
  widthType?: 's' | 'm' | 'l';
  className?: string;
  styleName?: string;
  'data-value'?: string;
  isRecommended?: boolean;
  description?: ReactNode;
};

export const RadioCard = ({
  id,
  label,
  subLabel,
  checked,
  onCheck,
  className,
  widthType = 'm',
  'data-value': dataAttribute,
  isRecommended = false,
  description,
}: Props) => (
  <div
    id={id}
    styleName={classNames('radio-card', widthType, { checked: checked })}
    className={className}
    onClick={() => onCheck?.(!checked)}
    data-value={dataAttribute}
  >
    <div styleName="label">
      <RadioButton checked={checked} onCheck={onCheck} styleName="radio" />
      {label}
      {subLabel && (
        <div styleName={classNames('radio-recommend-label', { 'radio-recommend-label-small': !isRecommended })}>
          {subLabel}
        </div>
      )}
    </div>
    {description && <div styleName="description">{description}</div>}
  </div>
);

type FreeAnswerProps = Props & {
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
};

export const FreeAnswerRadioCard = ({
  id,
  label,
  checked,
  onCheck,
  value,
  onChange,
  placeholder,
  className,
  widthType = 'm',
}: FreeAnswerProps) => (
  <div
    id={id}
    styleName={classNames('radio-card-fa', widthType, { checked: checked })}
    className={className}
    onClick={() => onCheck?.(!checked)}
  >
    <div styleName="fa-top">
      <RadioButton checked={checked} onCheck={onCheck} styleName="radio" />
      <div styleName="label">{label}</div>
    </div>
    <div styleName="fa-bottom">
      <MultiLineStringInput
        id={`${id || 'free-answer'}-input`}
        styleName="fa-input"
        value={value}
        placeholder={placeholder}
        onChange={(v) => {
          onChange(v);
          onCheck?.(!!v && v !== '');
        }}
        onClick={(e) => e.stopPropagation()}
      />
    </div>
  </div>
);
