import { action, computed, observable, makeObservable } from 'mobx';
import { GlobalStatesV3 } from '../../../../commonv3/states/GlobalStatesV3';
import { SurveySubmissionApis } from '../../../apis/surveySubmissions';
import { SurveyState } from './SurveyState';
import { SubmitState } from '../../../../commonv3/states/SubmitState';
import { translateErrorMessage } from '../../../../commonv3/api/protocol';
import { EventReservationApis } from '../../../apis/eventReservations';
import { ReservationListState } from '../../../components/organisms/home/states/ReservationListState';
import { ReservationListState as AccountReservationListState } from '../../../../account/containers/reservation/states/ReservationListState';

export class ReservationCancelSurveyPageState {
  submitState = new SubmitState();
  routerState = GlobalStatesV3.routing;
  success = false;
  reservationId;
  surveyState = new SurveyState();
  reservationListState: ReservationListState | AccountReservationListState = null;

  constructor() {
    makeObservable(this, {
      submitState: observable,
      routerState: observable,
      success: observable,
      reservationId: observable,
      init: action.bound,
      hasSurvey: computed,
      submit: action.bound,
    });
  }

  async init(reservationListState: ReservationListState | AccountReservationListState) {
    this.reservationListState = reservationListState;
    await this.surveyState.init(this.reservationListState.reservationCancelSurveyKey);
  }

  get hasSurvey(): boolean {
    return !this.surveyState.survey.isEmpty() && !this.surveyState.parameterParseError;
  }

  async submit() {
    try {
      await this.surveyState.validate();
      if (!this.surveyState.hasErrors) {
        const surveyKey = this.surveyState.hasSurvey ? this.surveyState.surveyKey : null;
        await this.submitState.operate(async () => {
          const survey_submission = await SurveySubmissionApis.create(
            surveyKey,
            this.surveyState.parameters,
            this.surveyState.questionAnswers
          );
          await EventReservationApis.cancel(this.reservationId, {
            cancel_survey_submission_id: survey_submission.id,
          });
          await this.reservationListState.completeCancelSurvey();
          this.success = true;
        });
      }
    } catch (e) {
      alert('予約のキャンセルに失敗しました: ' + translateErrorMessage(e));
    }
  }
}
