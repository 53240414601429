export enum Service {
  LIKES = 'likes',
  BEAUTY = 'beauty',
  MONEY = 'money',
  MULTICREATOR = 'multicreator',
}

export const serviceName = {
  likes: 'SHElikes',
  beauty: 'SHEbeauty',
  money: 'SHEmoney',
  multicreator: 'MULTI CREATOR COURSE Designer',
};
