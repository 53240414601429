import * as React from 'react';
import './questionCardFormat.module.scss';
import { OptionalLabel, RequiredLabel } from '../../atoms/surveys/QuestionLabel';
import { QuestionError } from '../../atoms/surveys/QuestionError';
import { useRef, useState } from 'react';
import { YoutubeIframe } from './youtubeIframe';

type Props = {
  questionNumber: number;
  totalQuestions: number;
  sentence: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subSentence?: any;
  youtubeKey?: string;
  required: boolean;
  errorMessage?: string | null;
  children?: React.ReactNode;
  onBlur?: () => void;
  className?: string;
  bodyClassName?: string;
  styleName?: string;
  useOuterClickHandler: <T extends HTMLElement>(ref: React.RefObject<T>, handler: () => void) => void;
};

export const OneByOneFormat = ({
  questionNumber,
  totalQuestions,
  sentence,
  subSentence,
  youtubeKey,
  required,
  errorMessage,
  className,
  bodyClassName,
  children,
  onBlur,
  useOuterClickHandler,
}: Props) => {
  const [focused, setFocused] = useState(false);
  const ref = useRef(null);
  const progress = totalQuestions - questionNumber;
  useOuterClickHandler(ref, () => {
    if (focused) {
      onBlur && onBlur();
      setFocused(false);
    }
  });
  return (
    <div
      styleName="question-card"
      className={className}
      ref={ref}
      onFocus={() => setFocused(true)}
      onClick={() => setFocused(true)}
    >
      <div styleName="question-header-one-question">
        {required ? <RequiredLabel styleName="label" /> : <OptionalLabel styleName="label" />}
        <div styleName="sentence-one-question">{sentence}</div>
        {youtubeKey && <YoutubeIframe youtubeKey={youtubeKey} />}
        <div styleName="progress-bar">
          <div styleName="bar-wrapper">
            <div styleName="bar" style={{ width: `${(questionNumber / totalQuestions) * 100}%` }} />
          </div>
          <div styleName="text">{progress ? `残り${progress}問` : '最終回答'}</div>
        </div>
      </div>
      <div styleName="question-body" className={bodyClassName}>
        {errorMessage && <QuestionError styleName="error">{errorMessage}</QuestionError>}
        {subSentence && <div styleName="sub-sentence-one-question">{subSentence}</div>}
        {children}
      </div>
    </div>
  );
};
