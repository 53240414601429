export type TrialLessonId = number;
export enum TrialLessonStatus {
  ACTIVE = 'active',
  HIDDEN = 'hidden',
  FULL = 'full',
}
export enum TrialLessonTarget {
  NORMAL = 'normal',
  EXPERT = 'expert',
  NAGOYA = 'nagoya',
}

export type TrialLessonArea = 'tokyo' | 'nagoya' | 'osaka';
